<!--
* @desc: 经营数据表
* @Author: YanZhongYing
* @Date: 2024-06-05
-->
<template>
	<div class="box">
		<div class="box-date flex-end">
			<es-button style="margin-right: 16px" @click="exportExcel">导出</es-button>
			<es-date-picker v-model="date" type="month"></es-date-picker>
		</div>
		<div class="box-c">
			<!-- 头部 -->
			<!-- <Header title="经营数据表" /> -->
			<h2>四川省投资集团有限公司{{ showDate }}月生产经营指标完成情况表</h2>
			<div class="box-c-table">
				<es-data-table
					:data="tableData"
					:thead="thead"
					:key="tableKey"
					:span-method="objectSpanMethod"
					row-key="id"
					default-expand-all
					:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
					:row-class-name="tableRowClassName"
				></es-data-table>
			</div>
		</div>
	</div>
</template>

<script>
import Mixins from './mixins/mixins';
import Header from './components/Header.vue';
import { operateShow, common } from '@/http/operateAnalysisShow';
import $ from '@/utils';
export default {
	components: {
		Header
	},
	mixins: [Mixins],
	data() {
		return {
			rowArr: [],
			tableData: [],
			date: '',
			thead: [],
			tableKey: 0
			// flattenedData: [],
		};
	},
	computed: {
		showDate() {
			if (this.date == '') {
				return '';
			}
			let arr = this.date.split('-');
			return arr[0] + '年' + arr[1] + '月';
		}
	},
	watch: {
		date: {
			handler: function () {
				this.getData();
				if (!this.date) return [];
				let year = this.date.split('-')[0];
				this.thead = [
					{
						title: '序号',
						field: 'sort',
						width: '70',
						align: 'center',
						fixed: true
					},
					{
						title: '单位名称',
						field: 'unitName',
						width: '260',
						align: 'center',
						showOverflowTooltip: true,
						fixed: true
					},
					{
						title: '产品产量',
						childHead: [
							{
								title: '产量名称',
								width: '130',
								align: 'center',
								field: 'yieldName'
							},
							{
								title: '本月数',
								width: '120',
								align: 'center',
								field: 'numberOfMonths'
							},
							{
								title: '本期累计',
								width: '120',
								align: 'center',
								field: 'fiscalYear'
							},
							{
								title: '去年同期累计',
								width: '140',
								align: 'center',
								field: 'fiscalLastYear'
							},
							{
								title: '同比差异',
								width: '120',
								align: 'center',
								field: 'yearOnYear'
							},
							{
								title: '预算数',
								width: '120',
								align: 'center',
								field: 'budgetedNumber'
							},
							{
								title: '预算执行率',
								width: '120',
								align: 'center',
								field: 'budgetImplementationRate'
							}
						]
					},
					{
						title: '营业收入（万元）',
						childHead: [
							{
								title: '本月数',
								width: '120',
								align: 'center',
								field: 'inNumberOfMonths'
							},
							{
								title: '本期累计',
								width: '120',
								align: 'center',
								field: 'inFiscalYear'
							},
							{
								title: '去年同期累计',
								width: '140',
								align: 'center',
								field: 'inFiscalLastYear'
							},
							{
								title: '同比增减',
								width: '120',
								align: 'center',
								field: 'inYearOnYear'
							},
							{
								title: '同比增减变动率',
								width: '140',
								align: 'center',
								field: 'inYearOnYearRate'
							},
							{
								title: year + '年预算数',
								width: '140',
								align: 'center',
								field: 'inBudgetedNumber'
							},
							{
								title: '预算执行率',
								width: '120',
								align: 'center',
								field: 'inBudgetImplementationRate'
							},
							{
								title: '预算执行情况',
								width: '150',
								align: 'center',
								render: (h, params) => {
									const data = params.row.inBudgetImplementationCondition;
									if (data) {
										return h(
											'p',
											{
												style: {
													color: data.includes('未完成') ? 'rgb(255 0 0)' : '#00B050'
												}
											},
											data
										);
									}
									return h('p', {}, '--');
								},
								field: 'inBudgetImplementationCondition'
							}
						]
					},
					{
						title: '利润总额（万元）',
						childHead: [
							{
								title: '本月数',
								width: '120',
								align: 'center',
								field: 'profitNumberOfMonths'
							},
							{
								title: '本期累计',
								width: '120',
								align: 'center',
								field: 'profitFiscalYear'
							},
							{
								title: '去年同期累计',
								width: '140',
								align: 'center',
								field: 'profitFiscalLastYear'
							},
							{
								title: '同比增减',
								width: '120',
								align: 'center',
								field: 'profitYearOnYear'
							},
							{
								title: '同比增减变动率',
								width: '140',
								align: 'center',
								field: 'profitYearOnYearRate'
							},
							{
								title: year + '年预算数',
								width: '140',
								align: 'center',
								field: 'profitBudgetedNumber'
							},
							{
								title: '预算执行率',
								width: '120',
								align: 'center',
								field: 'profitBudgetImplementationRate'
							},
							{
								title: '预算执行情况',
								width: '150',
								align: 'center',
								render: (h, params) => {
									const data = params.row.profitBudgetImplementationCondition;
									if (data) {
										return h(
											'p',
											{
												style: {
													color:
														data == '同序时进度比减盈' ||
														data == '同预算比转亏' ||
														data == '同序时进度比增亏'
															? 'rgb(255 0 0)'
															: '#00B050'
												}
											},
											data
										);
									}
									return h('p', {}, '--');
								},
								field: 'profitBudgetImplementationCondition'
							}
						]
					},
					{
						title: '成本费用占比（%）',
						childHead: [
							{
								title: '本期累计',
								width: '120',
								align: 'center',
								field: 'feeFiscalYear'
							},
							{
								title: '去年同期累计',
								width: '140',
								align: 'center',
								field: 'feeFiscalLastYear'
							}
						]
					}
				];
				this.tableKey++;
			}
		}
	},
	mounted() {
		// this.getData();
		// this.flattenedData = this.flattenTableData(this.tableData);
		// this.rowArr = $.getRow(this.tableData, "unitName");
	},
	methods: {
		/**
		 * @desc: 获取数据
		 **/
		getData() {
			const loading = this.$.loading(this.$loading, '加载中');
			this.$.ajax({
				url: operateShow.dataSheet,
				params: {
					dateString: this.date
				}
			})
				.then(res => {
					loading.close();
					const { rCode, results } = res;
					if (rCode == 0) {
						this.tableData = results;
						this.rowArr = $.getRow(this.tableData, 'unitName');
					}
				})
				.catch(error => {
					loading.close();
				});
		},
		/**
		 * @desc: 导出
		 **/
		exportExcel() {
			let date = this.date.split('-');
			window.open(
				this.$.urlJoinParams({
					url: this.$.getStorage('host') + common.exportExcel,
					param: {
						excelCode: 'dataSheetExcelServiceImpl',
						fileName:
							'四川省投资集团有限公司' + date[0] + '年' + date[1] + '月生产经营指标完成情况表',
						dateString: date[0] + date[1]
					}
				})
			);
		},
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			// 不合并的列
			const noMergeColumns = [
				'yieldName',
				'numberOfMonths',
				'fiscalYear',
				'fiscalLastYear',
				'yearOnYear',
				'budgetedNumber',
				'budgetImplementationRate'
			];

			// 不合并的列直接返回 [1, 1]
			if (noMergeColumns.includes(column.property)) {
				return [1, 1];
			}
			if (columnIndex > 0) {
				let arr = this.rowArr;
				if (arr[rowIndex] > 0) {
					return [arr[rowIndex], 1];
				} else {
					return [0, 0];
				}
			}
			return [1, 1];
		},

		flattenTableData(data) {
			const result = [];
			data.forEach(row => {
				result.push({ ...row, isParent: true });
				if (row.children && row.children.length > 0) {
					row.children.forEach(child => {
						const childRow = {
							...child,
							isChild: true,
							parentName: row.unitName
						};
						result.push(childRow);
					});
				}
			});
			return result;
		},

		tableRowClassName({ row, rowIndex }) {
			if (row.unitName.includes('小计') || row.unitName.includes('合并')) {
				return 'row-total';
			}
			return '';
		}
		// objectSpanMethod({ row, column, rowIndex, columnIndex }) {
		//   const data = this.flattenedData;

		//   // 不合并的列
		//   const noMergeColumns = [
		//     "yieldName",
		//     "numberOfMonths",
		//     "fiscalYear",
		//     "fiscalLastYear",
		//     "yearOnYear",
		//     "budgetedNumber",
		//     "budgetImplementationRate",
		//   ];
		//   // 跳过子行
		//   if (row.isChild) {
		//     return [1, 1];
		//   }

		//   // 不合并的列直接返回 [1, 1]
		//   if (noMergeColumns.includes(column.property)) {
		//     return [1, 1];
		//   }

		//   // 合并数据列
		//   if (columnIndex > 0) {
		//     const columnProp = column.property;
		//     let rowspan = 1;

		//     // 计算rowspan
		//     for (let i = rowIndex + 1; i < data.length; i++) {
		//       // 跳过isChild为true的数据
		//       if (data[i].isChild) {
		//         continue;
		//       }

		//       // 跳过父行与子行相同数据
		//       if (
		//         !data[i].isChild &&
		//         data[i].parentName === row.unitName &&
		//         data[i][columnProp] === row[columnProp]
		//       ) {
		//         break;
		//       }

		//       if (
		//         data[i].unitName === row.unitName &&
		//         data[i][columnProp] === row[columnProp]
		//       ) {
		//         rowspan++;
		//       } else {
		//         break;
		//       }
		//     }
		//     // 确保前一行数据与当前行数据相同时，不合并
		//     if (
		//       rowIndex > 0 &&
		//       !data[rowIndex].isChild &&
		//       data[rowIndex - 1][columnProp] === row[columnProp] &&
		//       data[rowIndex - 1].unitName === row.unitName
		//     ) {
		//       return [0, 0];
		//     }

		//     return [rowspan, 1];
		//   }

		//   return [1, 1];
		// },
	}
};
</script>

<style lang="scss" scoped>
.box {
	width: 100%;
	height: 100%;
	background: #f6f6f6;
	font-size: 18px;
	// overflow: auto;
	&-date {
		width: 100%;
		padding: 6px 10px;
		height: 54px;
		background: #fff;
		border: 1px solid #eee;
		.es-date-picker {
			width: 200px;
		}
	}
	&-c {
		height: calc(100% - 54px);
		padding: 16px;
		background: #fff;
		h2 {
			font-weight: bold;
			height: 54px;
			font-size: 22px;
			color: #333333;
			text-align: center;
			margin-top: 0;
		}
		&-table {
			height: calc(100% - 74px);
		}
	}
}
::v-deep .es-data-table-content {
	.es-table {
		font-size: 16px;
	}
	.es-table thead th {
		background: #4e7aa9;
		color: #fff;
		font-weight: 400;
	}
	.es-thead-border {
		border-color: #2b4055;
		.el-table__header th:not(.gutter),
		td {
			border-color: #2b4055;
		}
		&::before,
		&::after {
			background-color: #2b4055;
		}
	}
	.row-total {
		background-color: #dae3e8;
		font-weight: 600;
	}
}
</style>