<template>
	<div id="app">
		<es-login
			launch
			:position="thumbnail ? '0%' : '55%'"
			:translate="thumbnail ? '-50%' : '-60%'"
			:class="{
				'login-fixed': thumbnail
			}"
			:doorIndex="doorIndex"
			:type="type"
			:img-code="{ noLine: true }"
			:qrImage="qrImage"
			:loginBackground="bgimage"
			:is-fixed="thumbnail"
		>
			<template v-if="!thumbnail">
				<div
					id="lottie"
					ref="lottie"
					class="lottie"
					:style="{ width, height }"
					v-show="animation"
				></div>
			</template>
		</es-login>
	</div>
</template>
<script>
import lottie from 'lottie-web';
import data from '@/assets/lottie/data.js';
let bg = require('@/assets/image/login/login_bg.png');
let { width, height } = window.screen;
data.assets.forEach(item => {
	if (item.p) {
		let p = item.p;
		item.u = '';
		item.p = require('@/assets/lottie/images/' + p);
	}
});
export default {
	data() {
		return {
			width: width + 'px',
			height: height + 'px',
			doorIndex: process.env.NODE_ENV != 'development' ? true : false,
			type: '3',
			bgimage: bg,
			qrImage: require('@/assets/image/login/icon.png'),
			animation: true,
			thumbnail: false
		};
	},
	created() {
		let thumbnail = this.$.getParams('thumbnail');
		this.thumbnail = thumbnail ? true : false;
		let initLogin = this.$.getStorage('initLogin');
		if (initLogin) {
			initLogin = JSON.parse(initLogin);
			if (
				initLogin.subsystemExtend &&
				(initLogin.subsystemExtend.loginAnimationHide == true ||
					initLogin.subsystemExtend.loginAnimationHide == 'true')
			) {
				this.animation = false;
			}
		}
		let ceshi = this.$.getParams('ceshi');
		if (ceshi == 'true' || ceshi == true) {
			this.type = '0';
		}
		sessionStorage.setItem('loginPage', 'login.html');
		const color = '#2591F7';
		this.$.updateTheme(color);
	},
	mounted() {
		// sessionStorage.removeItem('ssId');
		// sessionStorage.removeItem('Authorization');
		this.init();
		if (
			!this.$.getStorage('appcode') &&
			window.__store.state.initLogin &&
			Object.getOwnPropertyNames(window.__store.state.initLogin).length
		) {
			setTimeout(() => {
				this.$.setStorage(
					'localStorage',
					'appcode',
					window.__store.state.initLogin.subsystemExtend.applicationName
				);
			}, 200);
		}
	},
	methods: {
		init() {
			this.animation &&
				lottie.loadAnimation({
					container: document.getElementById('lottie'), // the dom element that will contain the animation
					renderer: 'svg',
					loop: true,
					autoplay: true,
					animationData: data // the path to the animation json
				});
		}
	}
};
</script>
<style lang="scss" scoped>
#app {
	height: 100%;
	overflow: hidden;
	position: relative;
}
.lottie {
	width: 100%;
	height: 100%;
	display: block;
	overflow: hidden;
	transform: translate3d(0, 0, 0);
	text-align: center;
	opacity: 1;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
::v-deep {
	.es-login-main {
		&::after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -110px;
			background: url(@/assets/image/login/shadow.png) no-repeat;
			height: 100px;
			background-size: contain;
		}
	}
}
.login-fixed {
	background: #fff !important;
	::v-deep {
		.es-login {
			width: 100%;
			height: 100%;
		}
		.es-login-main {
			height: 100%;
			border-radius: 0 !important;
		}
	}
}
</style>
