// 表格合并（带子表格）
const getRow = (data, type) => {
  //页面展示的数据，不一定是全部的数据，所以每次都清空之前存储的 保证遍历的数据是最新的数据。以免造成数据渲染混乱
  //遍历数据
  let spanArr = [];
  let pos = 0;
  let childrenCount = 0;

  data.forEach((item, index) => {
    //判断是否是第一项
    if (index === 0) {
      spanArr.push(1);
      pos = 0;
    } else {
      //不是第一项时，就根据标识去存储
      if (data[index][type] === data[index - 1][type]) {
        // 查找到符合条件的数据时每次要把之前存储的数据+1
        spanArr[pos + childrenCount] += 1;
        spanArr.push(0);
      } else {
        // 没有符合的数据时，要记住当前的index
        spanArr.push(1);
        pos = index;
      }
    }
    if (item.children) {
      childrenCount += item.children.length;
      spanArr = spanArr.concat(getRow(item.children, "unitName"));
    }
  });
  return spanArr;
};

export default { getRow };
