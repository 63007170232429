<template>
  <div :id="id" class="echart"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "MoreBars",
  props: {
    id: {
      type: String,
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    color: {
      type: Array,
      default: () => {
        return [["#5A99E3", "#82AFE4"], ["#9CCAFD", "#D2E7FC"], "#4E7AA9"];
      },
    },
    isLegend: {
      type: Boolean,
      default: true,
    },
    isLabel: {
      // 是否柱子头上带数据
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    data: {
      handler(val) {
        this.$nextTick(() => {
          this.drawBar(this.id, val);
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.drawBar(this.id, this.data);
    });
  },
  methods: {
    drawBar(id, res) {
      let data = JSON.parse(JSON.stringify(res));
      let dom = document.getElementById(id);
      if (!dom || data.length == 0) {
        return;
      }
      let chart = echarts.init(dom);
      chart.clear();

      let series = [],
        legendData = [],
        yAxis = [],
        xData = [];

      xData = data[0].data.map((i) => i.name);

      data.forEach((j, i) => {
        legendData.push({
          name: j.name,
          // icon: j.legendIcon,
        });

        // yAxis.push({
        //   splitLine: {
        //     lineStyle: {
        //       color: "rgba(238, 238, 238, .5)",
        //     },
        //   },
        //   axisLabel: {
        //     textStyle: {
        //       color: "#333",
        //     },
        //   },
        // });

        if (j.type == "bar") {
          series.push({
            name: j.name,
            type: j.type,
            barWidth: 8, //柱子宽度
            barGap: 1, //柱间距离
            itemStyle: {
              normal: {
                barBorderRadius: [4, 4, 0, 0],
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: this.color[i][0],
                  },
                  {
                    offset: 1,
                    color: this.color[i][1],
                  },
                ]),
              },
            },
            label: {
              //图形上的文本标签
              normal: {
                show: this.isLabel,
                position: "top",
                textStyle: {
                  color: "#333333",
                  fontStyle: "normal",
                  fontSize: 16,
                },
              },
            },
            data: j.data,
          });
        } else if (j.type == "line") {
          series.push({
            yAxisIndex: 1,
            name: j.name,
            type: j.type,
            // smooth: true,
            // yAxisIndex: 1,
            symbolSize: 13,
            symbol: "circle",
            itemStyle: {
              color: this.color[i],
              borderColor: "#fff",
              borderWidth: 2,
            },
            lineStyle: {
              color: this.color[i], //线条颜色
            },
            areaStyle: {
              opacity: 0.7,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: this.color[i],
                },
                {
                  offset: 1,
                  color: "rgba(255, 255, 255,0.2)",
                },
              ]),
            },
            data: j.data,
          });
        }
      });

      let option = {
        title: {
          show: data.name,
          text: data.name,
          textStyle: {
            color: "#e2f7fc",
          },
          top: 0,
          left: 0,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#202630",
          borderColor: "#202630",
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
        },
        grid: {
          bottom: "14%",
          top: "14%",
          left: "12%",
          right: "12%",
        },
        legend: {
          show: true,
          // icon: "circle",
          data: legendData,
          // right: 120,
          top: this.large ? 10 : 0,
          itemGap: 50,
          textStyle: {
            color: "#999999",
            fontSize: this.large ? 22 : 16,
          },
          itemWidth: this.large ? 16 : 10,
          itemHeight: this.large ? 16 : 10,
        },
        xAxis: {
          type: "category",
          data: xData,
          axisLine: {
            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#333",
              fontSize: 14,
            },
            formatter: (params) => {
              return params.replace(
                params.substr(4, 1),
                "\n" + params.substr(4, 1)
              );
            },
            // lineHeight: 18,
            interval: 0,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            splitLine: {
              lineStyle: {
                color: "rgba(238, 238, 238, .5)",
              },
            },
            axisLabel: {
              textStyle: {
                color: "#333",
                fontSize: 14,
              },
            },
          },
          {
            position: "right",
            splitLine: {
              lineStyle: {
                color: "rgba(238, 238, 238, .5)",
              },
            },
            axisLabel: {
              textStyle: {
                color: "#333",
                fontSize: 14,
              },
            },
          },
        ],
        series,
      };
      chart.setOption(option);
      this.$emit("getChart", chart);
      window.addEventListener("resize", () => {
        chart && chart.resize();
      });
    },
  },
};
</script>
<style scoped lang="scss">
.echart {
  width: 100%;
  height: 100%;
}
</style>
