<template>
	<statistic :data="data"></statistic>
</template>
<script>
import { mapState } from 'vuex';
import Statistic from '@/components/public/Statistic.vue';
export default {
	name: 'BacklogCount',
	components: { Statistic },
	props: {
		gutter: {
			type: Number,
			default: 20
		},
		contents: Array
		// makePendingCount: {
		// 	type: Number,
		// 	default: 0
		// }, // 待办数量
		// readPendingCount: {
		// 	type: Number,
		// 	default: 0
		// }, //  待阅数量
		// allPendingCount: {
		// 	type: Number
		// },
		// default: 0, //  所有待办的数量(保留字段)
		// meApplicationCount: {
		// 	type: Number,
		// 	default: 0
		// }, //   我的发起的数量
		// pendedCount: {
		// 	type: Number,
		// 	default: 0
		// }, // 已办的数量
		// signCount: {
		// 	type: Number,
		// 	default: 0
		// } //待签公文
	},
	data() {
		return {
			userId: this.$.getStorage('userId')
		};
	},
	computed: {
		data() {
			return [
				{ icon: 'es-icon-daiban', label: '待办', value: this.makePendingCount },
				{ icon: 'es-icon-daiban', label: '已办', value: this.pendedCount },
				{ icon: 'es-icon-daiban', label: '发起', value: this.meApplicationCount },
				{ icon: 'es-icon-daiban', label: '公文', value: this.signCount }
			];
		},
		...mapState([
			'makePendingCount',
			'readPendingCount',
			'allPendingCount',
			'meApplicationCount',
			'pendedCount',
			'signCount'
		])
	},
	created() {
		this.getCount();
	},
	methods: {
		getCount() {
			this.$store.dispatch('getCount', { ajax: this.$.ajax, userId: this.userId });
		}
	}
};
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	height: 100%;
	padding: 16px;
	background-color: #f5f8fd;
}
</style>
