<!--信创看的-->
<template>
	<div class="background">
		<div class="content">
			<card title="经营完成情况（完成预算进度）" width="1200">
				<div class="jywcqk-box">
					<div class="left">
						<div class="label">集团数据</div>
						<div class="list" v-if="jdzzbList.length > 0">
							<div class="list-item">
								<div class="item-label">资产总额</div>
								<div class="br" />
								<div class="item-value">
									{{ (jdzzbList[2].value - 0).toLocaleString() }}
									<span class="unit">亿</span>
								</div>
							</div>
							<div class="list-item">
								<div class="item-label">营业收入</div>
								<div class="br" />
								<div class="item-value">
									{{ jdzzbList[0].value }}
									<span class="unit">%</span>
								</div>
							</div>
							<div class="list-item">
								<div class="item-label">利润总额</div>
								<div class="br" />
								<div class="item-value">
									{{ jdzzbList[1].value }}
									<span class="unit">%</span>
								</div>
							</div>
						</div>
					</div>
					<div class="right">
						<div class="right-toolbar">
							<div class="right-tabs">
								<div
									class="right-tabs-item"
									:class="{ actived: index == actived }"
									v-for="(item, index) in tabsList"
									:key="index"
									@click="actived = index"
								>
									{{ item }}
								</div>
								<div class="date">(1-{{ asMonth }}月)</div>
							</div>
							<div class="right-lenged">
								<div class="right-lenged-item">
									<div class="icon r" />
									完成预算进度
								</div>
								<div class="right-lenged-item">
									<div class="icon g" />
									未完成预算进度
								</div>
							</div>
						</div>
						<div class="right-content">
							<el-carousel class="swiper" height="490px" :interval="5000">
								<el-carousel-item
									class="swiper-item"
									v-for="ele in [
										Math.ceil(allqyList.length / 12),
										Math.ceil(ylqyList.length / 12),
										Math.ceil(ksqyList.length / 12)
									][actived]"
									:key="ele"
								>
									<div
										class="right-content-item"
										v-for="item in [
											allqyList.slice((ele - 1) * 12, ele * 12),
											ylqyList.slice((ele - 1) * 12, ele * 12),
											ksqyList.slice((ele - 1) * 12, ele * 12)
										][actived]"
										:key="item.name"
									>
										<div class="right-content-item-head">
											<div class="name">{{ item.name }}</div>
											<div class="qk">
												<div class="icon" :class="{ g: !item.yl }"></div>
												{{ item.yl ? '盈利' : '控亏' }}
											</div>
										</div>
										<div class="right-content-item-info">
											<div class="info-item">
												<div class="label">营业收入</div>
												<div class="value" :class="{ g: item.operaingIncomeType !== '完成' }">
													{{ item.operaingIncome.replace('%', '') }}
													<span class="unit">%</span>
												</div>
											</div>
											<div class="info-item">
												<div class="label">利润总额</div>
												<div class="value" :class="{ g: item.totalProfitType !== '完成' }">
													{{ item.totalProfit.replace('%', '') }}
													<span class="unit">%</span>
												</div>
											</div>
										</div>
									</div>
								</el-carousel-item>
							</el-carousel>
						</div>
					</div>
				</div>
			</card>
			<card title="集团要闻" class="mt" :more="true" width="384" @onMore="onMore('groupNews')">
				<div class="focus-news">
					<div
						class="show-img"
						v-if="jtywList[0] && jtywList[0].adjunctId && jtywList[0].adjunctId.length > 0"
						@click="toDetail(jtywList[0], 'groupNews')"
						:class="{ expir: itemClass(jtywList[0]) }"
					>
						<div class="icon">{{ itemClass(jtywList[0]) ? '已结束' : 'NEW' }}</div>
						<img
							class="show-img-main"
							:src="`${baseUrl}/portal/fileManagement/previewAdjunct.dhtml?adjunctId=${jtywList[0].adjunctId}`"
							alt=""
						/>
						<div class="info">
							<div class="title">{{ jtywList[0].title }}</div>
							<div class="date">{{ jtywList[0].publishTime.split(' ')[0] }}</div>
						</div>
					</div>
					<div class="focus-news-list" v-if="jtywList.length > 0">
						<div
							class="focus-news-item"
							v-for="item in jtywList"
							:key="item.id"
							:class="{ expir: itemClass(item) }"
							@click="toDetail(item, 'groupNews')"
						>
							<div class="title">
								<img
									class="icon"
									:src="
										!itemClass(item)
											? require('@/assets/image/home/focus-news-actived.png')
											: require('@/assets/image/home/focus-news.png')
									"
									alt=""
								/>
								{{ item.title }}
							</div>
							<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
						</div>
					</div>
					<no-data v-if="jtywList.length == 0" />
				</div>
			</card>
			<card
				title="子公司要闻"
				class="mt"
				:more="true"
				width="384"
				@onMore="onMore('subsidiaryInformation')"
			>
				<div class="focus-news">
					<div
						class="show-img"
						v-if="zgsywList[0] && zgsywList[0].adjunctId && zgsywList[0].adjunctId.length > 0"
						@click="toDetail(zgsywList[0], 'subsidiaryInformation')"
						:class="{ expir: itemClass(zgsywList[0]) }"
					>
						<div class="icon">{{ itemClass(zgsywList[0]) ? '已结束' : 'NEW' }}</div>
						<img
							class="show-img-main"
							:src="`${baseUrl}/portal/fileManagement/previewAdjunct.dhtml?adjunctId=${zgsywList[0].adjunctId}`"
							alt=""
						/>
						<div class="info">
							<div class="title">{{ zgsywList[0].title }}</div>
							<div class="date">{{ zgsywList[0].publishTime.split(' ')[0] }}</div>
						</div>
					</div>
					<div class="focus-news-list" v-if="zgsywList.length > 0">
						<div
							class="focus-news-item"
							v-for="item in zgsywList"
							:class="{ expir: itemClass(item) }"
							:key="item.id"
							@click="toDetail(item, 'subsidiaryInformation')"
						>
							<div class="title">
								<img
									class="icon"
									:src="
										!itemClass(item)
											? require('@/assets/image/home/focus-news-actived.png')
											: require('@/assets/image/home/focus-news.png')
									"
									alt=""
								/>
								{{ item.title }}
							</div>
							<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
						</div>
					</div>
					<no-data v-if="zgsywList.length == 0" />
				</div>
			</card>
			<card
				class="mt"
				width="384"
				:headTab="true"
				:headTabList="['公告', '通知']"
				:more="true"
				@onMore="onMore('announcementNotice')"
			>
				<template #0>
					<div class="info-list h1" v-if="ggList.length > 0">
						<div
							class="info-item"
							v-for="item in ggList"
							:key="item.id"
							:class="{ expir: itemClass(item) }"
							@click="toDetail(item, 'announcement', 0)"
						>
							<div class="title">
								<img
									class="icon"
									:src="
										!itemClass(item)
											? require('@/assets/image/home/focus-news-actived.png')
											: require('@/assets/image/home/focus-news.png')
									"
									alt=""
								/>
								{{ item.title }}
							</div>
							<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
						</div>
					</div>

					<no-data v-if="ggList.length == 0" height="220" />
				</template>
				<template #1>
					<div class="info-list h1" v-if="tzList.length > 0">
						<div
							class="info-item"
							v-for="item in tzList"
							:key="item.id"
							:class="{ expir: itemClass(item) }"
							@click="toDetail(item, 'notice', 1)"
						>
							<div class="title">
								<img
									class="icon"
									:src="
										!itemClass(item)
											? require('@/assets/image/home/focus-news-actived.png')
											: require('@/assets/image/home/focus-news.png')
									"
									alt=""
								/>
								{{ item.title }}
							</div>
							<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
						</div>
					</div>
					<no-data v-if="tzList.length == 0" height="100" />
				</template>
			</card>
			<card
				class="mt"
				width="384"
				:headTab="true"
				:headTabList="['公司文件', '制度汇编']"
				:more="true"
				@onMore="onMore('companyDocumentsSystemCompilation')"
			>
				<template #0>
					<div class="info-list">
						<div
							class="info-item"
							v-for="item in gswjList"
							:key="item.id"
							:class="{ expir: itemClass(item) }"
							@click="toDetail(item, 'notice', 1)"
						>
							<div class="title">
								<img
									class="icon"
									:src="
										!itemClass(item)
											? require('@/assets/image/home/focus-news-actived.png')
											: require('@/assets/image/home/focus-news.png')
									"
									alt=""
								/>
								{{ item.title }}
							</div>
							<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
						</div>
					</div>
				</template>
				<template #1>
					<div class="info-list">
						<div
							class="info-item"
							v-for="item in zdhbList"
							:key="item.id"
							:class="{ expir: itemClass(item) }"
							@click="toDetail(item, 'notice', 1)"
						>
							<div class="title">
								<img
									class="icon"
									:src="
										!itemClass(item)
											? require('@/assets/image/home/focus-news-actived.png')
											: require('@/assets/image/home/focus-news.png')
									"
									alt=""
								/>
								{{ item.title }}
							</div>
							<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
						</div>
					</div>
				</template>
			</card>
			<card class="mt" width="384"></card>
			<card class="mt" width="384"></card>
		</div>
	</div>
</template>

<script>
import Card from '../components/Card.vue';
import NoData from '../components/NoData';
export default {
	components: { Card, NoData },
	data() {
		return {
			baseUrl: window.location.origin + (window.location.hostname == 'localhost' ? '/dev-api' : ''),
			jtywList: [],
			zgsywList: [],
			tzList: [],
			ggList: [],
			gswjList: [],
			zdhbList: [],
			allqyList: [],
			ylqyList: [],
			ksqyList: [],
			jdzzbList: [],
			tabsList: ['全部', '盈利企业', '控亏企业'],
			actived: 0,
			asMonth: '--'
		};
	},
	computed: {},
	mounted() {
		this.getFinaceData();
		this.getColumnIdByCode();
	},
	methods: {
		//财务接口-刘珂良
		getFinaceData() {
			//先拿时间
			this.$.ajax({ url: this.$url.getRecentDate }).then(res => {
				if (res.rCode == 0) {
					let dateString = res.results.date;
					this.asMonth = dateString.split('-')[1] - 0;
					//盈利企业、亏损企业
					this.$.ajax({
						url: this.$url.budgetCompletionInCome,
						params: {
							dateString
						}
					}).then(res => {
						if (res.rCode == 0) {
							this.ylqyList = res.results.profit
								? res.results.profit.map(e => {
										return { ...e, yl: true };
								  })
								: [];
							this.ksqyList = res.results.profitLoss
								? res.results.profitLoss.map(e => {
										return { ...e, yl: false };
								  })
								: [];
							this.allqyList = [...this.ylqyList, ...this.ksqyList];
						}
					});
					this.$.ajax({
						url: this.$url.mainOperatingIndicators,
						params: { dateString }
					}).then(res => {
						if (res.rCode == 0) {
							this.jdzzbList = res.results[0].data;
						}
					});
				}
			});
		},
		//先拿目录id再用目录id去查数据
		getColumnIdByCode() {
			//集团要闻
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'gsyw' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnListJson + `?columnId=${res.data}`,
						data: {
							page: 1,
							rows: 10
						},
						method: 'POST'
					}).then(res => {
						this.jtywList = res.data;
					});
				}
			});
			//子公司要闻
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'agsyw' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnListJson + `?columnId=${res.data}`,
						data: {
							page: 1,
							rows: 10
						},
						method: 'POST'
					}).then(res => {
						this.zgsywList = res.data;
					});
				}
			});
			//公告
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'gg' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnListJson + `?columnId=${res.data}`,
						data: {
							page: 1,
							rows: 10
						},
						method: 'POST'
					}).then(res => {
						this.ggList = res.data;
					});
				}
			});
			//通知
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'tz' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnListJson + `?columnId=${res.data}`,
						data: {
							page: 1,
							rows: 10
						},
						method: 'POST'
					}).then(res => {
						this.tzList = res.data;
					});
				}
			});
			//公司文件
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'gswj' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnList,
						params: { columnId: res.data }
					}).then(res => {
						if (res.status == 'success') {
							let columnId = res.data[0].id;
							this.$.ajax({
								url: this.$url.getColumnListJson + `?columnId=${columnId}`,
								data: {
									page: 1,
									rows: 10
								},
								method: 'POST'
							}).then(res => {
								this.gswjList = res.data;
							});
						}
					});
				}
			});
			//制度汇编
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'zdhb' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnList,
						params: { columnId: res.data }
					}).then(res => {
						if (res.status == 'success') {
							let columnId = res.data[0].id;
							this.$.ajax({
								url: this.$url.getColumnListJson + `?columnId=${columnId}`,
								data: {
									page: 1,
									rows: 10
								},
								method: 'POST'
							}).then(res => {
								this.zdhbList = res.data;
							});
						}
					});
				}
			});
		},
		//更多
		onMore(code, activedIndex) {
			this.$router.push({ name: code, query: { activedIndex } });
			// //集团要闻
			// groupNews
			// //子公司要闻
			// subsidiaryInformation
			// //公告通知
			// announcementNotice
			// //综合信息
			// integratedInformation
			// //下载中心
			// downloadCenter
			//公司文件、制度汇编
			//companyDocumentsSystemCompilation
		},
		//去详情
		toDetail(item, name) {
			if (this.itemClass(item)) {
				this.$message.warning('该内容已结束！');
				return;
			}
			//跳转类型的
			if (item.infoType !== 0 && item.infoUrl) {
				window.open(item.infoUrl, '_blank');
			}
			//去详情
			else {
				// this.$router.push({ name: 'detail', query: { id: item.id, name } });
				var { href } = this.$router.resolve({ name: 'detail', query: { id: item.id, name } });
				window.open(href, '_blank');
			}
		},
		//类函数
		itemClass(item) {
			if (item.expirationDate && item.expirationDate.length > 0) {
				let exDate = new Date(item.expirationDate).getDate();
				let now = new Date().getDate();
				return exDate < now;
			} else {
				return false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.background {
	width: 100%;
	padding: 24px 0 64px;
	background: #f4f6fd;
}
.content {
	width: 1200px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	.jywcqk-box {
		width: 100%;
		height: 561px;
		display: flex;
		justify-content: space-between;
		padding-top: 24px;
		.left {
			width: 204px;
			height: 490px;
			border-right: 1px solid rgba($color: #dfebf6, $alpha: 0.7);
			display: flex;
			flex-direction: column;
			.label {
				text-align: center;
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 16px;
				color: #15224c;
				margin-bottom: 38px;
				position: relative;
				&::after {
					content: '';
					display: block;
					position: absolute;
					top: 30px;
					left: calc(50% - 6px);
					width: 12px;
					height: 8px;
					background: url('@/assets/image/home/title-icon1.png') no-repeat;
					background-size: 100% 100%;
				}
			}
			.list {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				.list-item {
					width: 166px;
					height: 139px;
					background: url('@/assets/image/home/jtsj-bg.png') no-repeat;
					background-size: 100% 100%;
					padding-top: 24px;
					.item-label {
						font-family: Alibaba PuHuiTi 2;
						text-align: center;
						font-weight: normal;
						font-size: 16px;
						color: #15224c;
						margin-bottom: 20px;
					}
					.br {
						width: 120px;
						height: 1px;
						background: rgba($color: #ffcacd, $alpha: 0.4);
						margin: 0 auto;
					}
					.item-value {
						text-align: center;
						padding-top: 22px;
						color: #d0454d;
						font-family: Alibaba PuHuiTi 2;
						font-weight: 600;
						font-size: 24px;
						color: #d0454d;
						.unit {
							font-family: Alibaba PuHuiTi 2;
							font-weight: normal;
							font-size: 14px;
							margin-left: 6px;
						}
					}
				}
			}
		}
		.right {
			width: calc(100% - 204px);
			padding: 0 16px;
			.right-toolbar {
				width: 100%;
				height: 40px;
				padding-bottom: 8px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.right-tabs {
					display: flex;
					align-items: center;
					.right-tabs-item {
						height: 32px;
						padding: 0 28px;
						line-height: 32px;
						background: #f2f2f2;
						border-radius: 16px;
						font-family: Alibaba PuHuiTi 2;
						font-weight: normal;
						font-size: 14px;
						color: #15224c;
						margin: 0 8px;
						cursor: pointer;
						&.actived {
							background: #2591f7;
							box-shadow: 0px 8px 10px 0px rgba(37, 145, 247, 0.21);
							color: #ffffff;
						}
					}
					.date {
						margin-left: 30px;
						font-family: Alibaba PuHuiTi 2;
						font-weight: normal;
						font-size: 14px;
						color: #b9bdc9;
					}
				}
				.right-lenged {
					display: flex;
					.right-lenged-item {
						display: flex;
						align-items: center;
						font-family: Alibaba PuHuiTi 2;
						font-weight: normal;
						font-size: 14px;
						color: #737a94;
						margin-left: 23px;
						.icon {
							width: 10px;
							height: 10px;
							margin-right: 9px;
							&.r {
								background: #d0454d;
							}
							&.g {
								background: #0cc41d;
							}
						}
					}
				}
			}
			.right-content {
				// display: flex;
				width: 100%;
				height: calc(100% - 40px);
				// justify-content: space-between;
				// flex-wrap: wrap;
				.right-content-item {
					margin-top: 18px;
					background: url('@/assets/image/home/jywcqk-bg.png') no-repeat;
					background-size: 100% 100%;
					display: inline-block;
					width: 231px;
					height: 139px;
					margin-left: 12px;
					padding: 20px;
					.right-content-item-head {
						display: flex;
						align-items: center;
						padding-bottom: 18px;
						border-bottom: 1px solid rgba($color: #dfebf6, $alpha: 0.7);
						justify-content: space-between;
						.name {
							font-family: Alibaba PuHuiTi 2;
							font-weight: normal;
							font-size: 16px;
							color: #15224c;
						}
						.qk {
							font-family: Alibaba PuHuiTi 2;
							font-weight: normal;
							font-size: 14px;
							display: flex;
							align-items: center;
							color: #15224c;
							.icon {
								width: 8px;
								height: 8px;
								background: #d0454d;
								box-shadow: 0px 2px 4px 0px rgba(208, 69, 77, 0.24);
								border-radius: 50%;
								margin-right: 8px;
								&.g {
									background: #0cc41d;
								}
							}
						}
					}
					.right-content-item-info {
						.info-item {
							display: flex;
							justify-content: space-between;
							align-items: center;
							margin-top: 8px;
							.label {
								font-family: Alibaba PuHuiTi 2;
								font-weight: normal;
								font-size: 14px;
								color: #737a94;
							}
							.value {
								font-family: Alibaba PuHuiTi 2;
								font-weight: normal;
								font-size: 18px;
								color: #d0454d;
								.unit {
									font-family: Alibaba PuHuiTi 2;
									font-weight: normal;
									font-size: 14px;
									color: #d0454d;
									margin-left: 8px;
								}
								&.g {
									color: #0cc41d;
									.unit {
										color: #0cc41d;
									}
								}
							}
						}
					}

					// &:nth-child(4n) {
					// 	margin-left: 0;
					// }
				}
			}
			.swiper {
				width: 100%;
				height: 100%;
				position: relative;
				overflow: hidden;
				.swiper-item {
					width: 100%;
					height: 100%;
				}
				::v-deep .el-carousel__indicators {
					.el-carousel__indicator {
						.el-carousel__button {
							background: #2591f7;
						}
					}
				}
			}
		}
	}
	.mt {
		margin-top: 24px;
	}
	.focus-news {
		padding: 8px 24px;
		width: 100%;
		height: 312px;
		display: flex;
		flex-direction: column;
		.show-img {
			margin-top: 16px;
			cursor: pointer;
			width: 100%;
			background: #a9a9a9;
			height: 189px;
			box-shadow: 0px 4px 16px 0px rgba(4, 80, 184, 0.08);
			border-radius: 6px;
			overflow: hidden;
			margin-bottom: 10px;
			position: relative;
			.icon {
				left: 0;
				top: 0;
				position: absolute;
				width: 52px;
				height: 27px;
				background: #2591f7;
				box-shadow: 0px 2px 4px 0px rgba(37, 145, 247, 0.24);
				border-radius: 6px 0px 6px 0px;
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 12px;
				color: #ffffff;
				line-height: 27px;
				text-align: center;
			}
			.show-img-main {
				width: 100%;
				height: 100%;
			}
			.info {
				width: 100%;
				height: 67px;
				position: absolute;
				left: 0;
				bottom: 0;
				background: rgba($color: #15224c, $alpha: 0.54);
				padding: 14px 22px 0;
				.title {
					width: 100%;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #ffffff;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-bottom: 5px;
				}
				.date {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: rgba($color: #fff, $alpha: 0.5);
				}
			}
			&.expir {
				cursor: not-allowed;
				.icon {
					background: #999;
				}
			}
		}
		.focus-news-list {
			flex: 1;
			width: 100%;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			.focus-news-item {
				cursor: pointer;
				flex: 1;
				flex-shrink: 0;
				min-height: 36px;
				max-height: 37px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.title {
					.icon {
						width: 8px;
						height: 8px;
						margin-right: 12px;
					}
					width: 230px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #15224c;
				}
				.date {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #737a94;
				}
				&.expir {
					cursor: not-allowed;
					.title {
						color: #999;
					}
					.date {
						color: #999;
					}
				}
			}
		}
	}
	.info-list {
		width: 100%;
		padding: 0 24px;
		overflow: hidden;
		height: 158px;
		&.h1 {
			height: 310px;
			.info-item {
				height: 12.5%;
			}
		}
		.info-item {
			cursor: pointer;
			display: flex;
			align-items: center;
			height: 33.3%;
			justify-content: space-between;
			.title {
				.icon {
					width: 8px;
					height: 8px;
					margin-right: 12px;
				}
				width: 230px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #15224c;
			}
			.date {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #737a94;
			}
			&.expir {
				cursor: not-allowed;
				.title {
					color: #999;
				}
				.date {
					color: #999;
				}
			}
		}
	}
}
</style>
