var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"big_box",staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"big-box"}},[_c('div',{staticClass:"content",attrs:{"id":"container"}},[_c('h2',{staticClass:"title"},[_vm._v(" 川投集团值班预警监控平台 "),_c('div',{staticClass:"date",domProps:{"innerHTML":_vm._s(_vm.headTitle)}})]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"statistics"},[_vm._m(0),_c('div',{staticClass:"chart"},[_c('div',{staticClass:"chart-info"},[_c('MoreBars',{attrs:{"mid":"statistics","data":_vm.statisticsData}})],1)])]),_c('div',{staticClass:"warning-center"},[_vm._m(1),_c('div',{staticClass:"table"},[_c('div',{staticClass:"thead"},_vm._l((_vm.warnThead),function(item,index){return _c('div',{key:index,staticClass:"item",style:({ width: `calc(100% / ${_vm.warnThead.length})` })},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"tbody"},[_c('el-marquee',{staticClass:"seamless-warp",attrs:{"data":_vm.warnDatas,"disabled":_vm.warnDatas.length < 12}},_vm._l((_vm.warnDatas),function(item,index){return _c('ul',{key:index},_vm._l((_vm.warnThead),function(i,ind){return _c('li',{key:ind,style:({ width: `calc(100% / ${_vm.warnThead.length})` })},[_c('span',{class:{
                      fxyj: i.value == 'duty_situation_status_text',
                      low: item.duty_situation_status == null,
                      medium: item.duty_situation_status == 1,
                      high: item.duty_situation_status == 2,
                    }},[_vm._v(" "+_vm._s(item[i.value])+" ")])])}),0)}),0)],1)])]),_c('div',{staticClass:"personnel"},[_vm._m(2),_c('div',{staticClass:"table"},[_vm._m(3),_c('div',{staticClass:"tbody"},[_c('el-marquee',{staticClass:"seamless-warp",attrs:{"data":_vm.personnelData,"disabled":_vm.personnelData.length < 12}},_vm._l((_vm.personnelData),function(item,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(item.insert_org_name))]),_c('li',[_vm._v(_vm._s(item.name))]),_c('li',[_vm._v(_vm._s(item.phone))]),_c('li',{style:({
                    color:
                      item.duty_feedback_status_text == '未反馈'
                        ? 'rgb(227 122 111)'
                        : '#333',
                  })},[_vm._v(" "+_vm._s(item.duty_feedback_status_text)+" ")])])}),0)],1)])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"right-item item1"},[_vm._m(4),_c('div',{staticClass:"info-box"},_vm._l((_vm.tianbaoData),function(item){return _c('div',{key:item.name,staticClass:"box-item"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(item.value))])])}),0)]),_c('div',{staticClass:"right-item item2"},[_c('div',{staticClass:"info-title"},[_c('div',{staticClass:"icon"}),_vm._v(" "+_vm._s(_vm.nowYear)+"二级公司年度值班填报情况 ")]),_c('div',{staticClass:"pie-box"},_vm._l((_vm.companyDutyNumber),function(item,index){return _c('div',{key:item.name,staticClass:"pie-item"},[_c('RightPie',{attrs:{"mid":'pie' + index,"data":item,"color":item.color}}),_c('div',{staticClass:"pie-title"},[_vm._v(_vm._s(item.name))])],1)}),0)]),_c('div',{staticClass:"right-item item3"},[_c('div',{staticClass:"info-title"},[_c('div',{staticClass:"icon"}),_vm._v(" "+_vm._s(_vm.dutyStatisticsScoreTitle)+" ")]),_c('div',{staticClass:"org-box"},[_c('el-marquee',{staticClass:"seamless-warp",attrs:{"data":_vm.dutyStatisticsScore,"disabled":_vm.dutyStatisticsScore.length < 8}},_vm._l((_vm.dutyStatisticsScore),function(item,index){return _c('div',{key:index,staticClass:"org-item"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.value))])])}),0)],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-title"},[_c('div',{staticClass:"icon"}),_vm._v(" 本年度值班填报情况统计 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-title"},[_c('div',{staticClass:"icon"}),_vm._v(" 预警中心 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-title"},[_c('div',{staticClass:"icon"}),_vm._v(" 本日值班人员 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"thead"},[_c('div',{staticClass:"item"},[_vm._v("单位")]),_c('div',{staticClass:"item"},[_vm._v("值班人员")]),_c('div',{staticClass:"item"},[_vm._v("联系方式")]),_c('div',{staticClass:"item"},[_vm._v("值班反馈")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-title"},[_c('div',{staticClass:"icon"}),_vm._v(" 本日二级公司填报数据 ")])
}]

export { render, staticRenderFns }