var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('div',{staticClass:"content"},[_c('card',{attrs:{"title":"经营完成情况（完成预算进度）","width":"792"}},[_c('div',{staticClass:"title1"},[_c('div',{staticClass:"label"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/image/home/title-icon1.png"),"alt":""}}),_vm._v(" 绝对值指标 "),_c('span',{staticClass:"unit"},[_vm._v("(单位：亿元)")])]),_c('div',{staticClass:"date"},[_vm._v(" 截至日期： "+_vm._s(_vm.showDate1)+" "),_c('es-date-picker',{attrs:{"type":"month","placeholder":"选择截至月份","width":"50"},on:{"change":_vm.onDateChange},model:{value:(_vm.date1),callback:function ($$v) {_vm.date1=$$v},expression:"date1"}})],1)]),_c('div',{staticClass:"jdzzb-list"},_vm._l((_vm.jdzzbList),function(item,index){return _c('div',{key:index,staticClass:"jdzzb-item"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.value))]),_c('div',{staticClass:"increase",class:{
							up: item.percentage && item.percentage - 0 > 0,
							down: item.percentage && item.percentage - 0 < 0
						}},[_vm._v(" "+_vm._s(item.percentage + '%')+" ")]),(item.budgetCompletionRate)?_c('div',{staticClass:"percentage"},[_c('div',{staticClass:"label"},[_vm._v("预计完成率")]),_c('div',{staticClass:"value"},[_vm._v(_vm._s(item.budgetCompletionRate + '%'))])]):_vm._e()])}),0),_c('div',{staticClass:"title1"},[_c('div',{staticClass:"label"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/image/home/title-icon1.png"),"alt":""}}),_vm._v(" 比率类指标 ")])]),_c('div',{staticClass:"bllzb-list"},_vm._l((_vm.bllzbList),function(item,index){return _c('div',{key:index,staticClass:"bllzb-item"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"value"},[_c('div',{staticClass:"value-left"},[_vm._v(" "+_vm._s(item.value)+" "),_c('span',{staticClass:"unit"},[_vm._v(_vm._s(index == _vm.bllzbList.length - 1 ? '万元' : '%'))])]),_c('div',{staticClass:"value-right",class:{
								up: item.percentage && item.percentage - 0 > 0,
								down: item.percentage && item.percentage - 0 < 0
							}},[_vm._v(" "+_vm._s(item.percentage + (index == _vm.bllzbList.length - 1 ? '万元/人' : '个百分点'))+" ")])])])}),0)]),_c('div',{staticClass:"bb"},[_c('card',{attrs:{"title":"集团要闻","more":true,"width":"384"},on:{"onMore":function($event){return _vm.onMore('groupNews')}}},[_c('div',{staticClass:"focus-news"},[(_vm.jtywList[0] && _vm.jtywList[0].adjunctId && _vm.jtywList[0].adjunctId.length > 0)?_c('div',{staticClass:"show-img",class:{ expir: _vm.itemClass(_vm.jtywList[0]) },on:{"click":function($event){return _vm.toDetail(_vm.jtywList[0], 'groupNews')}}},[_c('div',{staticClass:"icon"},[_vm._v(_vm._s(_vm.itemClass(_vm.jtywList[0]) ? '已结束' : 'NEW'))]),_c('img',{staticClass:"show-img-main",attrs:{"src":`${_vm.baseUrl}/portal/fileManagement/previewAdjunct.dhtml?adjunctId=${_vm.jtywList[0].adjunctId}`,"alt":""}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.jtywList[0].title))]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.jtywList[0].publishTime.split(' ')[0]))])])]):_vm._e(),(_vm.jtywList.length > 0)?_c('div',{staticClass:"focus-news-list"},_vm._l((_vm.jtywList),function(item){return _c('div',{key:item.id,staticClass:"focus-news-item",class:{ expir: _vm.itemClass(item) },on:{"click":function($event){return _vm.toDetail(item, 'groupNews')}}},[_c('div',{staticClass:"title"},[_c('img',{staticClass:"icon",attrs:{"src":!_vm.itemClass(item)
											? require('@/assets/image/home/focus-news-actived.png')
											: require('@/assets/image/home/focus-news.png'),"alt":""}}),_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.publishTime.split(' ')[0]))])])}),0):_vm._e(),(_vm.jtywList.length == 0)?_c('no-data'):_vm._e()],1)]),_c('card',{attrs:{"title":"子公司要闻","more":true,"width":"384"},on:{"onMore":function($event){return _vm.onMore('subsidiaryInformation')}}},[_c('div',{staticClass:"focus-news"},[(_vm.zgsywList[0] && _vm.zgsywList[0].adjunctId && _vm.zgsywList[0].adjunctId.length > 0)?_c('div',{staticClass:"show-img",class:{ expir: _vm.itemClass(_vm.zgsywList[0]) },on:{"click":function($event){return _vm.toDetail(_vm.zgsywList[0], 'subsidiaryInformation')}}},[_c('div',{staticClass:"icon"},[_vm._v(_vm._s(_vm.itemClass(_vm.zgsywList[0]) ? '已结束' : 'NEW'))]),_c('img',{staticClass:"show-img-main",attrs:{"src":`${_vm.baseUrl}/portal/fileManagement/previewAdjunct.dhtml?adjunctId=${_vm.zgsywList[0].adjunctId}`,"alt":""}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.zgsywList[0].title))]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.zgsywList[0].publishTime.split(' ')[0]))])])]):_vm._e(),(_vm.zgsywList.length > 0)?_c('div',{staticClass:"focus-news-list"},_vm._l((_vm.zgsywList),function(item){return _c('div',{key:item.id,staticClass:"focus-news-item",class:{ expir: _vm.itemClass(item) },on:{"click":function($event){return _vm.toDetail(item, 'subsidiaryInformation')}}},[_c('div',{staticClass:"title"},[_c('img',{staticClass:"icon",attrs:{"src":!_vm.itemClass(item)
											? require('@/assets/image/home/focus-news-actived.png')
											: require('@/assets/image/home/focus-news.png'),"alt":""}}),_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.publishTime.split(' ')[0]))])])}),0):_vm._e(),(_vm.zgsywList.length == 0)?_c('no-data'):_vm._e()],1)])],1),_c('card',{staticClass:"mt",attrs:{"width":"384","headTab":true,"headTabList":['公告', '通知'],"more":true},on:{"onMore":function($event){return _vm.onMore('announcementNotice')}},scopedSlots:_vm._u([{key:"0",fn:function(){return [(_vm.ggList.length > 0)?_c('div',{staticClass:"info-list"},_vm._l((_vm.ggList),function(item){return _c('div',{key:item.id,staticClass:"info-item",class:{ expir: _vm.itemClass(item) },on:{"click":function($event){return _vm.toDetail(item, 'announcement', 0)}}},[_c('div',{staticClass:"title"},[_c('img',{staticClass:"icon",attrs:{"src":!_vm.itemClass(item)
										? require('@/assets/image/home/focus-news-actived.png')
										: require('@/assets/image/home/focus-news.png'),"alt":""}}),_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.publishTime.split(' ')[0]))])])}),0):_vm._e(),(_vm.ggList.length == 0)?_c('no-data',{attrs:{"height":"220"}}):_vm._e()]},proxy:true},{key:"1",fn:function(){return [(_vm.tzList.length > 0)?_c('div',{staticClass:"info-list"},_vm._l((_vm.tzList),function(item){return _c('div',{key:item.id,staticClass:"info-item",class:{ expir: _vm.itemClass(item) },on:{"click":function($event){return _vm.toDetail(item, 'notice', 1)}}},[_c('div',{staticClass:"title"},[_c('img',{staticClass:"icon",attrs:{"src":!_vm.itemClass(item)
										? require('@/assets/image/home/focus-news-actived.png')
										: require('@/assets/image/home/focus-news.png'),"alt":""}}),_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.publishTime.split(' ')[0]))])])}),0):_vm._e(),(_vm.tzList.length == 0)?_c('no-data',{attrs:{"height":"220"}}):_vm._e()]},proxy:true}])}),_c('card',{staticClass:"mt",attrs:{"title":"综合信息","more":true,"width":"384"},on:{"onMore":function($event){return _vm.onMore('integratedInformation')}}},[(_vm.zhxxList.length > 0)?_c('div',{staticClass:"info-list"},_vm._l((_vm.zhxxList),function(item){return _c('div',{key:item.id,staticClass:"info-item",class:{ expir: _vm.itemClass(item) },on:{"click":function($event){return _vm.toDetail(item, 'integratedInformation')}}},[_c('div',{staticClass:"title"},[_c('img',{staticClass:"icon",attrs:{"src":!_vm.itemClass(item)
									? require('@/assets/image/home/focus-news-actived.png')
									: require('@/assets/image/home/focus-news.png'),"alt":""}}),_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.publishTime.split(' ')[0]))])])}),0):_vm._e(),(_vm.zhxxList.length == 0)?_c('no-data',{attrs:{"height":"220"}}):_vm._e()],1),_c('div',{staticClass:"bb mt"},[_c('card',{attrs:{"width":"384"}},[_c('div',{staticClass:"ctny"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.shareData.shareCompanyName))]),_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.shareData.shareCode))])]),_c('div',{staticClass:"right",class:{ red: _vm.shareData.increase && _vm.shareData.increase > 0 }},[(_vm.shareData.increase && _vm.shareData.increase > 0)?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/image/home/stock.png"),"alt":""}}):_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/image/home/fall.png"),"alt":""}}),_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.shareData.currentPrice))]),_c('div',{staticClass:"increase"},[_vm._v(_vm._s(`(${_vm.shareData.increase || 0}%)`))])])])]),_c('card',{attrs:{"title":"下载中心","more":true,"width":"384"},on:{"onMore":function($event){return _vm.onMore('downloadCenter')}}},[_c('div',{staticClass:"download-box"},_vm._l((_vm.downloadList),function(item,index){return _c('div',{key:item.id,staticClass:"download-item",on:{"click":function($event){return _vm.onMore('downloadCenter', index)}}},[_c('img',{staticClass:"icon",attrs:{"src":[
									require('@/assets/image/home/wenjian.png'),
									require('@/assets/image/home/ruanjian.png'),
									require('@/assets/image/home/zhidu.png')
								][index],"alt":""}}),_vm._v(" "+_vm._s(item.name)+" ")])}),0)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }