<template>
	<es-card
		class="layot-card"
		:header="header"
		:shadow="shadow"
		:border="false"
		mode="chunk"
		:style="styles"
	>
		<div slot="suffix" class="documents-suffix">
			<el-button slot="suffix" class="documents-more" type="text" @click="handleMore">
				更多
			</el-button>
		</div>
		<div class="documents-list-header" v-if="types.length">
			<div class="documents-tabs" :class="{ 'show-handle': show }">
				<div class="calendar_tabs" ref="box">
					<ul
						class="calendar_tabs-content"
						ref="types"
						:style="{ width: width + 'px', left: left + 'px' }"
					>
						<li
							class="calendar_tabs-list"
							:class="{ 'is-select': item.value == active }"
							v-for="item in types"
							@click="handleChangeType(item)"
							:key="item.value"
						>
							{{ item.name }}
						</li>
					</ul>
				</div>
				<div class="documents-tpye-handle" v-show="show">
					<i class="documents-tpye-prev es-icon-pointer-left" @click="handlePrev"></i>
					<i class="documents-tpye-next es-icon-pointer-right" @click="handleNext"></i>
				</div>
			</div>
		</div>
		<div class="documents-new" v-else>
			<div
				class="documents-new-list"
				v-for="(item, index) in newList"
				:key="item.title + index"
				:title="item.title"
				@click="handleClick(item)"
			>
				<div class="es-text-ellipsis-row2">
					{{ item.title }}
				</div>
				<div class="documents-list-new">NEW</div>
			</div>
		</div>
		<ul class="documents-list" :class="{ 'documents-lists-new': showNew }">
			<li
				class="documents-list-item"
				v-for="(item, index) in lists"
				:key="item.title + index"
				:title="item.title"
				@click="handleClick(item)"
			>
				<i class="documents-list-item-icon es-icon-wenjian"></i>
				{{ item.title }}
				<span class="documents-list-item-other">详情</span>
			</li>
		</ul>
		<es-dialog size="lg" title="文档库" :visible.sync="more">
			<es-data-table
				v-if="more"
				numbers
				page
				url="/docmanage/doc/docManage/moreListJsonNew.dhtml"
				:toolbar="toolbar"
				:thead="thead"
			></es-data-table>
		</es-dialog>
		<es-dialog v-if="visible" size="lg" title="查看文档" :visible.sync="visible">
			<iframe v-if="url" frameborder="0" width="100%" height="100%" :src="url"></iframe>
		</es-dialog>
	</es-card>
</template>
<script>
export default {
	name: 'Documents',
	props: {
		height: {
			type: [String, Number],
			default: '374'
		},
		header: {
			type: String,
			default: '文档库'
		},
		shadow: {
			type: String,
			default: 'never'
		},
		sysCode: String,
		showNew: {
			type: Boolean,
			default: true
		},
		pageNumber: {
			type: Number,
			default: 8
		}
	},
	watch: {
		active(val) {
			this.getData(val);
		}
	},
	computed: {
		styles() {
			return { height: parseInt(this.height) + 'px' };
		},
		newList() {
			return this.showNew ? this.list.slice(0, 3) : [];
		},
		lists() {
			return this.showNew ? this.list.slice(3) : this.list;
		}
	},
	data() {
		return {
			visible: false,
			types: [],
			active: 0,
			show: false,
			boxWidth: '',
			width: '',
			left: 0,
			list: [],
			url: '',
			more: false,
			toolbar: [
				{
					type: 'search',
					reset: true,
					contents: [
						{
							type: 'text',
							label: '关键字',
							name: 'keyword',
							placeholder: '请输入关键字'
						}
					]
				}
			],
			thead: [
				{
					title: '标题',
					field: 'documentTitle',
					align: 'left',
					showOverflowTooltip: true,
					minWidth: '160'
				},
				{
					title: '发布时间',
					align: 'center',
					field: 'publishDate',
					width: '160'
				},
				{
					title: '录入人',
					align: 'center',
					field: 'publishPerson',
					width: '140'
				},
				{
					title: '所属应用',
					align: 'center',
					field: 'docFrom',
					width: '160'
				},
				{
					title: '操作',
					type: 'handle',
					width: '100',
					template: '',
					events: [
						{
							text: '查看',
							type: 'text',
							event: res => {
								this.handleClick(res.row);
							}
						}
					]
				}
			]
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			if (this.sysCode) {
				this.$.ajax({
					url: '/sys/v1/mecpSys/findSysCode.dhtml',
					params: { sysAppCode: this.sysCode } //'doc_manage_type_code'
				})
					.then(res => {
						if (res.rCode == 0) {
							let { results } = res;
							let types = [];
							results.forEach(item => {
								let { shortName, cciValue } = item;
								types.push({
									name: shortName,
									value: cciValue,
									show: true
								});
							});
							this.types = types;
							this.active = types[0].value;
							this.$nextTick(() => {
								if (this.$refs.box) {
									let { width } = this.$refs.box.getBoundingClientRect();
									this.boxWidth = width;
									let w = this.type.length * 120;
									this.show = w > width;
									if (w > width) {
										this.show = true;
										this.width = w;
									} else {
										this.show = false;
										this.width = '';
									}
								}
							});
						}
					})
					.catch(err => {});
			} else {
				this.getData();
			}
		},
		getData(active) {
			let param = active
				? {
						docType: active,
						num: this.pageNumber,
						pageNumber: 1
				  }
				: {
						num: this.pageNumber,
						pageNumber: 1
				  };
			this.$.ajax({
				url: '/docmanage/doc/zxwdListsByPager.dhtml',
				params: param
			})
				.then(res => {
					if (res.rCode == 0) {
						this.list = res.results.list;
					}
				})
				.catch(err => {});
		},
		handleMore() {
			this.more = true;
			//this.moreUrl = '/docmanage/doc/docManage/moreList.dhtml';
		},
		handleChangeType(res) {
			this.active = res.value;
		},
		handleNext() {
			if (this.left < 0) {
				this.left += 120;
			}
		},
		handlePrev() {
			if (this.boxWidth + Math.abs(this.left) < this.width) {
				this.left -= 120;
			}
		},
		handleClick(res) {
			if (res.detailUrl || res.viewUrl) {
				this.url = res.detailUrl || res.viewUrl;
			} else if (res.documentFrom == 2) {
				this.url = '/docmanage/docManage/viewDocument.dhtml?id=' + res.id;
			} else if (res.documentFrom == 1 || res.documentFrom == 0) {
				this.url =
					'/archives/archives/centerdetail/toReadDocument.dhtml?id=' +
					res.id +
					'&businesstype=' +
					res.documentFrom;
			}
			this.visible = true;
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep {
	.el-button--mini {
		padding: 4px 8px;
	}
}
.documents-suffix {
	flex: 1;
	text-align: right;
}
.documents-list-header {
	position: relative;
	height: 30px;
	.documents-tabs {
		background-color: #ecf0fa;
		border-radius: 20px;
		position: relative;
		&.show-handle {
			padding-right: 48px;
		}
		.calendar_tabs {
			overflow: hidden;
			.calendar_tabs-content {
				display: flex;
				position: relative;
				.calendar_tabs-list {
					width: 120px;
					height: 30px;
					line-height: 30px;
					cursor: pointer;
					text-align: center;
					&.is-select {
						border-radius: 20px;
						background-color: $--color-primary;
						color: #fff;
					}
				}
			}
		}
		.documents-tpye-handle {
			position: absolute;
			right: 0;
			top: 0;
			line-height: 30px;
			padding-right: 8px;
			.documents-tpye-prev {
				margin-right: 4px;
				cursor: pointer;
			}
			.documents-tpye-next {
				cursor: pointer;
			}
		}
	}
	.documents-list-type {
		position: absolute;
		right: 0;
		top: 0;
	}
}
.documents-new {
	display: flex;
	padding-bottom: 20px;
	border-bottom: 1px solid #dfebf6;
	.documents-new-list {
		flex: 1;
		position: relative;
		padding: 10px 30px 8px 12px;
		line-height: 18px;
		background-color: rgba(37, 145, 247, 0.08);
		border-radius: 9px;
		cursor: pointer;
		.documents-list-new {
			height: 20px;
			font-size: 10px;
			color: #fff;
			line-height: 20px;
			padding: 0 4px;
			background-color: $--color-primary;
			border-radius: 0px 9px 0px 9px;
			position: absolute;
			right: 0;
			top: 0;
		}
		& + .documents-new-list {
			margin-left: 12px;
		}
	}
}
.documents-list {
	padding: 10px;
	.documents-list-item {
		padding: 0 30px 0 20px;
		line-height: 30px;
		cursor: pointer;
		position: relative;
		border-bottom: 1px solid #dfebf6;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		.es-icon-wenjian {
			font-size: 16px;
			color: $--color-primary;
			position: absolute;
			left: 0;
		}
		.documents-list-item-other {
			font-size: 14px;
			color: $--color-primary;
			position: absolute;
			right: 0;
		}
	}
	&.documents-lists-new {
		padding: 0 0 10px 0;
		.documents-list-item {
			line-height: 40px;
		}
	}
}
</style>
