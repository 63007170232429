/*
	//路由配置
	列：
	{
		path: '/convoke',
		title: 'xxxx',
		name: 'convoke',
		component: resolve => require(['@/views/xxxx/Convoke.vue'], resolve)
	}
*/
import open from './open.js';
import portal from './portal'
import dutyDisplay from './dutyDisplay.js';
import operateAnalysisShow from './operateAnalysisShow.js';
import operateAnalysisShowXc from './operateAnalysisShowXc.js';
export default [
	{
		path: '/error',
		name: 'error',
		title: '',
		component: resolve => require(['@/views/public/Error.vue'], resolve)
	},
	{
		//中转页面实现路由刷新
		path: '/transfer',
		title: '',
		name: 'transfer',
		component: resolve => require(['@/views/public/Transfer.vue'], resolve)
	},
	// 业务流程页面
	{
		path: '/process',
		title: '业务流程',
		name: 'process',
		component: resolve => require(['@/views/public/Process.vue'], resolve)
	},
	{
		path: '/workbench',
		title: '个人工作台',
		name: 'workbench',
		//新main ，命名视图路由使用此方式 创建菜单时需要对应属性名
		components: {
			default:resolve => require(['@/views/workbench/Index.vue'], resolve),
			grsy: resolve => require(['@/views/workbench/Index.vue'], resolve)
		}
	},
	...open,
	...portal,
	...dutyDisplay,
	...operateAnalysisShow,
	...operateAnalysisShowXc
];
