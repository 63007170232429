import { common } from "@/http/operateAnalysisShow";
const Mixins = {
  data() {
    return {
      date: "",
    };
  },
  mounted() {
    this.getDate();
  },
  methods: {
    getDate() {
      const loading = this.$.loading(this.$loading, "加载中");
      this.$.ajax({
        url: common.recentDate,
      })
        .then((res) => {
          loading.close()
          const { rCode, results } = res;
          if (rCode == 0) {
            this.date = results.date;
          }
        })
        .catch((error) => {
          loading.close()
        });
    },
  },
};
export default Mixins;
