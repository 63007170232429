<template>
	<ul class="es-page-unit" :class="{ 'es-horizontal': mode == 'horizontal' }" :style="styles">
		<li
			class="es-page-unit-item"
			:class="{ 'es-pointer': item.path || item.pointer || item.open }"
			v-for="(item, index) in list"
			:key="index"
			v-on="{ ...item.events }"
			@click="handleClick(item)"
		>
			<template v-if="mode == 'horizontal'">
				<template v-if="item.icon">
					<es-icon class="es-page-unit-item-icon" :contents="item.icon"></es-icon>
				</template>
				<img class="es-page-unit-item-img" :src="item.img" v-if="item.img" />
				<span class="es-page-unit-item-label">{{ item.label || item.name || item.text }}</span>
				<span class="es-page-unit-item-value">{{ item.value }}</span>
				<span class="es-page-unit-item-unit">{{ item.unit }}</span>
			</template>
			<template v-else>
				<div class="es-page-unit-item-icon-box" :style="getStyle(item, index)">
					<template v-if="item.icon">
						<es-icon class="es-page-unit-item-icon" :contents="item.icon"></es-icon>
					</template>
					<img class="es-page-unit-item-img" :src="item.img" v-if="item.img" />
				</div>
				<div class="es-page-unit-item-content-box">
					<div class="es-page-unit-item-label">{{ item.label || item.name || item.text }}</div>
					<div class="es-page-unit-item-value">
						{{ item.value }}
						<span class="es-page-unit-item-unit">{{ item.unit }}</span>
					</div>
				</div>
			</template>
		</li>
	</ul>
</template>
<script>
export default {
	name: 'Statistic',
	props: {
		data: {
			type: Array,
			default() {
				return [];
			}
		},
		mode: String,
		bgColors: {
			type: Array,
			default() {
				return ['#3863C6', '#5BBF77', '#F0B672', '#5DC6D4'];
			}
		},
		height: {
			type: Number,
			default: 92,
			validator: function (value) {
				return value > 91;
			}
		}
	},
	computed: {
		list() {
			return this.value.length ? this.value : this.data;
		},
		styles() {
			return { height: this.height + 'px' };
		}
	},
	data() {
		return {
			value: []
		};
	},
	methods: {
		getStyle(res, index) {
			let { color, bgColor } = res;
			if (!bgColor) {
				bgColor = this.bgColors ? this.bgColors[index] : '';
			}
			let styles = { color: color, 'background-color': bgColor };
			return styles;
		},
		getEvents(events) {
			if (events) {
				// eslint-disable-next-line no-unused-vars
				let { click, ...event } = events;
				return event;
			}
			return {};
		},
		handleClick(res) {
			let { events, path, open, query } = res;
			if (path) {
				this.$router.push({ path: path, query: query });
			}
			if (events && events.click) {
				events.click(res);
			}
			if (open) {
				window.top.open(open);
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.es-page-unit {
	display: flex;
	justify-content: space-around;
	padding: 20px;
	border-radius: 6px;
	background-color: #fff;
	.es-page-unit-item {
		display: flex;
		align-items: center;
		height: 52px;
		&.es-pointer {
			cursor: pointer;
		}
		.es-page-unit-item-icon {
			font-size: 24px;
		}
		.es-page-unit-item-img {
			height: 24px;
		}
		.es-page-unit-item-label {
			font-size: 14px;
			margin-left: 4px;
		}
		.es-page-unit-item-value {
			font-size: 22px;
			margin-left: 4px;
			position: relative;
			top: -1px;
		}
		.es-page-unit-item-unit {
			font-size: 14px;
		}
	}
	.es-page-unit-item-icon-box {
		width: 52px;
		height: 52px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		.es-page-unit-item-icon {
			font-size: 26px;
			color: #fff;
		}
	}
	.es-page-unit-item-content-box {
		margin-left: 12px;
		.es-page-unit-item-label {
			line-height: 20px;
			margin-left: 0;
		}
		.es-page-unit-item-value {
			font-size: 24px;
			font-weight: bold;
			line-height: 32px;
			top: 0;
			margin-left: 0;
		}
	}
}
</style>
