export default [
    {
        path: '/portalRedirect',
        name: 'redirect',
        title: '重定向',
        component: resolve => require(['@/views/portal/Redirect.vue'], resolve)
    },
    {
        path: '/portal',
        name: 'portal',
        title: '川投门户',
        // redirect:'/portal/home',
        component: resolve => require(['@/views/portal/MainIndex.vue'], resolve),
        children: [
            {
                path: '/portal/home',
                name: 'home',
                title: '首页',
                meta: {
                    name: '首页'
                },
                component: resolve => require(['@/views/portal/children/home/Home.vue'], resolve),
            },
            //信产用的Information Industry
            {
                path: '/portal/homeII',
                name: 'homeII',
                title: '首页',
                meta: {
                    name: '首页'
                },
                component: resolve => require(['@/views/portal/children/home/Home.vue'], resolve),
            },
            //首页的二级页面都用一个vue文件，组件里通过不同的理由去做不同请求和渲染
            {
                path: '/portal/groupNews',
                name: 'groupNews',
                title: '集团要闻',
                meta: {
                    name: '集团要闻'
                },
                component: resolve => require(['@/views/portal/children/home/SecondLevel.vue'], resolve),
            }, {
                path: '/portal/subsidiaryInformation',
                name: 'subsidiaryInformation',
                title: '子公司要闻',
                meta: {
                    name: '子公司要闻'
                },
                component: resolve => require(['@/views/portal/children/home/SecondLevel.vue'], resolve),
            }, {
                path: '/portal/announcementNotice',
                name: 'announcementNotice',
                title: '公告/通知',
                meta: {
                    name: '公告/通知'
                },
                component: resolve => require(['@/views/portal/children/home/SecondLevel.vue'], resolve),
            }, {
                path: '/portal/integratedInformation',
                name: 'integratedInformation',
                title: '综合信息',
                meta: {
                    name: '综合信息'
                },
                component: resolve => require(['@/views/portal/children/home/SecondLevel.vue'], resolve),
            }, 
            {
                path: '/portal/companyDocumentsSystemCompilation',
                name: 'companyDocumentsSystemCompilation',
                title: '公司文件/制度汇编',
                meta: {
                    name: '公司文件/制度汇编'
                },
                component: resolve => require(['@/views/portal/children/home/SecondLevel.vue'], resolve),
            }, 
            {
                path: '/portal/downloadCenter',
                name: 'downloadCenter',
                title: '下载中心',
                meta: {
                    name: '下载中心'
                },
                component: resolve => require(['@/views/portal/children/home/SecondLevel.vue'], resolve),
            },
            //所有的二级页面都用一个vue文件，组件里通过不同的理由去做不同请求和渲染
            {
                path: '/portal/complianceManagement',
                name: 'complianceManagement',
                title: '合规管理',
                meta: {
                    name: '合规管理'
                },
                component: resolve => require(['@/views/portal/children/secondLevel/SecondLevel.vue'], resolve),
            },
            {
                path: '/portal/compilationOfSystems',
                name: 'compilationOfSystems',
                title: '制度汇编',
                meta: {
                    name: '制度汇编'
                },
                component: resolve => require(['@/views/portal/children/secondLevel/SecondLevel.vue'], resolve),
            }, {
                path: '/portal/safetyAndEnvironmentalProtection',
                name: 'safetyAndEnvironmentalProtection',
                title: '安全环保',
                meta: {
                    name: '安全环保'
                },
                component: resolve => require(['@/views/portal/children/secondLevel/SecondLevel.vue'], resolve),
            }, {
                path: '/portal/confidentialityAndSecurity',
                name: 'confidentialityAndSecurity',
                title: '保密安全',
                meta: {
                    name: '保密安全'
                },
                component: resolve => require(['@/views/portal/children/secondLevel/SecondLevel.vue'], resolve),
            },
            {
                path: '/portal/detail',
                name: 'detail',
                title: '详情',
                component: resolve => require(['@/views/portal/children/detail/Detail.vue'], resolve),
            },
            //搜素
            {
                path: '/portal/search',
                name: 'search',
                title: '详情',
                component: resolve => require(['@/views/portal/children/search/Search.vue'], resolve),
            },
        ]
    },
];