<template>
	<el-scrollbar class="page-scroll" :view-class="!isEnum ? 'layout-alone' : ''">
		<slot name="title"></slot>
		<template v-if="isEnum">
			<el-row class="layout-row" :gutter="gutter" v-for="(item, indexs) in contents" :key="indexs">
				<el-col v-for="(ele, index) in item" :key="ele.id || index" :span="ele.span">
					<component v-bind="ele" v-on="ele.events" :is="ele.type"></component>
				</el-col>
			</el-row>
		</template>
		<el-row v-else class="layout-row" :gutter="gutter">
			<el-col
				v-for="(item, index) in contents"
				class="layout-col"
				:key="item.id || index"
				:lg="item.span > 9 ? 24 : 12"
				:xlg="item.span"
			>
				<component v-bind="item" v-on="item.events" :is="item.type"></component>
			</el-col>
		</el-row>
		<slot></slot>
	</el-scrollbar>
</template>
<script>
const modulesFiles = require.context('./public', true, /.vue$/);
const components = modulesFiles.keys().reduce((modules, modulePath) => {
	const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
	const value = modulesFiles(modulePath);
	modules[moduleName] = value.default;
	return modules;
}, {});
console.log('components', components);
export default {
	name: 'Layout',
	components: components,
	props: {
		gutter: {
			type: Number,
			default: 20
		},
		contents: Array
	},
	computed: {
		isEnum() {
			return this.contents && Array.isArray(this.contents) && Array.isArray(this.contents[0]);
		}
	},
	data() {
		return {};
	}
};
</script>
<style lang="scss" scoped>
::v-deep .el-scrollbar__view {
	padding: 16px;
	&.layout-alone {
		padding-top: 6px;
		padding-bottom: 6px;
	}
}
.layout-ow + .layout-row {
	margin-top: 16px;
}
.layout-col {
	padding-top: 10px;
	padding-bottom: 10px;
}
</style>
