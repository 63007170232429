<template>
  <div class="pie" :id="mid"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    mid: { default: "" },
    data: { default: {} },
    color: { default: "" },
  },
  mounted() {
    this.$nextTick(function () {
      this.draw(this.mid, this.data);
    });
  },
  watch: {
    data: {
      deep: true,
      handler: (val) => {
        this.$nextTick(() => {
          this.draw(this.mid, val);
        });
      },
    },
  },
  methods: {
    draw: function (id, data) {
      let dom = document.getElementById(id);
      let that = this;
      if (!dom) {
        return false;
      }
      let chart = echarts.init(dom);
      chart.clear();
      let option = {
        //   backgroundColor: '#0a1a38',
        title: {
          text: `{a|${data.value}}` + `\n` + `{b|占比${data.percentage}%}`,
          textStyle: {
            rich: {
              a: {
                fontSize: 24,
                fontWeight: 600,
                color: "#333",
                lineHeight: 26,
              },
              b: {
                fontSize: 12,
                color: "#333",
                lineHeight: 24,
              },
            },
          },
          left: "center",
          top: "24%",
        },
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            data: [
              {
                value: data.percentage,
                itemStyle: {
                  borderRadius: [0, 10, 0, 10],
                },
              },
              100 - data.percentage,
            ],
            radius: ["115%", "140%"],
            left: "center",
            center: ["50%", "40%"],
            color: [
              new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#eee",
                },
                {
                  offset: 1,
                  color: that.color,
                },
              ]),
              "#eee",
            ],
            emphasis: {
              disabled: true,
            },
            label: {
              show: false,
            },
          },
        ],
      };
      chart.setOption(option);
      window.addEventListener("resize", () => {
        chart && chart.resize();
      });
    },
  },
};
</script>

<style>
.pie {
  width: 100%;
  height: 100%;
}
</style>