<!--搜素-->
<template>
	<div class="secondLevel">
		<div class="list-box">
			<div class="toolbar">
				<div class="label">找到{{ resSearchKey || '--' }}的结果数约{{ listTotal || 0 }}条</div>
				<div class="search">
					<es-input v-model="searchKey" placeholder="请输入关键字"></es-input>
					<es-button class="btn" icon="es-icon-magnifier" @click="onSearch"></es-button>
				</div>
			</div>
			<div class="list">
				<div class="list-item" v-for="(item, index) in list" :key="index" @click="onClick(item)">
					<div class="left">
						<div class="icon" />
						{{ item.title }}
					</div>
					<div class="right">{{ item.publishTime.split(' ')[0] }}</div>
				</div>
				<no-data v-if="list.length == 0" />
				<div class="page-box">
					<el-pagination
						background
						@size-change="onListSizeChange"
						@current-change="onListPageChange"
						:current-page.sync="listPageNum"
						:page-size="listPageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="listTotal"
						:page-sizes="[10, 20, 30, 40, 50]"
					></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NoData from '../components/NoData';
export default {
	components: { NoData },
	data() {
		return {
			searchKey: '',
			resSearchKey: '',
			listPageNum: 1,
			listPageSize: 10,
			listTotal: 0,
			list: [],
			baseUrl: window.location.origin + (window.location.hostname == 'localhost' ? '/dev-api' : '')
		};
	},
	created() {
		this.searchKey = this.$route.query.searchKey;
	},
	methods: {
		onListPageChange(num) {
			this.listPageNum = num;
			this.getList();
		},
		onListSizeChange(size) {
			this.listPageSize = size;
			this.getList();
		},
		//拿列表
		getList() {
			let loading = this.$.loading(this.$loading, '加载中...');
			this.$.ajax({
				url: `${this.$url.search}?keyWord=${this.searchKey}`,
				data: {
					page: this.listPageNum,
					rows: this.listPageSize
				},
				method: 'POST'
			}).then(res => {
				loading.close();
				
					this.resSearchKey = this.searchKey;
					this.list = res.data;
					this.listTotal = res.totalrecords;
				
			});
		},
		onClick(item) {
			const { href } = this.$router.resolve({
				name: 'detail',
				query: {
					id: item.id,
					name: 'search'
				}
			});
			window.open(href, '_blank');
		},
		onSearch() {
			this.getList();
		}
	}
};
</script>

<style lang=scss scoped>
.secondLevel {
	width: 100%;
	.list-box {
		width: 100%;
		padding: 24px 0;
		background: #f4f6fd;
		.toolbar {
			width: 1200px;
			margin: 0 auto;
			padding: 0 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: #2591f7;
			height: 60px;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			.label {
				font-size: 16px;
				color: #ffffff;
			}
			.search {
				display: flex;
				align-items: center;
				::v-deep .el-input__inner {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
				.btn {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					background: #ffffff;
					color: #2591f7;
					border: none;
					height: 40px;
				}
			}
		}
		.list {
			width: 1200px;
			margin: 0 auto;
			padding: 0 24px;
			background: #ffffff;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
			.list-item {
				width: 100%;
				padding: 22px 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				cursor: pointer;
				border-bottom: 1px solid rgba($color: #dfebf6, $alpha: 0.7);
				.left {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 16px;
					color: #15224c;
					align-items: center;
					width: 520px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					.icon {
						width: 8px;
					flex-shrink: 0;
						display: inline-block;
						height: 8px;
						background: url('@/assets/image/home/focus-news-actived.png') no-repeat;
						background-size: 100% 100%;
						margin-right: 18px;
					}
				}
				.right {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 16px;
					color: #737a94;
				}
				&:hover {
					.left {
						color: #2591f7;
					}
					.right {
						color: #2591f7;
					}
				}
			}
		}
		.page-box {
			width: 100%;
			height: 80px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
</style>