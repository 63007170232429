<template>
  <div id="big-box" ref="big_box" style="width: 100%; height: 100%">
  <div class="content"  id="container">
    <h2 class="title">
      川投集团值班预警监控平台
      <div class="date" v-html="headTitle"></div>
    </h2>
    <div class="info">
      <div class="left">
        <div class="statistics">
          <div class="info-title">
            <div class="icon" />
            本年度值班填报情况统计
          </div>
          <div class="chart">
            <div class="chart-info">
              <MoreBars mid="statistics" :data="statisticsData"></MoreBars>
            </div>
          </div>
        </div>
        <div class="warning-center">
          <div class="info-title">
            <div class="icon" />
            预警中心
          </div>
          <div class="table">
            <div class="thead">
              <div
                class="item"
                :style="{ width: `calc(100% / ${warnThead.length})` }"
                v-for="(item, index) of warnThead"
                :key="index"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="tbody">
              <el-marquee
                :data="warnDatas"
                class="seamless-warp"
                :disabled="warnDatas.length < 12"
              >
                <ul v-for="(item, index) of warnDatas" :key="index">
                  <li
                    v-for="(i, ind) of warnThead"
                    :key="ind"
                    :style="{ width: `calc(100% / ${warnThead.length})` }"
                  >
                    <span
                      :class="{
                        fxyj: i.value == 'duty_situation_status_text',
                        low: item.duty_situation_status == null,
                        medium: item.duty_situation_status == 1,
                        high: item.duty_situation_status == 2,
                      }"
                    >
                      {{ item[i.value] }}
                    </span>
                  </li>
                </ul>
              </el-marquee>
            </div>
          </div>
        </div>
        <div class="personnel">
          <div class="info-title">
            <div class="icon" />
            本日值班人员
          </div>
          <div class="table">
            <div class="thead">
              <div class="item">单位</div>
              <div class="item">值班人员</div>
              <div class="item">联系方式</div>
              <div class="item">值班反馈</div>
            </div>
            <div class="tbody">
              <el-marquee
                :data="personnelData"
                class="seamless-warp"
                :disabled="personnelData.length < 12"
              >
                <ul v-for="(item, index) of personnelData" :key="index">
                  <li>{{ item.insert_org_name }}</li>
                  <li>{{ item.name }}</li>
                  <li>{{ item.phone }}</li>
                  <li
                    :style="{
                      color:
                        item.duty_feedback_status_text == '未反馈'
                          ? 'rgb(227 122 111)'
                          : '#333',
                    }"
                  >
                    {{ item.duty_feedback_status_text }}
                  </li>
                </ul>
              </el-marquee>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right-item item1">
          <div class="info-title">
            <div class="icon" />
            本日二级公司填报数据
          </div>
          <div class="info-box">
            <div class="box-item" v-for="item in tianbaoData" :key="item.name">
              <div class="name">{{ item.name }}</div>
              <div class="num">{{ item.value }}</div>
            </div>
          </div>
        </div>
        <div class="right-item item2">
          <div class="info-title">
            <div class="icon" />
            {{ nowYear }}二级公司年度值班填报情况
          </div>
          <div class="pie-box">
            <div
              class="pie-item"
              v-for="(item, index) in companyDutyNumber"
              :key="item.name"
            >
              <RightPie
                :mid="'pie' + index"
                :data="item"
                :color="item.color"
              ></RightPie>
              <div class="pie-title">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="right-item item3">
          <div class="info-title">
            <div class="icon" />
            {{ dutyStatisticsScoreTitle }}
          </div>

          <div class="org-box">
            <el-marquee
              :data="dutyStatisticsScore"
              class="seamless-warp"
              :disabled="dutyStatisticsScore.length < 8"
            >
              <div
                class="org-item"
                v-for="(item, index) in dutyStatisticsScore"
                :key="index"
              >
                <div class="name">{{ item.name }}</div>
                <div class="value">{{ item.value }}</div>
              </div>
            </el-marquee>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import MoreBars from "./components/MoreBars.vue";
import RightPie from "./components/RightPie.vue";
export default {
  components: { MoreBars, RightPie },
  data() {
    return {
      statisticsData: {
        color: [
          ["#f57f27", "#faa765"],
          ["#8df2af", "#8df2af"],
          ["#dbda2b", "#dbda2b"],
        ],
        name: "",
        unit: "",
        list: [
          {
            name: "已填报",
            data: [],
          },
          {
            name: "填报不及时",
            data: [],
          },
          {
            name: "未填报",
            data: [],
          },
        ],
      },
      personnelData: [],
      warnThead: [
        {
          name: "值班日期",
          value: "duty_date_f",
        },
        {
          name: "单位",
          value: "insert_org_name",
        },
        {
          name: "值班标题",
          value: "title",
        },
        {
          name: "带班领导",
          value: "class_leader",
        },
        {
          name: "是否上报",
          value: "duty_report_status_text",
        },
        {
          name: "风险预警",
          value: "duty_situation_status_text",
        },
      ],
      warnDatas: [],
      tianbaoData: [],
      companyDutyNumber: [],
      dutyStatisticsScoreTitle: "",
      dutyStatisticsScore: [],
      headTitle: "",
      nowYear: "",
    };
  },
  mounted() {
    this.getData();
    this.nowYear = new Date().getFullYear();
    setInterval(() => {
      this.headTitle = this.headDate();
    }, 1000);
    //监听
    let _this = this;
    _this.$listenDom.listenTo(_this.$refs.big_box, (element) => {
      _this.$nextTick(() => {
        _this.setScale();
      });
    });
  },
  beforeDestroy() {
    let _this = this;
    // 离开页面删除检测器和所有侦听器
    _this.$listenDom.uninstall(_this.$refs.big_box); // 这里用ref是因为vue离开页面后获取不到dom
  },
  methods: {
    //设置缩放
    setScale() {
      let dom = document.getElementById("container");
      let domp = document.getElementById("big-box");
      dom.style.width = "1650px";
      let h = 901 * (window.screen.width / 1920) * (1650 / domp.offsetWidth);
      dom.style.height = h + "px";
      let scale =
        "scale(" + domp.offsetWidth / 1650 + "," + domp.offsetHeight / h + ")";
      dom.style.transform = scale;
      dom.style.transformOrigin = "0 0";
    },
    getData() {
      this.$.ajax({
        url: "/dutymanage/dutyStatisticsApi/dutyStatisticsApi.json",
      }).then((res) => {
        let { results, rCode } = res;
        if (rCode == 0) {
          this.statisticsData = {
            color: [
              ["#3e93ed", "#66a6ed"],
              ["#f57f27", "#faa765"],
              ["#a1ccf9", "#d7e5f0"],
            ],
            name: "",
            unit: "",
            list: [
              {
                name: "已填报",
                data: [],
              },
              {
                name: "填报不及时",
                data: [],
              },
              {
                name: "未填报",
                data: [],
              },
            ],
          };
          results.dutyReportStatistics.timelyNum.forEach((e, i) => {
            let org = results.dutyReportStatistics.orgXData[i];
            let v1 = results.dutyReportStatistics.timelyNum[i];
            let v2 = results.dutyReportStatistics.unTimelyNum[i];
            let v3 = results.dutyReportStatistics.notReportedNum[i];
            this.statisticsData.list[0].data[i] = { name: org, value: v1 };
            this.statisticsData.list[1].data[i] = { name: org, value: v2 };
            this.statisticsData.list[2].data[i] = { name: org, value: v3 };
          });
          this.personnelData = results.currentDutyUser;
          this.warnDatas = results.warningCenter;
          this.warnDatas.forEach((e) => {
            let date = new Date(e.duty_date);
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            let d = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
            e.duty_date_f = y + "-" + m + "-" + d;
          });
          this.tianbaoData = results.currentDutyNumber;
          let totalCompanyDutyNumber = 0;
          results.companyDutyNumber.forEach((e) => {
            totalCompanyDutyNumber += e.value;
          });
          this.companyDutyNumber = results.companyDutyNumber;
          let colorList = ["#5c85b1", "#6e9dcb", "#9d9d9d"];
          this.companyDutyNumber.forEach((e, i) => {
            e.color = colorList[i];
            e.percentage = totalCompanyDutyNumber==0?0:( ((e.value / totalCompanyDutyNumber) * 100).toFixed(2));
          });
          this.dutyStatisticsScore = results.dutyStatisticsScore;
          this.dutyStatisticsScoreTitle = results.dutyStatisticsScoreTitle;
        }
      }).catch(()=>{});
    },
    headDate() {
      let date = new Date();
      let y = date.getFullYear() + "年";
      let m = date.getMonth() + 1;
      m = (m > 9 ? m : "0" + m) + "月";
      let d = date.getDate();
      d = (d > 9 ? d : "0" + d) + "日  ";
      let h = date.getHours();
      h = (h > 9 ? h : "0" + h) + ":";
      let min = date.getMinutes();
      min = (min > 9 ? min : "0" + min) + ":";
      let s = date.getSeconds();
      s = (s > 9 ? s : "0" + s) + "  ";
      let w = date.getDay();
      let wList = [
        "星期天",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      return (
        y + m + d + wList[w] + ' <span class="time">' + h + min + s + "</span>"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  background: #fff;
  color: #333;

  .title {
    text-align: center;
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    position: relative;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ddd;
    .date {
      position: absolute;
      right: 20px;
      top: 0px;
      ::v-deep .time {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .info {
    height: calc(100% - 40px);
    width: 100%;
    display: flex;
    background: #eee;
    padding: 12px;
    .left {
      width: 74%;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      justify-content: space-between;
    }

    .right {
      margin-left: 12px;
      flex: 1;

      .right-item {
        border-radius: 8px;
        overflow: hidden;
        &.item1 {
          background: #fff;
          width: 100%;
          height: calc(25% - 9px);

          .info-box {
            height: calc(100% - 40px);
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 12px;
            .box-item {
              width: 33%;
              height: 50%;
              padding-top: 12px;
              position: relative;
              &:nth-child(-n + 3) {
                border-bottom: 1px solid #eee;
              }
              &::after {
                position: absolute;
                content: "";
                width: 1px;
                height: 52px;
                background: #eee;
                right: 0;
                top: calc(50% - 26px);
              }
              &:nth-child(3n) {
                &::after {
                  display: none;
                }
              }
              .num {
                font-size: 24px;
                text-align: center;
                font-weight: 600;
              }
              &:nth-child(1) {
                .num {
                  color: #53769e;
                }
              }
              &:nth-child(2) {
                .num {
                  color: #6ba0d4;
                }
              }
              &:nth-child(3) {
                .num {
                  color: #989898;
                }
              }
              &:nth-child(4) {
                .num {
                  color: #97d340;
                }
              }
              &:nth-child(5) {
                .num {
                  color: #ff6f00;
                }
              }
              &:nth-child(6) {
                .num {
                  color: #ef2646;
                }
              }
              .name {
                font-size: 12px;
                text-align: center;
                color: #333;
                margin-bottom: 2px;
              }
            }
          }
        }

        &.item2 {
          background: #fff;
          margin-top: 12px;
          height: calc(25% - 9px);
          .pie-box {
            height: calc(100% - 40px);
            display: flex;

            .pie-item {
              position: relative;
              width: 33.3%;
              height: 100%;

              .pie-title {
                position: absolute;
                bottom: 14px;
                text-align: center;
                width: 100%;
                font-size: 14px;
                color: #333;
              }
            }
          }
        }

        &.item3 {
          background: #fff;
          height: calc(50% - 6px);
          margin-top: 12px;
          .org-box {
            display: flex;
            width: 100%;
            height: calc(100% - 40px);
			padding: 12px;
			.el-marquee{
				width: 100%;
			}
            .org-item {
              width: 100%;
              display: flex;
              align-items: center;
              height: 40px;
			  justify-content: space-between;
			  padding: 0 12px;
&:nth-child(even){
	background: #f0f7ff;
}
              .name {
                font-size: 14px;
              }

              .value {
                font-size: 22px;
				font-weight: 600;
              }
            }
          }
        }
      }
    }

    .info-title {
      line-height: 40px;
      width: 100%;
      height: 40px;
      padding-left: 16px;
      background: #fff;
      color: #333;
      font-size: 16px;
      border-bottom: 1px solid #eee;
      .icon {
        display: inline-block;
        width: 4px;
        height: 18px;
        position: relative;
        top: 2px;
        margin-right: 8px;
        border-radius: 2px;
        background: #2e7bad;
      }
    }
  }

  // 表格
  .table {
    margin-top: 6px;
    width: 100%;

    .thead {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 4px 0;
      background-color: #2e7bad;
      color: #fff;
      .item {
        text-align: center;
      }
    }

    .tbody {
      width: 100%;
      height: calc(100% - 30px);

      .seamless-warp {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      ul {
        padding: 6px 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #fff;

        &:nth-child(even) {
          background: #f0f7ff;
        }
      }

      li {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .statistics {
    width: 100%;
    height: calc(50% - 6px);
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
    .date {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
    }

    .chart {
      height: calc(100% - 40px);
      background-color: #fff;

      .chart-info {
        width: 100%;
        height: 100%;
        background-color: none;
      }
    }
  }

  .personnel {
    width: 32%;
    height: calc(50% - 6px);
    background: #fff;
    border-radius: 8px;
    overflow: hidden;

    .table {
      width: calc(100% - 24px);
      height: calc(100% - 64px);
      margin: 12px;

      .item,
      li {
        width: calc((100% - 50px) / 4);
      }

      ul {
        color: #333;
        &:nth-child(even) {
          background: #f0f7ff;
        }
      }
    }
  }

  .warning-center {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    width: calc(68% - 12px);
    height: calc(50% - 6px);
    background: #fff;
    .remark {
      position: absolute;
      right: 20px;
      top: 10px;
      display: flex;

      .remark-item {
        margin: 0 20px;
        color: #dadada;
        display: flex;
        align-items: center;

        &::before {
          content: "";
          display: inline-block;
          margin-right: 4px;
          width: 6px;
          height: 12px;
          border: 2px solid #5ad8a6;
        }

        &:nth-child(2)::before {
          border: 2px solid #f6bd16;
        }

        &:nth-child(3)::before {
          border: 2px solid #e86452;
        }
      }
    }

    .table {
      width: calc(100% - 24px);
      height: calc(100% - 64px);
      margin: 12px;
      .thead {
        background-color: #2e7bad;
        padding: 8px 0;
      }

      .tbody {
        height: calc(100% - 34px);

        ul {
          color: #333;
          background-color: #fff;
          &:nth-child(even) {
            background-color: #f0f7ff;
          }
        }
      }
    }

    .fxyj {
      padding: 4px 8px;
      &.low {
        background: #eeeef0;
        color: #777;
      }

      &.medium {
        background: #fdeddd;
        color: #d08c5d;
      }

      &.high {
        color: #ff92a2;
        background: #fbebeb;
      }
    }
  }
}
</style>
