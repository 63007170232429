<template>
	<es-card
		class="layot-card"
		:header="header"
		:shadow="shadow"
		:border="false"
		mode="chunk"
		:style="styles"
	>
		<div slot="suffix" class="notices-suffix">
			<el-button slot="suffix" class="notices-more" type="text" @click="handleMore">更多</el-button>
		</div>
		<ul class="notices-list">
			<li
				class="notices-list-item"
				v-for="(item, index) in list"
				:key="item.title + index"
				:title="item.title"
				@click="handleClick(item)"
			>
				<i class="notices-list-item-icon es-icon-wenjian"></i>
				{{ item.title }}
				<span class="notices-list-item-date">{{ item.createTime.split(' ')[0] }}</span>
				<span class="notices-list-item-other">详情</span>
			</li>
		</ul>
		<es-dialog size="lg" title="通知公告" :visible.sync="more">
			<es-data-table
				v-if="more"
				numbers
				page
				method="post"
				url="/portal/baseWork/listJson"
				:toolbar="toolbar"
				:thead="thead"
			></es-data-table>
		</es-dialog>
		<es-dialog size="lg" title="详情" :visible.sync="visible" @close="handleClose">
			<iframe v-if="url" frameborder="0" width="100%" height="100%" :src="url"></iframe>
			<el-scrollbar class="page-scroll" v-if="content">
				<h1 class="notices-title">{{ title }}</h1>
				<div class="notices-time-source">
					<span class="notices-time">时间：{{ time }}</span>
					<span class="notices-source">来源：{{ source }}</span>
				</div>
				<div class="notices-content" v-html="content"></div>
			</el-scrollbar>
		</es-dialog>
	</es-card>
</template>
<script>
export default {
	name: 'Notices',
	props: {
		moreUrl: String,
		detailUrl: String,
		height: {
			type: [String, Number],
			default: '374'
		},
		header: {
			type: String,
			default: '通知公告'
		},
		shadow: {
			type: String,
			default: 'never'
		},
		pageNumber: {
			type: Number,
			default: 7
		}
	},
	watch: {
		active(val) {
			this.getData(val);
		}
	},
	computed: {
		styles() {
			return { height: parseInt(this.height) + 'px' };
		}
	},
	data() {
		return {
			type: [],
			active: 0,
			show: false,
			boxWidth: '',
			width: '',
			left: 0,
			list: [],
			more: false,
			visible: false,
			url: '',
			title: '',
			time: '',
			source: '',
			content: '',
			toolbar: [
				{
					type: 'search',
					reset: true,
					contents: [
						{
							type: 'text',
							label: '关键字',
							name: 'keyword',
							placeholder: '请输入关键字'
						}
					]
				}
			],
			thead: [
				{
					title: '标题',
					field: 'title',
					align: 'left',
					showOverflowTooltip: true,
					minWidth: '160'
				},
				{
					title: '录入人',
					align: 'center',
					field: 'createUserName',
					width: '140'
				},
				{
					title: '发布时间',
					align: 'center',
					field: 'publishTime',
					width: '160'
				},
				{
					title: '录入时间',
					align: 'center',
					field: 'createTime',
					width: '160'
				},
				{
					title: '操作',
					type: 'handle',
					width: '100',
					template: '',
					events: [
						{
							text: '查看',
							type: 'text',
							event: res => {
								this.handleClick(res.row);
							}
						}
					]
				}
			]
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.$.ajax({
				method: 'post',
				url: '/portal/baseWork/listJson',
				data: {
					pageSize: this.pageNumber,
					pageNum: 1
				}
			})
				.then(res => {
					if (res.rCode == 0 || res.status == 'success') {
						let { results } = res;
						this.list = results.records.slice(0, 7);
						console.log(this.list, 9999);
					}
				})
				.catch(err => {});
		},
		handleMore() {
			this.more = true;
		},
		handleChangeType(res) {
			this.active = res.value;
			this.visible = true;
		},
		handleNext() {
			if (this.left < 0) {
				this.left += 120;
			}
		},
		handlePrev() {
			if (this.boxWidth + Math.abs(this.left) < this.width) {
				this.left -= 120;
			}
		},
		handleClick(res) {
			if (this.detailUrl || res.linkUrl || res.detailUrl) {
				let name = ['announcement', 'notice'];
				let url = this.$.urlJoinParams({
					url: this.detailUrl || res.linkUrl || res.detailUrl,
					param: { id: res.id, name: name[res.noticeType] || 'notice' }
				});
				this.url = res.linkUrl;
				this.visible = true;
			} else if (res.content) {
				this.title = res.title;
				this.time = res.publishTime || res.createTime;
				this.source = res.source;
				this.content = res.content;
				this.visible = true;
			}
		},
		handleClose() {
			this.url = '';
			this.title = '';
			this.time = '';
			this.source = '';
			this.content = '';
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep {
	.el-button--mini {
		padding: 4px 8px;
	}
	.el-card__body {
		padding-top: 8px;
	}
}
.notices-suffix {
	flex: 1;
	text-align: right;
}
.notices-list-header {
	position: relative;
	height: 30px;
	.notices-tabs {
		background-color: #ecf0fa;
		border-radius: 20px;
		position: relative;
		&.show-handle {
			padding-right: 48px;
		}
		.calendar_tabs {
			overflow: hidden;
			.calendar_tabs-content {
				display: flex;
				position: relative;
				.calendar_tabs-list {
					width: 120px;
					height: 30px;
					line-height: 30px;
					cursor: pointer;
					text-align: center;
					&.is-select {
						border-radius: 20px;
						background-color: $--color-primary;
						color: #fff;
					}
				}
			}
		}
		.notices-tpye-handle {
			position: absolute;
			right: 0;
			top: 0;
			line-height: 30px;
			padding-right: 8px;
			.notices-tpye-prev {
				margin-right: 4px;
				cursor: pointer;
			}
			.notices-tpye-next {
				cursor: pointer;
			}
		}
	}
	.notices-list-type {
		position: absolute;
		right: 0;
		top: 0;
	}
}
.notices-list {
	.notices-list-item {
		padding: 0 120px 0 20px;
		line-height: 40px;
		cursor: pointer;
		position: relative;
		border-bottom: 1px solid #dfebf6;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		.es-icon-wenjian {
			font-size: 16px;
			color: $--color-primary;
			position: absolute;
			left: 0;
		}
		.notices-list-item-date {
			font-size: 13px;
			position: absolute;
			right: 40px;
		}
		.notices-list-item-other {
			font-size: 14px;
			color: $--color-primary;
			position: absolute;
			right: 0;
		}
	}
}
.notices-title {
	text-align: center;
	font-weight: bold;
	font-size: 2em;
}
.notices-time-source {
	text-align: center;
	font-size: 18px;
	.notices-source {
		margin-left: 40px;
	}
}
.notices-content {
	padding: 20px;
	font-size: 18px;
	::v-deep span,
	::v-deep p {
		font-family: '宋体';
	}
}
</style>
