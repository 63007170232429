export default [
	{
		path: '/operateShow/mainBusinessSmall',
		title: '主要经营指标-小',
		name: 'mainBusinessSmall',
		components: {
			default: resolve => require(['@/views/operateAnalysisShow/mainBusinessSmall.vue'], resolve),
			mainBusinessSmall: resolve =>
				require(['@/views/operateAnalysisShow/mainBusinessSmall.vue'], resolve)
		}
	},
	{
		path: '/operateShow/mainBusinessIndicators',
		title: '主要经营指标',
		name: 'mainBusinessIndicators',
		components: {
			default: resolve => require(['@/views/operateAnalysisShow/mainBusinessIndicators.vue'], resolve),
			mainBusinessIndicators: resolve => require(['@/views/operateAnalysisShow/mainBusinessIndicators.vue'], resolve)
		}
	},
	{
		path: '/operateShow/dataSheet',
		title: '经营数据表',
		name: 'dataSheet',
		components: {
			default: resolve => require(['@/views/operateAnalysisShow/dataSheet.vue'], resolve),
			dataSheet: resolve => require(['@/views/operateAnalysisShow/dataSheet.vue'], resolve)
		}
	},
	{
		path: '/operateShow/budgetPerformance',
		title: '预算完成情况',
		name: 'budgetPerformance',
		components: {
			default: resolve => require(['@/views/operateAnalysisShow/budgetPerformance.vue'], resolve),
			budgetPerformance: resolve => require(['@/views/operateAnalysisShow/budgetPerformance.vue'], resolve)
		}
	},
	{
		path: '/operateAnalysis/profitChanges',
		title: '利润变动情况',
		name: 'profitChanges',
		components: {
			default: resolve => require(['@/views/operateAnalysisShow/profitChanges.vue'], resolve),
			profitChanges: resolve => require(['@/views/operateAnalysisShow/profitChanges.vue'], resolve)
		}
	},
	{
		path: '/operateAnalysis/operatePerformance',
		title: '预算完成情况',
		name: 'operatePerformance',
		components: {
			default: resolve => require(['@/views/operateAnalysisShow/operatePerformance.vue'], resolve),
			operatePerformance: resolve => require(['@/views/operateAnalysisShow/operatePerformance.vue'], resolve)
		}
	},
	{
		path: '/operateAnalysis/profitContribute',
		title: '利润贡献情况',
		name: 'profitContribute',
		components: {
			default: resolve => require(['@/views/operateAnalysisShow/profitContribute.vue'], resolve),
			profitContribute: resolve => require(['@/views/operateAnalysisShow/profitContribute.vue'], resolve)
		}
	}
];
