<template>
	<div class="moreSys" :class="{ close: close }" @click="onClose" :style="{ height: pH + 'px' }">
		<div class="moreSys-panel" @click.stop :style="{ height: `${height}px` }">
			<div class="left">
				<el-scrollbar class="es-scrollbar">
					<div
						class="moreSys-menu-item"
						v-for="(item, index) in list"
						:key="item.id"
						:class="{ actived: index == actived }"
						@click="actived = index"
					>
						<div class="icon" />
						{{ item.name }}
					</div>
				</el-scrollbar>
			</div>
			<div class="right">
				<div class="right-head">
					<div class="icon" />
					{{ list[actived]?.name || '--' }}
				</div>
				<el-scrollbar class="es-scrollbar">
					<div class="right-content" v-if="actived > -1">
						<div
							class="right-item"
							:class="{ disabled: !item.permission }"
							v-for="item in list[actived].children || []"
							:key="item.id"
							@click="onClick(item)"
						>
							{{ item.name }}
						</div>
					</div>
				</el-scrollbar>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		list: {
			type: Array,
			default: () => {
				return [];
			}
		},
		pH: {
			default: 0
		}
	},
	data() {
		return {
			height: 0,
			actived: 0,
			close: false
		};
	},
	created() {
		// this.list.some((e,i)=>{
		// 	if(e.permission){
		// 		this.actived=i
		// 	}
		// 	return e.permission
		// })
	},
	mounted() {
		this.$nextTick(() => {
			this.height = 430;
		});
	},
	methods: {
		onClose() {
			this.close = true;
			this.height = 0;
			//不急，等动画结束后再关闭,反正动画结束时dom高度也为0了
			setTimeout(() => {
				this.$emit('onClose');
			}, 1250);
		},
		onClick(item) {
			if (!item.permission) return;
			try {
				if (item.openModel == 1 && item.url) {
					window.open(item.url, '_blank');
				} else if (item.code) {
					window.open(`./main.html#/?appCode=${item.code}`, '_blank');
				} else if (item.url) {
					window.open(item.url, '_blank');
				} else {
					throw error;
				}
			} catch {
				this.$message.error('系统地址异常：' + item.url);
			}
		}
		// leftClick(item,index){
		// 	if(!item.permission) return
		// 	this.actived = index
		// }
	}
};
</script>

<style lang=scss scoped>
@keyframes opacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-webkit-keyframes opacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-moz-keyframes opacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.moreSys {
	width: 100%;
	position: absolute;
	left: 0;
	top: 100%;
	z-index: 4;
	background: rgba($color: #000, $alpha: 0.6);
	&.close {
		animation: 1s opacity 0.3s;
		-webkit-animation: 1s opacity 0.3s;
		-moz-animation: 1s opacity 0.3s;
	}
	.moreSys-panel {
		transition: height linear 0.3s;
		display: inline-block;
		overflow: hidden;
		width: 100%;
		background: url('@/assets/image/home/bg_more.png') no-repeat;
		background-size: 100% 100%;
		display: flex;
		justify-content: center;
		background-color: #fff;
		.left {
			height: 350px;
			position: relative;
			top: 56px;
			width: 336px;
			.moreSys-menu-item {
				width: 280px;
				height: 58px;
				display: flex;
				padding-left: 32px;
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 18px;
				align-items: center;
				cursor: pointer;
				color: #15224c;
				.icon {
					background: url('@/assets/image/home/icon_menu_normal.png') no-repeat;
					background-size: 100% 100%;
					width: 18px;
					height: 18px;
					margin-right: 24px;
				}
				&.actived {
					color: #fff;
					background: #2591f7;
					box-shadow: 0px 8px 10px 0px rgba(37, 145, 247, 0.21);
					border-radius: 29px;
					.icon {
						background: url('@/assets/image/home/icon_menu_selected.png') no-repeat;
						background-size: 100% 100%;
					}
				}
				&.disabled {
					/* 4、设置图像灰度 */
					-webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
					filter: grayscale(100%);
					cursor: not-allowed;
					.icon {
					}
				}
			}
		}
		.right {
			height: 350px;
			width: 864px;
			background: #ffffff;
			position: relative;
			top: 56px;
			box-shadow: 0px 40px 59px 0px rgba(4, 80, 184, 0.13);
			border-radius: 6px;
			.right-head {
				display: flex;
				align-items: center;
				padding-left: 40px;
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 20px;
				height: 70px;
				color: #2591f7;
				background: #f5faff;
				.icon {
					background: url('@/assets/image/home/icon_menu_normal.png') no-repeat;
					background-size: 100% 100%;
					width: 22px;
					height: 22px;
					margin-right: 20px;
				}
			}
			.es-scrollbar {
				height: calc(100% - 70px);
				.right-content {
					width: 100%;
					padding: 28px 0 52px;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					.right-item {
						height: 47px;
						width: 33.33%;
						padding-left: 40px;
						flex-shrink: 0;
						display: flex;
						align-items: center;
						font-family: Alibaba PuHuiTi 2;
						font-weight: normal;
						font-size: 18px;
						color: #737a94;
						border-right: 1px solid rgba($color: #dfebf6, $alpha: 0.7);
						cursor: pointer;
						&:hover {
							color: #2591f7;
						}
						&:nth-child(3n) {
							border: none;
						}
						&.disabled {
							color: #737a94;
							cursor: not-allowed;
						}
					}
				}
			}
		}
	}
}
</style>