import Vue from 'vue';
import VueRouter from 'vue-router';
import $ from 'eoss-ui/lib/utils/util';
import request from 'eoss-ui/lib/utils/http';
import children from './children.js';

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

let routes = [
	{
		path: '/',
		redirect: '/portalRedirect'
	},
	...children
];
const router = new VueRouter({
	//mode: 'history', // 去掉链接中的#
	routes
});
router.beforeEach((to, from, next) => {
	$.isLogined({
		to,
		from,
		//exclude: true,
		exclude:[], // 不拦截的路由
		next,
		request,
		redirect: true
	});
});

export default router;
