<!--
* @desc: 考核完成情况
* @Author: YanZhongYing
* @Date: 2024-06-05
-->
<template>
	<div class="box">
		<div class="box-date flex-end">
			<es-button style="margin-right: 16px" @click="exportExcel">导出</es-button>
			<es-date-picker v-model="date" type="month"></es-date-picker>
		</div>
		<div class="box-c box-flex">
			<template v-for="item of info">
				<div :key="item.title" class="box-c-item flex-item">
					<!-- 头部 -->
					<Header :title="item.title" />
					<!-- card -->
					<div class="card flex-sb">
						<template v-for="i of item.data">
							<div v-if="i.sort < 4 && i.type != '控亏企业'" :key="i.sort" class="card-item">
								<div class="card-item-box">
									<div :class="['card-item-box-c', 'card-item-box-c' + i.sort]">
										<img
											class="rank"
											:src="require(`@/assets/image/operateAnalysisShow/rank${i.sort}.png`)"
											alt=""
											srcset=""
										/>
										<div class="card-content">
											<div class="card-content-value">{{ i.currentLoss }}</div>
											<div class="card-content-name text_ellipsis">
												{{ i.companyName }}
											</div>
											<div class="card-content-line"></div>
											<div class="flex-sb">
												<span>完成考核进度</span>
												<span>{{ i.process }}%</span>
											</div>
											<div class="card-content-bottom flex-sb">
												<span>完成考核情况</span>
												<span>{{ i.condition }}</span>
											</div>
											<div v-if="item.title.includes('利润总额')" class="flex-sb">
												<span>企业类型</span>
												<span>{{ i.type }}</span>
											</div>
										</div>
									</div>
								</div>
								<div :class="['card-item-bg', 'card-item-bg' + i.sort]"></div>
							</div>
						</template>
					</div>
					<!-- 表格 -->
					<div ref="tables" class="table">
						<el-scrollbar class="es-scrollbar" slot="contents">
							<template v-for="(i, index) of item.data">
								<div
									:key="index"
									v-if="(i.sort > 3 && !i.kk) || i.type == '控亏企业'"
									class="table-tr flex-c"
								>
									<div class="table-td-sort">
										{{ i.sort < 10 ? '0' + i.sort : i.sort }}
									</div>
									<div class="flex-item">
										<div>{{ i.currentLoss }}</div>
										<div class="table-td-text text_ellipsis">
											{{ i.companyName }}
										</div>
									</div>
									<div style="width: 200px; margin: 0 10px">
										<div>{{ i.process }}%</div>
										<div class="table-td-text">完成考核进度</div>
									</div>
									<div style="width: 200px">
										<div>{{ i.condition }}</div>
										<div class="table-td-text">完成考核情况</div>
									</div>
									<div v-if="i.type" style="width: 90px">
										<div
											:style="{
												color: i.type.includes('盈利' ? '#333' : '#999')
											}"
										>
											{{ i.type }}
										</div>
										<div class="table-td-text">企业类型</div>
									</div>
								</div>
								<div :key="index" v-if="i.kk" class="table-tr-kk">控亏企业</div>
							</template>
						</el-scrollbar>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import Mixins from './mixins/mixins';
import Header from './components/Header.vue';
import { operateShow, common } from '@/http/operateAnalysisShow';
export default {
	components: {
		Header
	},
	mixins: [Mixins],
	data() {
		return {
			info: [
				{
					data: [],
					title: '营业收入排名'
				},
				{ data: [], title: '利润总额排名' }
			]
		};
	},
	watch: {
		date() {
			this.getData();
		}
	},
	mounted() {
		// this.getData();
	},
	methods: {
		/**
		 * @desc: 获取数据
		 **/
		getData() {
			const loading = this.$.loading(this.$loading, '加载中');
			let params = { dateString: this.date };
			this.$.ajax({
				url: operateShow.rankingIncome,
				params
			})
				.then(res => {
					loading.close();
					const { rCode, results } = res;
					if (rCode == 0) {
						let item = results.splice(1, 1)[0]; // 从数组中移除该项并获得它
						results.unshift(item); // 将该项插入到数组的开头
						this.info[0].data = results;
					}
				})
				.catch(error => {
					loading.close();
				});

			this.$.ajax({
				url: operateShow.rankingOfTotalProfitsTwo,
				params
			})
				.then(res => {
					loading.close();
					const { rCode, results } = res;
					if (rCode == 0) {
						if (results.yl.length > 2) {
							let item = results.yl.splice(1, 1)[0];
							results.yl.unshift(item);
						}

						this.info[1].data = [...results.yl, { kk: true }, ...results.kk];
					}
				})
				.catch(error => {
					loading.close();
				});
		},
		/**
		 * @desc: 导出
		 **/
		exportExcel() {
			let date = this.date.split('-');
			window.open(
				this.$.urlJoinParams({
					url: this.$.getStorage('host') + common.exportExcel,
					param: {
						excelCode: 'dataConditionExcelServiceImpl',
						fileName: date[0] + '年' + date[1] + '月考核完成情况',
						dateString: date[0] + date[1]
					}
				})
			);
		}
	}
};
</script>

<style lang="scss" scoped>
.box {
	width: 100%;
	height: 100%;
	background: #f6f6f6;
	font-size: 16px;
	overflow: auto;
	&-date {
		width: 100%;
		padding: 6px 10px;
		background: #fff;
		border: 1px solid #eee;

		.es-date-picker {
			width: 200px;
		}
	}
	&-c {
		height: calc(100% - 54px);
		padding: 16px;
		&-item {
			height: 100%;
			background: #fff;
			&:first-child {
				margin-right: 16px;
			}
		}
		// 前三名
		.card {
			padding: 16px 16px 0px 16px;
			&-item {
				width: calc(100% / 3 - 10px);

				&-box {
					height: 290px;
					position: relative;
					// border:1px solid red;
					&-c {
						position: absolute;
						bottom: 0;
						width: 100%;
						height: 250px;
						border-radius: 8px;
						.rank {
							position: absolute;
							top: -20px;
							width: 55px;
							left: 50%;
							transform: translateX(-50%);
						}
						.card-content {
							position: absolute;
							bottom: 0;
							height: 220px;
							width: 100%;
							padding: 0 24px;
							text-align: center;
							color: #fff;
							&-value {
								font-size: 38px;
								font-weight: 600;
								background: linear-gradient(to bottom, #fff, #f5ac6a9e);
								-webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/
								-webkit-text-fill-color: transparent; /*给文字设置成透明*/
							}
							&-name {
								opacity: 0.5;
								height: 45px;
								-webkit-line-clamp: 2;
							}
							&-line {
								height: 1px;
								background: #ffffff;
								opacity: 0.2;
								margin: 6px 0 16px;
							}
							&-bottom {
								margin: 10px 0;
							}
						}
					}
					&-c1 {
						height: 276px;
						background: linear-gradient(0deg, #e69e7f 0%, #e16e6a 98%);
						.card-content-value {
							background: linear-gradient(to bottom, #fff, #e16e6a80);
							-webkit-background-clip: text;
						}
					}
					&-c2 {
						background: linear-gradient(0deg, #f5ac6a 0%, #f4943f 99%);
					}
					&-c3 {
						background: linear-gradient(0deg, #5a99e3 0%, #82afe4 100%);
						.card-content-value {
							background: linear-gradient(to bottom, #fff, #5a99e380);
							-webkit-background-clip: text;
						}
					}
				}
				&-bg {
					margin-top: 6px;
					height: 44px;
					background: linear-gradient(0deg, #5a99e3 0%, #82afe4 100%);
					border-radius: 8px;
					opacity: 0.18;
				}
				&-bg1 {
					background: linear-gradient(
						0deg,
						rgba(230, 158, 127, 0) 0%,
						rgba(225, 110, 106, 0.98) 98%
					);
				}
				&-bg2 {
					background: linear-gradient(0deg, #f5ac6a 0%, #f4943f 99%);
				}
			}
		}
		// 表格
		.table {
			margin-top: 8px;
			padding: 0 16px;
			height: 310px;
			&-tr {
				height: 70px;
				background: #f7f7f7;
				border-radius: 8px;
				&:nth-child(2n) {
					background: #fdfdfc;
				}
				padding: 0 24px;
			}

			&-td-sort {
				width: 90px;
				color: #4e7aa9;
			}
			&-td-text {
				font-size: 16px;
				color: #999999;
			}
			&-tr-kk {
				height: 40px;
				line-height: 40px;
				text-align: center;
			}
		}
	}
}
</style>
@/http/operateAnalysisShow
