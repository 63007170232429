<!-- 督办、催办 -->
<template>
	<es-form
		v-loading="loading"
		element-loading-text="获取数据中..."
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.65)"
		:hides="hides"
		:model="formData"
		:contents="contents"
		@submit="handleSubmit"
	></es-form>
</template>

<script>
export default {
	name: 'ShareRead',
	components: {},
	props: {
		rowData: Object,
		notify: String,
		notifyList: Array,
		typeName: String
	},
	data() {
		return {
			loading: true,
			formData: {},
			hides: [],
			contents: [
				{
					name: 'title',
					label: '标题',
					type: 'text',
					readonly: true
				},
				{
					name: 'taskName',
					label: '当前办理节点',
					type: 'textarea',
					readonly: true
				},
				{
					name: 'taskUserName',
					label: '当前办理人',
					type: 'text',
					readonly: true
				},
				{
					name: 'method',
					label: '通知方式',
					type: 'checkbox',
					data: this.notifyList,
					placeholder: '请选择通知方式',
					rules: {
						required: true,
						message: '请选择通知方式',
						trigger: 'change'
					}
				},
				{
					name: 'remark',
					label: '通知消息',
					type: 'textarea',
					placeholder: '请输入通知消息',
					rules: {
						required: true,
						message: '请输入通知消息',
						trigger: 'change'
					}
				}
			],
			taskExamine: {}
		};
	},
	computed: {},
	watch: {
		rowData: {
			immediate: true,
			deep: true,
			handler(val) {
				//if(val)
			}
		},
		notify: {
			immediate: true,
			handler(val) {
				this.$set(this.formData, 'method', val ? val.split(',') : []);
			}
		}
	},
	created() {
		this.getMsg();
	},
	mounted() {},
	methods: {
		getMsg() {
			let arcType = this.rowData.bcode == 'fawen' ? 0 : 1;
			this.$.ajax({
				url: '/archives/archives/common/superviseData.json',
				oldmode: true,
				params: {
					//id: this.rowData.apprecordid,
					id: 'DIS87ca4aaf6cb14792be93426218116e0f',
					arcType: arcType
				}
			})
				.then(res => {
					this.loading = false;
					if (res.rCode == 0) {
						let { results } = res;
						for (let i in results) {
							let val = results[i];
							if (val !== '' && val != null) {
								this.$set(
									this.formData,
									i,
									i == 'method' && typeof val == 'string' ? val.split(',') : val
								);
							}
						}
						console.log(this.formData);
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch(err => {
					this.loading = false;
					if (err.message && err.message !== 'canceled') {
						this.$message.error(err.message);
					}
				});
		},
		handleSubmit() {
			let { method, ...value } = this.formData;
			let data = {
				'bean.method': method.join(',')
			};
			for (let i in value) {
				data[`bean.${i}`] = value[i];
			}
			let title = this.rowData.pendtitle || this.formData.title;
			let typeName = this.rowData.taskReadTypeName || this.typeName;
			let loading = this.$.loading('', `${typeName}中，请稍等！`);
			this.$.ajax({
				url: '/archives/archives/common/supervise.dhtml',
				olmode: true,
				method: 'post',
				data: data
			})
				.then(res => {
					if (res.rCode == 0 || res.status == 'success') {
						this.$message({
							type: 'success',
							duration: 2000,
							message: `《${title}》${typeName}成功！`,
							onClose: () => {
								loading.close();
								//this.$emit('success');
							}
						});
					} else {
						loading.close();
						let msg = res.msg || res.message || '系统错误，请联系管理员！';
						this.$message.error(msg);
					}
				})
				.catch(err => {
					loading.close();
					if (err.message && err.message !== 'canceled') {
						this.$message.error(err.message);
					}
				});
		}
	}
};
</script>

<style lang="scss" scoped></style>
