<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
export default {
	data() {
		return {
			show: false,
			color: ''
		};
	},
	watch: {
		color(val) {
			if (val) {
				this.$.updateTheme(val);
				localStorage.setItem('theme', val);
			}
		}
	},
	created() {
		const color = this.$.getStorage('theme');
		color && this.$.updateTheme(color);
		let mainConfig = this.$.getStorage('mainConfig');
		if (!mainConfig) {
			this.$.getMainConfig(this.setConfig);
		} else {
			this.setConfig(JSON.parse(mainConfig));
		}
		window.addEventListener('message', this.setTheme);
	},
	mounted() {
		window.addEventListener('hashchange', this.jump, false);
	},
	methods: {
		setConfig(results) {
			sessionStorage.setItem('mainConfig', JSON.stringify(results));
			this.$.setStorage({
				type: this.storage,
				key: {
					userId: results.userModel.userId,
					userName: results.userModel.username,
					useCaseCodes: results.resourceCodes || results.userModel.resourceCodes
				}
			});
			if (results.subsystemExtend && results.subsystemExtend.themeColor) {
				this.color = unescape(results.subsystemExtend.themeColor).toLowerCase();
			}
			if (results.userStyle && results.userStyle.themeColor) {
				this.color = unescape(results.userStyle.themeColor).toLowerCase();
			}
			this.show = true;
		},
		setTheme(res) {
			if (res.data.themeColor) {
				this.color = res.data.themeColor;
			}
		},
		jump() {
			if (!!window.ActiveXObject || 'ActiveXObject' in window) {
				setTimeout(() => {
					let currentPath = window.location.hash.slice(1);
					if (this.$route.path !== currentPath) {
						this.$router.push(currentPath); // 主动更改路由界面
					}
				}, 500);
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('message', this.setTheme);
		window.removeEventListener('hashchange', this.jump);
	}
};
</script>
<style lang="scss" scoped>
#app {
	height: 100%;
	overflow: hidden;
	position: relative;
}
</style>
