<!--
* @desc: 模块头部
* @Author: YanZhongYing
* @Date: 2024-06-07
-->
<template>
  <div class="info-title">
    <div class="icon" />
    {{ title }}
    <span class="remark" v-if="remark">({{ remark }})</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    remark: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.info-title {
  line-height: 48px;
  width: 100%;
  height: 48px;
  padding-left: 16px;
  background: #fff;
  color: #333;
  font-size: 18px;
  border-bottom: 1px solid #eee;
  .icon {
    display: inline-block;
    width: 4px;
    height: 18px;
    position: relative;
    top: 2px;
    margin-right: 8px;
    border-radius: 2px;
    background: #2e7bad;
  }
  .remark {
    margin-left: 4px;
    font-size: 16px;
    color: #999999;
  }
}
</style>
