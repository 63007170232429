export default [
	{
		path: '/operateShowXc/mainBusinessSmall',
		title: '主要经营指标-小',
		name: 'mainBusinessSmallXc',
		components: {
			default: resolve => require(['@/views/operateAnalysisShowXc/mainBusinessSmall.vue'], resolve),
			mainBusinessSmallXc: resolve =>
				require(['@/views/operateAnalysisShowXc/mainBusinessSmall.vue'], resolve)
		}
	},
	{
		path: '/operateShowXc/mainBusinessIndicators',
		title: '主要经营指标',
		name: 'mainBusinessIndicatorsXc',
		components: {
			default: resolve => require(['@/views/operateAnalysisShowXc/mainBusinessIndicators.vue'], resolve),
			mainBusinessIndicatorsXc: resolve => require(['@/views/operateAnalysisShowXc/mainBusinessIndicators.vue'], resolve)
		}
	},
	{
		path: '/operateShowXc/dataSheet',
		title: '经营数据表',
		name: 'dataSheetXc',
		components: {
			default: resolve => require(['@/views/operateAnalysisShowXc/dataSheet.vue'], resolve),
			dataSheetXc: resolve => require(['@/views/operateAnalysisShowXc/dataSheet.vue'], resolve)
		}
	},
	{
		path: '/operateShowXc/budgetPerformance',
		title: '预算完成情况',
		name: 'budgetPerformanceXc',
		components: {
			default: resolve => require(['@/views/operateAnalysisShowXc/budgetPerformance.vue'], resolve),
			budgetPerformanceXc: resolve => require(['@/views/operateAnalysisShowXc/budgetPerformance.vue'], resolve)
		}
	},
	{
		path: '/operateAnalysisXc/profitChanges',
		title: '利润变动情况',
		name: 'profitChangesXc',
		components: {
			default: resolve => require(['@/views/operateAnalysisShowXc/profitChanges.vue'], resolve),
			profitChangesXc: resolve => require(['@/views/operateAnalysisShowXc/profitChanges.vue'], resolve)
		}
	},
	{
		path: '/operateAnalysisXc/operatePerformance',
		title: '预算完成情况',
		name: 'operatePerformanceXc',
		components: {
			default: resolve => require(['@/views/operateAnalysisShowXc/operatePerformance.vue'], resolve),
			operatePerformanceXc: resolve => require(['@/views/operateAnalysisShowXc/operatePerformance.vue'], resolve)
		}
	},
	{
		path: '/operateAnalysisXc/profitContribute',
		title: '利润贡献情况',
		name: 'profitContributeXc',
		components: {
			default: resolve => require(['@/views/operateAnalysisShowXc/profitContribute.vue'], resolve),
			profitContributeXc: resolve => require(['@/views/operateAnalysisShowXc/profitContribute.vue'], resolve)
		}
	}
];
