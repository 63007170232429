<template>
	<div class="group" @click="onClose">
		<div class="group-panel" @click.stop :style="{ width: `${width}px` }">
			<el-scrollbar class="es-scrollbar">
				<div class="group-main">
					<div class="left">
						<div class="icon" />
						集团官网
					</div>
					<div class="right" @click="reload">
						进入
						<i class="es-icon-pointer-right" />
					</div>
				</div>
				<div class="group-item">
					<div class="item-head">
						<img class="icon" src="@/assets/image/home/icon_nengyuan.png" alt="" />
						能源产业
					</div>

					<div class="item-content">
						<div
							class="item-content-item"
							v-for="item in nycyList"
							:key="item.url"
							@click="onClick(item.url)"
						>
							{{ item.label }}
						</div>
					</div>
				</div>
				<div class="group-item">
					<div class="item-head">
						<img class="icon" src="@/assets/image/home/icon_dianzi.png" alt="" />
						基金金融产业
					</div>

					<div class="item-content">
						<div
							class="item-content-item"
							v-for="item in jrjjList"
							:key="item.url"
							@click="onClick(item.url)"
						>
							{{ item.label }}
						</div>
					</div>
				</div>
				<div class="group-item">
					<div class="item-head">
						<img class="icon" src="@/assets/image/home/icon_dajiankang.png" alt="" />
						大健康产业
					</div>
					<div class="item-content">
						<div
							class="item-content-item"
							v-for="item in djkcyList"
							:key="item.url"
							@click="onClick(item.url)"
						>
							{{ item.label }}
						</div>
					</div>
				</div>
				<div class="group-item">
					<div class="item-head">
						<img class="icon" src="@/assets/image/home/icon_cailiao.png" alt="" />
						新兴产业
					</div>
					<div class="item-content">
						<div
							class="item-content-item"
							v-for="item in xxcyList"
							:key="item.url"
							@click="onClick(item.url)"
						>
							{{ item.label }}
						</div>
					</div>
				</div>
			</el-scrollbar>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			width: 0,
			nycyList: [
				{ label: '川投能源', url: '/portal/visit_index.dhtml?portalCode=ctny' },
				{ label: '川投田湾河', url: '/portal/visit_index.dhtml?portalCode=cttwh' },
				{ label: '川南发电', url: '/portal/visit_index.dhtml?portalCode=cnfd' },
				{ label: '川投售电', url: '/portal/visit_index.dhtml?portalCode=ctsd' },
				{ label: '川投燃电', url: '/portal/visit_index.dhtml?portalCode=rdjt' },
				{ label: '达州气电', url: '/portal/visit_index.dhtml?portalCode=rqfd' },
				{ label: '资阳气电', url: '/portal/visit_index.dhtml?portalCode=zyqd' },
				{ label: '泸州气电', url: '/portal/visit_index.dhtml?portalCode=lzqd' },
				{ label: '川投新能源', url: '/portal/visit_index.dhtml?portalCode=ctxny' }
			],
			jrjjList: [
				{ label: '川投置信', url: '/portal/visit_index.dhtml?portalCode=zxfdc' },
				{ label: '兴川建设', url: '/portal/visit_index.dhtml?portalCode=ctxc' },
				{ label: '房地产公司', url: '/portal/visit_index.dhtml?portalCode=estate' },
				{ label: '川投汇鑫', url: '/portal/visit_index.dhtml?portalCode=cthx' }
			],
			djkcyList: [
				{ label: '川投国网', url: '/portal/visit_index.dhtml?portalCode=ctgw' },
				{ label: '嘉阳集团', url: '/portal/visit_index.dhtml?portalCode=jiayang' },
				{ label: '佳友物业', url: '/portal/visit_index.dhtml?portalCode=jiayou' },
				{ label: '华西牙科', url: '/portal/visit_index.dhtml?portalCode=hxyk' },
				{ label: '川投凉山', url: '/portal/visit_index.dhtml?portalCode=ctls' },
				{ label: '川投大健康', url: '/portal/visit_index.dhtml?portalCode=chuantoudjk' },
				{ label: '川投峨旅', url: '/portal/visit_index.dhtml?portalCode=ctel' },
				{ label: '西昌大健康', url: '/portal/visit_index.dhtml?portalCode=ctdjk' },
				{ label: '田湾河旅游', url: '/portal/visit_index.dhtml?portalCode=twhly' }
			],
			xxcyList: [
				{ label: '川投峨铁', url: '/portal/visit_index.dhtml?portalCode=ctet' },
				{ label: '川投信产', url: '/portal/main/xinchan/first.dhtml' }
			]
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.width = 648;
		});
	},
	methods: {
		onClose() {
			this.$emit('onClose');
		},
		onClick(url) {
			window.open(url, '_blank');
		},
		reload() {
			window.location.reload()
		}
	}
};
</script>

<style lang="scss" scoped>
.group {
	width: 100%;
	height: calc(100% - 80px);
	position: absolute;
	left: 0;
	top: 80px;
	z-index: 8;
	background: rgba($color: #000, $alpha: 0.6);
	text-align: right;
	.group-panel {
		transition: width linear 0.5s;
		display: inline-block;
		height: 100%;
		background: #f4f6fd;
		.el-scrollbar__wrap {
			background: #f4f6fd;
			.group-item {
				width: calc(100% - 48px);
				background: #ffffff;
				border-radius: 6px;
				margin: 24px auto 0;
				&:last-child {
					margin-bottom: 24px;
				}
				.item-head {
					width: 100%;
					height: 74px;
					display: flex;
					padding-left: 32px;
					align-items: center;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 18px;
					color: #15224c;
					border-bottom: 1px solid rgba($color: #defbf6, $alpha: 0.7);
					.icon {
						width: 30px;
						height: 30px;
						margin-right: 20px;
						display: inline-block;
					}
				}
				.item-content {
					width: 100%;
					padding: 20px 80px;
					display: flex;
					flex-wrap: wrap;
					.item-content-item {
						font-family: Alibaba PuHuiTi 2;
						width: 50%;
						text-align: left;
						cursor: pointer;
						font-weight: normal;
						font-size: 18px;
						color: #737a94;
						margin: 20px 0;
						&:hover {
							color: #2591f7;
						}
					}
				}
			}
			.group-main {
				width: calc(100% - 48px);
				height: 72px;
				background: #ffffff;
				border-radius: 6px;
				padding: 0 24px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 24px auto 0;
				.left {
					display: flex;
					align-items: center;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 18px;
					color: #15224c;
					.icon {
						width: 75px;
						height: 24px;
						background: url('@/assets/image/home/img_scig.png') no-repeat;
						background-size: 100% 100%;
						margin-right: 20px;
					}
				}
				.right {
					font-family: Alibaba PuHuiTi 2;
					cursor: pointer;
					font-weight: normal;
					font-size: 16px;
					color: #2591f7;
					.es-icon-pointer-right {
						margin-left: 10px;
					}
				}
			}
		}
	}
}
</style>
