<template>
	<div class="home-bar">
		<div :id="id" class="bar"></div>
		<div class="page-list" v-if="intervalNum > 0">
			<div class="left" @click="onChangePage('-')" :class="{ disabled: intervalPageNum == 1 }">
				<i class="es-icon-pointer-left"></i>
			</div>
			<div class="dot-list">
				<div
					class="dot-item"
					:class="{ actived: intervalPageNum == item }"
					v-for="item in intervalNum"
					:key="item"
					@click="onDotClick(item)"
				/>
			</div>
			<div
				class="right"
				@click="onChangePage('+')"
				:class="{ disabled: intervalPageNum == intervalNum }"
			>
				<i class="es-icon-pointer-right"></i>
			</div>
		</div>
	</div>
</template>
<script>
import * as Echarts from 'echarts';
export default {
	props: {
		id: {
			type: String,
			default: ''
		},
		data: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			interval: null,
			intervalNum: 0,
			intervalPageNum: 1,
			chart: null
		};
	},
	watch: {
		data: {
			handler(val) {
				if (this.chart) {
					this.chart.clear();
				} else {
					this.chart = Echarts.init(document.getElementById(this.id));
				}
				this.$nextTick(() => {
					this.intervalNum = 0;
					this.intervalPageNum = 1;
					if (val.length > 10) {
						//长度大于10，开启分页
						this.intervalNum = Math.ceil(val.length / 10);
						this.draw(
							this.id,
							val.slice((this.intervalPageNum - 1) * 10, this.intervalPageNum * 10)
						);
					} else {
						this.draw(this.id, val);
					}
				});
			},
			deep: true
		}
	},
	mounted() {
		if (this.data.length > 0) {
			if (this.chart) {
				this.chart.clear();
			} else {
				this.chart = Echarts.init(document.getElementById(this.id));
			}
			this.$nextTick(() => {
				this.intervalNum = 0;
				this.intervalPageNum = 1;
				if (this.data.length > 10) {
					//长度大于10，开启分页
					this.intervalNum = Math.ceil(this.length / 10);
					this.draw(this.id, this.data.slice((this.intervalPageNum - 1) * 10, this.intervalPageNum * 10));
				} else {
					this.draw(this.id, this.data);
				}
			});
		}
	},
	methods: {
		draw(id, d) {
			let data = JSON.parse(JSON.stringify(d)).map(e => {
					return {
						...e,
						itemStyle: {
							borderRadius: e.value > 0 ? [4, 4, 0, 0] : [0, 0, 4, 4]
						}
					};
				}),
				axisData = data.map(e => {
					if (e.name.length < 5) {
						return e.name;
					} else {
						let arr = e.name.split('');
						arr.forEach((ele, index) => {
							if (index > 0 && index % 4 == 0) {
								arr[index] = '\n' + ele;
							}
						});
						return arr.join('');
					}
				});

			if (!data && data.length == 0) {
				return false;
			}
			let option = {
				grid: {
					top: '15%',
					left: '60',
					right: '10',
					bottom: '100'
					// containLabel:true
				},
				xAxis: {
					type: 'category',
					// boundaryGap: false,
					axisLabel: {
						// rotate: 45,
						color: '#333333',
						fontSize: '14px',
						interval: 0
					},
					axisLine: {
						show: false,
						lineStyle: {
							color: '#EFEFEF'
						}
					},
					axisTick: {
						show: false
					},
					splitLine: {
						show: false
					},
					data: axisData
				},
				yAxis: {
					type: 'value',
					minInterval: 50,
					min: -100,
					max: 100,
					axisLine: {
						show: false
					},
					axisLabel: {
						color: '#333',
						margin: 10,
						fontSize: '14px',
						formatter: val => {
							return val + '%';
						}
					},
					splitLine: {
						show: true, // 是否展示分割线条
						lineStyle: {
							color: ['#CDD9E4'],
							type: 'dashed'
						}
					}
				},
				legend: {
					show: false
				},
				emphasis: {
					disabled: true
				},
				series: {
					name: 'xx',
					type: 'bar',
					barWidth: '16',
					label: {
						show: true,
						distance: 8,
						position: 'top',
						formatter: res => {
							return `{img${res.data.condition == '完成' ? '1' : '2'}|${res.data.value}%}`;
						},
						rich: {
							img1: {
								color: '#D0454D',
								width: 48,
								height: 37,
								align: 'center',
								backgroundColor: {
									image: require('@/assets/image/home/label_red.png')
								}
							},
							img2: {
								color: '#0CC41D',
								width: 48,
								height: 37,
								align: 'center',
								backgroundColor: {
									image: require('@/assets/image/home/label_green.png')
								}
							}
						}
					},
					itemStyle: {
						color: '#2591F7',
						shadowBlur: 8,
						shadowOffsetX: -6,
						shadowColor: 'rgba(37,145,247,0.21)'
					},
					barGap: '-100%',
					data
				}
			};
			this.chart.setOption(option);
			window.addEventListener('resize', () => {
				this.chart && this.chart.resize();
			});
		},
		onChangePage(type) {
			switch (type) {
				case '+':
					if (this.intervalPageNum == this.intervalNum) return;
					this.intervalPageNum++;
					break;
				case '-':
					if (this.intervalPageNum == 0) return;
					this.intervalPageNum--;
					break;
				default:
					break;
			}

			this.chart.clear();
			this.draw(
				this.id,
				this.data.slice((this.intervalPageNum - 1) * 10, this.intervalPageNum * 10)
			);
		},
		onDotClick(item) {
			if (this.intervalPageNum == item) return;
			this.intervalPageNum = item;
			this.draw(
				this.id,
				this.data.slice((this.intervalPageNum - 1) * 10, this.intervalPageNum * 10)
			);
		}
		// onMouseEnter() {
		// 	//鼠标进入停止时循环
		// 	if (this.data.length > 10) {
		// 		clearInterval(this.interval);
		// 		this.interval=null
		// 	}
		// },
		// onMouseLeave() {
		// 	//鼠标移出继续循环
		// 	if (this.data.length > 10 && !this.interval) {
		// 		//长度大于10，开启轮播
		// 		this.intervalNum = Math.ceil(this.data.length / 10);
		// 		this.interval = setInterval(() => {
		// 			this.draw(this.id, this.data.slice(this.intervalPageNum * 10, (this.intervalPageNum + 1) * 10));
		// 			this.intervalPageNum++;
		// 			this.intervalPageNum == this.intervalNum && (this.intervalPageNum = 0);
		// 		}, 5000);
		// 	}
		// }
	}
};
</script>
<style lang=scss scoped>
.home-bar {
	width: 100%;
	height: 100%;
	position: relative;

	.bar {
		width: 100%;
		height: 100%;
	}
	.page-list {
		position: absolute;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		bottom: 20px;
		.dot-list {
			display: flex;
			.dot-item {
				width: 12px;
				height: 12px;
				border-radius: 50%;
				margin: 0 6px;
				background: #bbb;
				cursor: pointer;
				&.actived {
					background: #2591f7;
				}
			}
		}
		.left,
		.right {
			width: 20px;
			height: 20px;
			background: #2591f7;
			color: #fff;
			border-radius: 50%;
			margin: 0 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			&.disabled {
				background: #bbb;
				cursor: not-allowed;
			}
		}
	}
}
</style>
