<template>
	<div class="card" :style="{width:`${width}px`}">
		<div class="head" v-if="headTab||title" >
			<template v-if="headTab">
				<div class="tab-list">
					<div
						class="tab-item"
						v-for="(item, index) in headTabList"
						:class="{ actived: index == tabActived }"
						:key="index"
						@click="tabActived = index"
					>
						{{ item }}
					</div>
				</div>
			</template>
			<template v-else>
				<div class="title">{{ title }}</div>
			</template>
			<div class="more" v-if="more" @click="onMore">
				更多
				<i class="es-icon-pointer-right" />
			</div>
		</div>
		<div class="content">
			<template v-if="headTab">
				<slot :name="tabActived" />
			</template>
			<template v-else>
				<slot />
			</template>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		headTab: {
			type: Boolean,
			default: false
		},
		headTabList: {
			type: Array,
			default: () => {
				return [];
			}
		},
		title: {
			type: String,
			default: ''
		},
		more: {
			type: Boolean,
			default: false
		},
		width: {
			type: String,
			default: '0'
		}
	},
	data() {
		return {
			tabActived: 0
		};
	},
	methods: {
		onMore() {
			this.$emit('onMore');
		}
	}
};
</script>

<style lang=scss scoped>
.card {
	background: #fff;
	border-radius: 6px;
	.head {
		height: 56px;
		padding: 0 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		border-bottom: 1px solid rgba($color: #dfebf6, $alpha: 0.7);
		.title {
			font-family: Alibaba PuHuiTi 2;
			font-weight: normal;
			font-size: 18px;
			color: #2591f7;
		}
		.tab-list {
			height: 100%;
			align-items: center;
			display: flex;
			.tab-item {
                cursor: pointer;
				font-family: Alibaba PuHuiTi 2;
				height: 100%;
				display: flex;
				align-items: center;
				font-weight: normal;
				font-size: 18px;
				color: #15224C;
				margin: 0 15px;
				&:first-child {
					margin-left: 0;
				}
				&:last-child {
					margin-right: 0;
				}
				&.actived {
					color: #2591f7;
					position: relative;
					&::after {
						content: '';
						position: absolute;
						bottom: 0;
						left: 7px;
						width: 100%;
						height: 2px;
					}
				}
			}
		}
		.more {
			font-family: Alibaba PuHuiTi 2;
			font-weight: normal;
			font-size: 16px;
			cursor: pointer;
			color: #2591f7;
			.es-icon-pointer-right {
				margin-left: 12px;
			}
		}
	}
    .content{

    }
}
</style>