<template>
	<component
		:is="module"
		class="es-process"
		:boot-name="$route.query.boot"
		:code="$route.query.modulecode"
	></component>
</template>
<script>
import EsError from './Error.vue';
export default {
	components: { EsError },
	computed: {
		module: function () {
			return (
				'es-process-' +
				(this.$route.query.module ? this.$route.query.module.toLowerCase() : 'error')
			);
		}
	},
	mounted() {}
};
</script>
