//经营展示
export const operateShow = {
  mainData:
    "/gwapi/gz-financial-analysis/operateAnalysis/mainOperatingIndicators",
  rankingIncome:
    "/gwapi/gz-financial-analysis/operateAnalysis/rankingOfOperatingIncome",
  rankingProfits:
    "/gwapi/gz-financial-analysis/operateAnalysis/rankingOfTotalProfits",
  rankingOfTotalProfitsTwo:
    "/gwapi/gz-financial-analysis/operateAnalysis/rankingOfTotalProfitsTwo",
  dataSheet: "/gwapi/gz-financial-analysis/operateAnalysis/businessDataSheet",
};

// 经营分析
export const operateAnalysis = {
  inRevenue: "/gwapi/gz-financial-analysis/operateAnalysis/changesInRevenue",
  inProfit: "/gwapi/gz-financial-analysis/operateAnalysis/changesInProfit",
  contributeData:
    "/gwapi/gz-financial-analysis/operateAnalysis/contributionsInRevenue",
  completionInCome:
    "/gwapi/gz-financial-analysis/operateAnalysis/budgetCompletionInCome",
};

// 共用
export const common = {
  companyTree: "/finance/FinancialCompany/companyTreeCt.dhtml",
  recentDate: "/gwapi/gz-financial-analysis/operateAnalysis/getRecentDate",
  exportExcel: "/gwapi/gz-financial-analysis/easyExcel/exportExcel",
};
//信产专用接口
export const operateAnalysisXc = {
  contributeData:
    "/gwapi/gz-financial-analysis/operateAnalysis/contributionsInRevenueChild",
    dataSheet:"/gwapi/gz-financial-analysis/operateAnalysis/businessDataSheetLevelTwo"

}