<template>
	<div id="app">
		<es-main
			transfer="/transfer"
			:logo="logo"
			:type="type"
			:data="menu"
			:remote="remote"
			:theme="theme"
			:host="host"
		></es-main>
	</div>
</template>
<script>
import WujieVue from 'wujie-vue2';
const { bus } = WujieVue;
export default {
	components: {},
	watch: {
		$route: {
			handler(val) {
				if (val.path == '/home') {
					this.sideNav = false;
				} else {
					this.sideNav = true;
				}
			},
			deep: true
		}
	},
	data() {
		return {
			// visible: false,
			// //开发模式是加载本地public/meta/menu.json文件配置的菜单，如果不需要改成remote:true
			remote: true,
			// remote: process.env.NODE_ENV != 'development',
			type: 'simplicity',
			//type: process.env.NODE_ENV != 'development' ? 'simplicity' : undefined,
			theme: '#2591F7',
			host: this.$.getStorage('host'),
			menu: undefined,
			// sideNav: false,
			logo: require('@/assets/image/main/logo.png'),
			userName: '',
			systemName: '',
			daytime: true,
			show: localStorage.getItem('showLaunch') ? false : true
		};
	},
	created() {
		const color = localStorage.getItem('theme') || this.theme;
		this.$.updateTheme(color);
		this.getMenu();
	},
	computed: {},
	mounted() {},
	methods: {
		handleSetting(type, res) {
			if (type === 'theme') {
				bus.$emit('changeTheme', res);
			}
		},
		getMenu() {
			if (process.env.NODE_ENV == 'development' && !this.remote) {
				this.$.ajax({
					url: '/meta/menu.json',
					host: false
				}).then(res => {
					this.menu = res;
				});
			}
		}
	},
	destroyed() {}
};
</script>
<style lang="scss" scoped>
#app {
	height: 100%;
	overflow: hidden;
	position: relative;
}
::v-deep {
	.es-nav {
		.es-nav-main[biserial='true'] {
			background-color: #fff;
			.es-menu-vertical {
				padding: 10px;
				.el-menu-item:not(.is-disabled),
				.el-submenu__title {
					color: #333;
					border-bottom: 1px solid #d1d1d1;
					.es-menu-item-icon,
					& > i {
						color: #333;
					}
					&.is-active {
						background-color: transparent;
						color: #1b82d7;
						.es-menu-item-icon {
							color: #1b82d7;
						}
						&::before {
							content: '';
							display: block;
							width: 5px;
							height: 37px;
							position: absolute;
							left: 0;
							bottom: 0;
							background-color: #1b82d7;
						}
					}
					&:hover {
						background-color: #b9daf6;
						color: #1b82d7;
						.es-menu-item-icon {
							color: #1b82d7;
						}
					}
				}
			}
		}
	}
	.el-dialog__body {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
