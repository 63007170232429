<!--
* @desc: 主要经营指标
* @Author: YanZhongYing
* @Date: 2024-06-05
-->
<template>
  <div class="box flex-fc">
    <div class="box-date">
      <es-date-picker v-model="date" type="month"> </es-date-picker>
    </div>
    <div class="box-c">
      <Tree :date="date" @setId="setId" />
      <div class="box-c-right">
        <div class="end-date">
          <span style="margin-right: 10px;">当前选择单位：{{ treeName }}</span>
          <span>截至日期：{{ endDate }}</span>
        </div>
        <template v-for="(item, index) of info">
          <div :key="index" class="box-c-right-item">
            <!-- 头部 -->
            <Header :title="item.title" />
            <!-- 绝对值指标 -->
            <div
              v-if="item.title == '绝对值指标' && item.data.length > 0"
              class="absolute-box"
            >
              <template v-for="(i, ind) of item.data">
                <div
                  :key="ind"
                  class="absolute-box-item"
                  :style="{
                    backgroundImage:
                      'url(' +
                      require(`@/assets/image/operateAnalysisShow/card${
                        ind + 1
                      }.png`) +
                      ')',
                  }"
                >
                  <div class="absolute-box-item-name">
                    {{ i.name }}
                  </div>
                  <div class="absolute-box-item-value">
                    {{ i.value }}<span class="unit">{{ i.valueUnit }}</span>
                  </div>
                  <div class="absolute-box-item-per">
                    {{ i.percentage }}%
                    <img
                      class="per-img"
                      :src="
                        require(`@/assets/image/operateAnalysisShow/icon_${
                          i.percentage > 0 ? 'up' : 'down'
                        }.png`)
                      "
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div v-if="i.budgetCompletionRate">
                    <div class="absolute-box-item-line"></div>
                    <el-progress
                      :percentage="Number(i.budgetCompletionRate)"
                      :stroke-width="8"
                      textPosition="top"
                      background="#ffffff20"
                      ><span slot="prepend">预算完成率</span></el-progress
                    >
                  </div>
                </div>
              </template>
            </div>
            <!-- 比率类指标 -->
            <div
              v-if="item.title == '比率类指标' && item.data.length > 0"
              class="interest-box"
            >
              <template v-for="(i, ind) of item.data">
                <div :key="ind" class="interest-box-item">
                  <div class="interest-box-item-name">{{ i.name }}</div>

                  <img
                    class="interest-box-item-img"
                    :src="
                      require(`@/assets/image/operateAnalysisShow/img${
                        ind + 1
                      }.png`)
                    "
                    alt=""
                  />
                  <div class="interest-box-item-value">
                    {{ i.value }}
                    <span>{{
                      i.name.includes("全员劳动") ? "万元" : "%"
                    }}</span>
                  </div>

                  <!-- <el-progress
                    v-else
                    class="interest-box-item-progress"
                    type="circle"
                    :width="160"
                    :percentage="Number(i.value)"
                    :stroke-width="10"
                    :linearGradient="['#fff', '#3f96ef']"
                    :format="
                      (data) => {
                        return data;
                      }
                    "
                  >
                    <span slot="inside" class="unit">%</span>
                  </el-progress> -->

                  <div class="interest-box-item-per">
                    {{ i.percentage
                    }}{{ i.name.includes("全员劳动") ? "万元/人" : "个百分点" }}
                    <img
                      class="per-img"
                      :src="
                        require(`@/assets/image/operateAnalysisShow/icon_${
                          i.percentage > 0 ? 'up' : 'down'
                        }.png`)
                      "
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </template>
            </div>
            <el-empty
              v-if="item.data.length == 0"
              description="暂无数据"
            ></el-empty>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "./mixins/mixins";
import Header from "./components/Header.vue";
import Tree from "./components/Tree.vue";
import { operateShow } from "@/http/operateAnalysisShow";
export default {
  components: { Header, Tree },
  mixins: [Mixins],
  data() {
    return {
      operateShow,
      // date: this.$.getCurrentDate().slice(0, -3),
      info: [
        {
          data: [],
          title: "绝对值指标",
        },
        {
          data: [],
          title: "比率类指标",
        },
      ],
      id: "", //9151000020181233912024059
      endDate: "",
      treeName:''
    };
  },
  watch: {
    date(val) {
      // 设置日期为下一个月的第一天
      const nextMonth = new Date(val.split("-")[0], val.split("-")[1], 1);
      // 减去一天，得到本月的最后一天
      nextMonth.setDate(nextMonth.getDate() - 1);
      this.endDate = val + "-" + nextMonth.getDate();

      // this.getData();
    },
  },
  mounted() {},
  methods: {
    /**
     * @desc: 获取树id
     **/
    setId(id,first,name) {
      this.id = id;
      this.treeName = name
      this.getData();
    },
    /**
     * @desc: 获取数据
     **/
    getData() {
      if (this.id && this.date) {
        const loading = this.$.loading(this.$loading, "加载中");
        this.$.ajax({
          url: operateShow.mainData,
          params: {
            dateString: this.date,
            id: this.id,
          },
        })
          .then((res) => {
            loading.close();
            const { rCode, results, msg } = res;
            if (rCode == 0) {
              this.info = results;
              this.info[1].title = "比率类指标";
            }
          })
          .catch((error) => {
            loading.close();
          });
      } else {
        this.info = [
          {
            data: [],
            title: "绝对值指标",
          },
          {
            data: [],
            title: "比率类指标",
          },
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  background: #f6f6f6;
  font-size: 16px;
  overflow: auto;

  &-date {
    width: 100%;
    padding: 6px 10px;
    background: #fff;
    border: 1px solid #eee;
    text-align: right;
    .es-date-picker {
      width: 200px;
    }
  }
  &-c {
    position: relative;
    flex: 1;
    display: flex;

    &-right {
      flex: 1;
      padding: 16px;
      display: flex;
      flex-direction: column;
      position: relative;
      .end-date {
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 16px;
      }
      &-item {
        flex: 1;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 0px rgba(30, 52, 70, 0.05);
        &:first-child {
          margin-bottom: 16px;
        }
      }
    }
  }
}
//绝对值指标
.absolute-box {
  height: calc(100% - 48px);
  padding: 16px;
  display: flex;
  justify-content: space-between;
  &-item {
    flex: 1;
    height: 100%;
    background-size: 100% 100%;
    &:not(:last-child) {
      margin-right: 16px;
    }
    color: #fff;
    padding: 32px;
    &-name {
      font-size: 18px;
    }
    &-value {
      font-size: 38px;
      margin: 16px 0;
      .unit {
        font-size: 18px;
      }
    }
    &-per {
      display: inline-block;
      background: #ffffff10;
      border-radius: 16px;
      padding: 6px 12px;
      .per-img {
        margin-left: 8px;
        width: 12px;
      }
    }
    &-line {
      height: 1px;
      background: #ffffff;
      opacity: 0.2;
      margin: 24px 0;
    }
    // 进度条
    ::v-deep .el-progress {
      .el-progress-bar__inner {
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          #fff 100%
        ) !important;
      }
      .el-progress--prepend {
        top: -6px;
      }
      .el-progress__text {
        font-size: 30px !important;
        color: #ffffff;
        top: -15px;
      }
    }
  }
}

// 比率类指标
.interest-box {
  height: calc(100% - 48px);
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  color: #333333;
  &-item {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:not(:last-child) {
      border-right: 1px solid #eee;
    }
    &-name {
      font-size: 18px;
    }
    &-progress {
      margin: 10px 0;
      ::v-deep .el-progress__text {
        .el-progress-text {
          font-size: 38px;
        }
        .unit {
          font-size: 18px;
        }
      }
    }

    &-per {
      background: #cccccc20;
      border-radius: 16px;
      padding: 6px 12px;
      .per-img {
        margin-left: 8px;
        width: 12px;
      }
    }
    &-img {
      width: 86px;
      margin: 26px 0 34px;
    }
    &-value {
      margin-bottom: 10px;
      font-size: 38px;
      span {
        font-size: 18px;
      }
    }
  }
}
</style>
@/http/operateAnalysisShow