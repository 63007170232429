<!--
* @desc: 利润变动情况
* @Author: YanZhongYing
* @Date: 2024-06-07
-->
<template>
  <div class="box">
    <div class="box-header flex-c-sb">
      <div>
        <span
          v-for="(item, ind) of tabs"
          :key="ind"
          :class="{ 'tabs-item': true, 'tabs-active': ind == activeTab }"
          @click="changeTab(ind)"
          >{{ item }}</span
        >
      </div>
      <div>
        <span class="tree-name">当前选择单位：{{ treeName }}</span>
        <es-button style="margin-right: 16px" @click="exportImage"
          >导出</es-button
        >
        <es-date-picker v-model="date" type="month"> </es-date-picker>
      </div>
    </div>
    <div class="box-c box-flex">
      <Tree :date="date" @setId="setId" />
      <div class="box-c-right flex-item">
        <div
          
          ref="rightBox"
          class="card-item card-item-one"
          v-if="info[0]"
        >
        <!-- v-if="id !== firstId" -->
          <Header :title="info[0].name" remark="万元" />
          <MoreBar
            v-if="info[0].data.length > 0"
            ref="'moreBar0'"
            class="card-bar"
            :id="'moreBar0'"
            :color="info[0].color"
            :data="info[0].data"
            :large="true"
            @getChart="getChart"
          ></MoreBar>
          <el-empty v-else description="暂无数据"></el-empty>
        </div>
        <!-- <el-scrollbar v-else class="es-scrollbar" slot="contents">
          <div v-if="info.length > 0" ref="rightBox" class="card">
            <template v-for="(item, ind) of info">
              <div :key="ind" class="card-item">
                <Header :title="item.name" remark="万元" />
                <MoreBar
                  v-if="item.data.length > 0"
                  :ref="'moreBar' + ind"
                  class="card-bar"
                  :id="'moreBar' + ind"
                  :color="item.color"
                  :data="item.data"
                  @getChart="getChart"
                ></MoreBar>
              </div>
            </template>
          </div>
          <el-empty v-else description="暂无数据"></el-empty>
        </el-scrollbar> -->
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";

import Mixins from "./mixins/mixins";
import Header from "./components/Header.vue";
import Tree from "./components/Tree.vue";
import MoreBar from "./components/MoreBar.vue";
import { operateAnalysis } from "@/http/operateAnalysisShow";
export default {
  components: {
    Header,
    Tree,
    MoreBar,
  },
  mixins: [Mixins],
  data() {
    return {
      treeName:'',
      id: "", //91510100MA6C89MU2X2024059
      firstId: "", //9151000020181233912024059
      tabs: ["营业收入", "利润总额"],
      activeTab: 0,
      info: [],
      chartArr: [],
    };
  },
  watch: {
    date() {
      this.chartArr = [];
      // if (this.id) {
      //   this.getData();
      // }
    },
  },
  methods: {
    /**
     * @desc: 获取树id
     **/
    setId(id, first,name) {
      console.log(1111)
      if (first) {
        this.firstId = id;
      }
      this.id = id;
      this.treeName = name
      this.getData();
    },
    /**
     * @desc: 获取数据
     **/
    getData() {
      if (this.id && this.date) {
        const loading = this.$.loading(this.$loading, "加载中");
        this.$.ajax({
          url:
            this.activeTab == 0
              ? operateAnalysis.inRevenue
              : operateAnalysis.inProfit,
          params: {
            dateString: this.date,
            id: this.id,
          },
        })
          .then((res) => {
            loading.close();
            const { rCode, results, msg } = res;
            if (rCode == 0) {
              this.info = results;
              this.info[0].color = [
                ["#E16E6A", "#e69e7f"],
                ["#F4943F", "#F5AC6A"],
                "#F59D4F",
              ];
            }
          })
          .catch((error) => {
            loading.close();
          });
      } else {
        this.$set(this, "info", []);
      }
    },
    changeTab(ind) {
      this.chartArr = [];
      this.activeTab = ind;
      if (this.id) {
        this.getData();
      }
    },
    /**
     * @desc: 保存echarts初始化图表对象
     **/
    getChart(res) {
      // this.chartArr.push(res);
    },
    /**
     * @desc: 导出图片
     **/
    async exportImage() {
      const element = this.$refs.rightBox;
      const canvas = await html2canvas(element, {
        scale: 2, // 提高图片清晰度
        useCORS: true, // 解决跨域问题
      });
      canvas.toBlob((blob) => {
        saveAs(
          blob,
          this.date + "-利润变动情况-" + this.tabs[this.activeTab] + ".png"
        );
      });
    },
    /**
     * @desc: 导出xls
     **/
    async exportExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1");
      this.chartArr.forEach((i, k) => {
        const image = i.getDataURL({
          type: "png",
          pixelRatio: 2,
          backgroundColor: "#fff",
        });
        worksheet.addRow([this.info[k].name]);
        worksheet.addRow([]);

        this.addImageToSheet(workbook, worksheet, image, {
          tl: { col: 0, row: k * 12 + 2 },
          br: { col: 6, row: k * 12 + 12 },
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "利润变动情况-" + this.tabs[this.activeTab] + ".xlsx"
      );
    },
    async addImageToSheet(workbook, worksheet, image, range) {
      const imageId = workbook.addImage({
        base64: image,
        extension: "png",
      });
      worksheet.addImage(imageId, range);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  background: #f6f6f6;
  font-size: 18px;
  overflow: auto;
  &-header {
    width: 100%;
    padding: 6px 10px;
    background: #fff;
    border: 1px solid #eee;
    .tabs-item {
      border-radius: 17px;
      border: 1px solid #dcdfe6;
      font-size: 16px;
      color: #999999;
      padding: 10px 16px;
      margin-right: 16px;
      cursor: pointer;
    }
    .tabs-active {
      background: rgba(94, 148, 218, 0.1);
      border: 1px solid #9eb9dc;
      color: #4e7aa9;
    }
    .es-date-picker {
      width: 200px;
    }
    .tree-name {
      margin-right: 10px;
      font-size: 16px;
    }
  }
  &-c {
    position: relative;
    height: calc(100% - 54px);

    &-right {
      padding: 16px;
    }
    .card {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &-item {
        background: #fff;
        height: 348px;
        width: calc(50% - 8px);
        border-radius: 8px;
        min-width: 500px;

        &:not(:nth-last-child(1), :nth-last-child(2)) {
          margin-bottom: 16px;
        }
      }
      &-item-one {
        width: 100%;
        height: 100%;
      }
      &-bar {
        height: calc(100% - 54px);
      }
    }
  }
}
</style>
@/http/operateAnalysisShow