<template>
  <div :id="id"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "topLableBars",
  props: {
    id: {
      type: String,
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    data: {
      handler(val) {
        this.$nextTick(() => {
          this.drawBar(this.id, val);
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.drawBar(this.id, this.data);
    });
  },
  methods: {
    drawBar(id, res) {
      let data = JSON.parse(JSON.stringify(res));
      let dom = document.getElementById(id);
      if (!dom || data.length == 0) {
        return;
      }
      let chart = echarts.init(dom);
      chart.clear();

      let xdata = data.map((i) => i.name);

      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            shadowStyle: {
              color: "#00000015",
            },
          },
          formatter: (param) => {
            return param[0].name + "：" + param[0].value + "%";
          },
          backgroundColor: "#00000080",
          borderColor: "rgba(0, 0, 0, 0)",
          opacity: 0.5,
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
        },
        grid: {
          top: "10%",
          right: "4%",
          left: "4%",
          bottom: "25%",
        },
        xAxis: [
          {
            type: "category",
            color: "#59588D",
            data: xdata,
            axisLabel: {
              margin: 20,
              color: "#809dbe",
              interval: 0,
              rotate: 30,
              textStyle: {
                color: "#333",
                fontSize: 14,
              },
              formatter: (params) => {
                if (params.length > 10) {
                  return (
                    params.slice(0, Math.ceil(params.length / 2)) +
                    "\n" +
                    params.slice(Math.ceil(params.length / 2), params.length)
                  );
                }
                return params;
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#333",
              },
            },
            axisTick: {
              show: true,
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            // max: 10,
            // min: -2,
            minInterval: 50,
            axisLabel: {
              formatter: "{value} %",
              color: "#333",
              textStyle: {
                fontSize: 14,
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#333",
              },
            },
            axisTick: {
              show: true,
            },
            splitLine: {
              show: true,
              lineStyle: {
                // type: "dashed",
                color: "#ccc",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: data,
            barWidth: "24",
            label: {
              position: "top",
              show: true,
              distance: 5,
              formatter: (res) => {
                const { data, value } = res;
                if (data.type == "完成") {
                  return ["{yes|" + value + "%}"];
                }
                return ["{no|" + value + "%}"];
              },
              rich: {
                yes: {
                  backgroundColor: "#ffcccc",
                  color: "red",
                  padding: [6, 8, 4],
                  borderRadius: 4,
                  fontSize: 14,
                },
                no: {
                  backgroundColor: "#d7ffdd",
                  color: "green",
                  padding: [6, 8, 4],
                  borderRadius: 4,
                  fontSize: 14,
                },
              },
            },
            itemStyle: {
              color: "#3398db",
              // 渐变色
              // normal: {
              //   barBorderRadius: [4, 4, 0, 0],
              //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //     {
              //       offset: 0,
              //       color: '#3398db',
              //     },
              //     {
              //       offset: 1,
              //       color: '#ffffff',
              //     },
              //   ]),
              // },
            },
          },
        ],
      };

      chart.setOption(option);
      window.addEventListener("resize", () => {
        chart && chart.resize();
      });
    },
  },
};
</script>
<style scoped lang="scss"></style>
