<!--集团员工看的-->
<template>
	<div class="background">
		<div class="content">
			<card title="主要经营指标" width="792">
				<div class="zyjyzb">
					<div class="zyjyzb-toolbar">
						<div class="zyjyzb-tabs">
							<div
								class="zyjyzb-tabs-item"
								:class="{ actived: index == actived }"
								v-for="(item, index) in tabsList"
								:key="index"
								@click="onTab(index)"
							>
								{{ item }}
							</div>
							<div class="date">(1-{{ asMonth }}月)</div>
						</div>
						<div class="zyjyzb-lenged">
							<div class="zyjyzb-lenged-item">
								<div class="icon r" :class="{ bar: actived < 2 }" />
								完成预算进度
							</div>
							<div class="zyjyzb-lenged-item">
								<div class="icon g" :class="{ bar: actived < 2 }" />
								未完成预算进度
							</div>
						</div>
					</div>
					<div class="zyjyzb-content" v-if="actived == 2 || actived == 3">
						<el-carousel class="swiper" height="615px" :interval="5000">
							<el-carousel-item
								class="swiper-item"
								v-for="ele in actived == 2
									? Math.ceil(ylqyList.length / 12)
									: Math.ceil(ksqyList.length / 12)"
								:key="ele"
							>
								<div
									class="zyjyzb-content-item"
									v-for="item in actived == 2
										? ylqyList.slice((ele - 1) * 12, ele * 12)
										: ksqyList.slice((ele - 1) * 12, ele * 12)"
									:key="item.name"
								>
									<div class="zyjyzb-content-item-head">
										<div class="name">{{ item.name }}</div>
										<div class="qk">
											<div class="icon" :class="{ g: actived == 3 }"></div>
											{{ actived == 2 ? '盈利' : '控亏' }}
										</div>
									</div>
									<div class="zyjyzb-content-item-info">
										<div class="info-item">
											<div class="label">营业收入</div>
											<div class="value" :class="{ g: item.operaingIncomeType !== '完成' }">
												{{ item.operaingIncome.replace('%', '') }}
												<span class="unit">%</span>
											</div>
										</div>
										<div class="info-item">
											<div class="label">利润总额</div>
											<div class="value" :class="{ g: item.totalProfitType !== '完成' }">
												{{ item.totalProfit.replace('%', '') }}
												<span class="unit">%</span>
											</div>
										</div>
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="zyjyzb-content" v-else>
						<div class="bar-box">
							<home-bar :data="[barData1, barData2][actived] || []" id="bar1" />
						</div>
					</div>
				</div>
			</card>
			<div class="bb">
				<card title="集团要闻" :more="true" width="384" @onMore="onMore('groupNews')">
					<div class="focus-news">
						<div
							class="show-img"
							v-if="jtywList[0] && jtywList[0].adjunctId && jtywList[0].adjunctId.length > 0"
							@click="toDetail(jtywList[0], 'groupNews')"
							:class="{expir:itemClass(jtywList[0])}"
						>
							<div class="icon">  {{itemClass(jtywList[0])?'已结束':'NEW'}}</div>
							<img
								class="show-img-main"
								:src="`${baseUrl}/portal/fileManagement/previewAdjunct.dhtml?adjunctId=${jtywList[0].adjunctId}`"
								alt=""
							/>
							<div class="info">
								<div class="title">{{ jtywList[0].title }}</div>
								<div class="date">{{ jtywList[0].publishTime.split(' ')[0] }}</div>
							</div>
						</div>
						<div class="focus-news-list" v-if="jtywList.length > 0">
							<div
								class="focus-news-item"
								v-for="item in jtywList"
								:key="item.id"
								:class="{ expir: itemClass(item) }"
								@click="toDetail(item, 'groupNews')"
							>
								<div class="title">
									<img
										class="icon"
										:src="
											!itemClass(item)
												? require('@/assets/image/home/focus-news-actived.png')
												: require('@/assets/image/home/focus-news.png')
										"
										alt=""
									/>
									{{ item.title }}
								</div>
								<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
							</div>
						</div>
						<no-data v-if="jtywList.length == 0" />
					</div>
				</card>
				<card title="子公司要闻" :more="true" width="384" @onMore="onMore('subsidiaryInformation')">
					<div class="focus-news">
						<div
							class="show-img"
							v-if="zgsywList[0] && zgsywList[0].adjunctId && zgsywList[0].adjunctId.length > 0"
							@click="toDetail(zgsywList[0], 'subsidiaryInformation')"
							:class="{expir:itemClass(zgsywList[0])}"
						>
							<div class="icon">  {{itemClass(zgsywList[0])?'已结束':'NEW'}}</div>
							<img
								class="show-img-main"
								:src="`${baseUrl}/portal/fileManagement/previewAdjunct.dhtml?adjunctId=${zgsywList[0].adjunctId}`"
								alt=""
							/>
							<div class="info">
								<div class="title">{{ zgsywList[0].title }}</div>
								<div class="date">{{ zgsywList[0].publishTime.split(' ')[0] }}</div>
							</div>
						</div>
						<div class="focus-news-list" v-if="zgsywList.length > 0">
							<div
								class="focus-news-item"
								v-for="(item) in zgsywList"
								:key="item.id"
								:class="{ expir: itemClass(item) }"
								@click="toDetail(item, 'subsidiaryInformation')"
							>
								<div class="title">
									<img
										class="icon"
										:src="
											!itemClass(item)
												? require('@/assets/image/home/focus-news-actived.png')
												: require('@/assets/image/home/focus-news.png')
										"
										alt=""
									/>
									{{ item.title }}
								</div>
								<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
							</div>
						</div>
						<no-data v-if="zgsywList.length == 0" />
					</div>
				</card>
			</div>
			<card
				class="mt"
				width="384"
				:headTab="true"
				:headTabList="['公告', '通知']"
				:more="true"
				@onMore="onMore('announcementNotice')"
			>
				<template #0>
					<div class="info-list" v-if="ggList.length > 0">
						<div
							class="info-item"
							v-for="(item) in ggList"
							:key="item.id"
							:class="{ expir: itemClass(item) }"
							@click="toDetail(item, 'announcement', 0)"
						>
							<div class="title">
								<img
									class="icon"
									:src="
										!itemClass(item)
											? require('@/assets/image/home/focus-news-actived.png')
											: require('@/assets/image/home/focus-news.png')
									"
									alt=""
								/>
								{{ item.title }}
							</div>
							<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
						</div>
					</div>

					<no-data v-if="ggList.length == 0" height="220" />
				</template>
				<template #1>
					<div class="info-list" v-if="tzList.length > 0">
						<div
							class="info-item"
							v-for="(item) in tzList"
							:key="item.id"
							:class="{ expir: itemClass(item) }"
							@click="toDetail(item, 'notice', 1)"
						>
							<div class="title">
								<img
									class="icon"
									:src="
										!itemClass(item)
											? require('@/assets/image/home/focus-news-actived.png')
											: require('@/assets/image/home/focus-news.png')
									"
									alt=""
								/>
								{{ item.title }}
							</div>
							<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
						</div>
					</div>
					<no-data v-if="tzList.length == 0" height="100" />
				</template>
			</card>
			<card
				class="mt"
				title="综合信息"
				:more="true"
				width="384"
				@onMore="onMore('integratedInformation')"
			>
				<div class="info-list" v-if="zhxxList.length > 0">
					<div
						class="info-item"
						v-for="(item) in zhxxList"
						:key="item.id"
						:class="{ expir: itemClass(item) }"
						@click="toDetail(item, 'integratedInformation')"
					>
						<div class="title">
							<img
								class="icon"
								:src="
									!itemClass(item)
										? require('@/assets/image/home/focus-news-actived.png')
										: require('@/assets/image/home/focus-news.png')
								"
								alt=""
							/>
							{{ item.title }}
						</div>
						<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
					</div>
				</div>
				<no-data v-if="zhxxList.length == 0" height="220" />
			</card>
			<div class="bb mt">
				<card width="384">
					<div class="ctny">
						<div class="left">
							<div class="label">{{ shareData.shareCompanyName }}</div>
							<div class="num">{{ shareData.shareCode }}</div>
						</div>
						<div class="right" :class="{ red: shareData.increase && shareData.increase > 0 }">
							<img
								v-if="shareData.increase && shareData.increase > 0"
								class="icon"
								src="@/assets/image/home/stock.png"
								alt=""
							/>
							<img v-else class="icon" src="@/assets/image/home/fall.png" alt="" />
							<div class="value">{{ shareData.currentPrice }}</div>
							<div class="increase">{{ `(${shareData.increase || 0}%)` }}</div>
						</div>
					</div>
				</card>
				<card title="下载中心" :more="true" width="384" @onMore="onMore('downloadCenter')">
					<div class="download-box">
						<div
							class="download-item"
							v-for="(item, index) in downloadList"
							:key="item.id"
							@click="onMore('downloadCenter', index)"
						>
							<img
								class="icon"
								:src="
									[
										require('@/assets/image/home/wenjian.png'),
										require('@/assets/image/home/ruanjian.png'),
										require('@/assets/image/home/zhidu.png')
									][index]
								"
								alt=""
							/>
							{{ item.name }}
						</div>
					</div>
				</card>
			</div>
		</div>
	</div>
</template>

<script>
import Card from '../components/Card';
import HomeBar from '../components/HomeBar';
import NoData from '../components/NoData';
export default {
	components: { Card, HomeBar, NoData },
	data() {
		return {
			date1: new Date(),
			tabsList: ['营业收入', '利润总额', '盈利企业', '控亏企业'],
			actived: 0,
			ylqyList: [],
			ksqyList: [],
			barData1: [],
			barData2: [],
			jtywList: [],
			zgsywList: [],
			tzList: [],
			ggList: [],
			zhxxList: [],
			baseUrl: window.location.origin + (window.location.hostname == 'localhost' ? '/dev-api' : ''),
			downloadList: [],
			shareData: {},
			asMonth: '--'
		};
	},
	computed: {},
	mounted() {
		this.getFinaceData();
		this.getColumnIdByCode();
		this.getDownloadTabsFun();
		this.getShareData();
	},
	methods: {
		//财务接口-刘珂良
		getFinaceData() {
			//先拿时间
			this.$.ajax({ url: this.$url.getRecentDate }).then(res => {
				if (res.rCode == 0) {
					let dateString = res.results.date;
					this.asMonth = dateString.split('-')[1] - 0;
					//利润总额排名
					this.$.ajax({
						url: this.$url.rankingOfTotalProfits,
						params: {
							dateString
						}
					}).then(res => {
						if (res.rCode == 0) {
							this.barData2 = res.results.map(e => {
								return {
									name: e.companyName,
									value: e.process - 0,
									condition: e.condition
								};
							});
						}
					});
					//盈利企业、亏损企业
					this.$.ajax({
						url: this.$url.budgetCompletionInCome,
						params: {
							dateString
						}
					}).then(res => {
						if (res.rCode == 0) {
							this.ylqyList = res.results.profit || [];
							this.ksqyList = res.results.profitLoss || [];
						}
					});
					//营业收入排名
					this.$.ajax({
						url: this.$url.rankingOfOperatingIncome,
						params: {
							dateString
						}
					}).then(res => {
						if (res.rCode == 0) {
							this.barData1 = res.results.map((e, i) => {
								return {
									name: e.companyName,
									value: e.process - 0,
									condition: e.condition
								};
							});
						}
					});
				}
			});
		},
		//先拿目录id再用目录id去查数据
		getColumnIdByCode() {
			//集团要闻
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'gsyw' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnListJson + `?columnId=${res.data}`,
						data: {
							page: 1,
							rows: 10
						},
						method: 'POST'
					}).then(res => {
						this.jtywList = res.data;
					});
				}
			});
			//子公司要闻
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'agsyw' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnListJson + `?columnId=${res.data}`,
						data: {
							page: 1,
							rows: 10
						},
						method: 'POST'
					}).then(res => {
						this.zgsywList = res.data;
					});
				}
			});
			//公告
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'gg' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnListJson + `?columnId=${res.data}`,
						data: {
							page: 1,
							rows: 10
						},
						method: 'POST'
					}).then(res => {
						this.ggList = res.data;
					});
				}
			});
			//通知
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'tz' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnListJson + `?columnId=${res.data}`,
						data: {
							page: 1,
							rows: 10
						},
						method: 'POST'
					}).then(res => {
						this.tzList = res.data;
					});
				}
			});
			//综合信息
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'zhxx' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnList,
						params: { columnId: res.data }
					}).then(res => {
						if (res.status == 'success') {
							let columnId = res.data[0].id;
							this.$.ajax({
								url: this.$url.getColumnListJson + `?columnId=${columnId}`,
								data: {
									page: 1,
									rows: 10
								},
								method: 'POST'
							}).then(res => {
								this.zhxxList = res.data;
							});
						}
					});
				}
			});
		},
		//拿下载中心
		getDownloadTabsFun() {
			this.$.ajax({ url: this.$url.getDownloadTabs, params: { code: true, codeType: 'ct' } }).then(
				res => {
					this.downloadList = res[0]?.children || [];
				}
			);
		},
		//切换tab
		onTab(index) {
			this.actived = index;
		},
		//更多
		onMore(code, activedIndex) {
			this.$router.push({ name: code, query: { activedIndex } });
			// //集团要闻
			// groupNews
			// //子公司要闻
			// subsidiaryInformation
			// //公告通知
			// announcementNotice
			// //综合信息
			// integratedInformation
			// //下载中心
			// downloadCenter
		},
		//拿股票信息
		getShareData() {
			this.$.ajax({ url: this.$url.getShareData, method: 'POST' }).then(res => {
				if (res.status == 'success') {
					this.shareData = res.data.shareDataList[0];
				}
			});
		},
		//去详情
		toDetail(item, name) {
			if(this.itemClass(item)){
				this.$message.warning('该内容已结束！')
				return
			}
			//跳转类型的
			if (item.infoType !== 0 && item.infoUrl) {
				window.open(item.infoUrl, '_blank');
			}
			//去详情
			else {
				// this.$router.push({ name: 'detail', query: { id: item.id, name } });
				var { href } = this.$router.resolve({ name: 'detail', query: { id: item.id, name } });
				window.open(href, '_blank');
			}
		},
		//类函数
		itemClass(item) {
			if (item.expirationDate && item.expirationDate.length > 0) {
				let exDate = new Date(item.expirationDate).getDate();
				let now = new Date().getDate();
				return exDate < now;
			} else {
				return false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.background {
	width: 100%;
	padding: 24px 0 64px;
	background: #f4f6fd;
}
.content {
	width: 1200px;
	display: flex;
	margin: 0 auto;
	justify-content: space-between;
	flex-wrap: wrap;
	.bb {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		::v-deep .card:first-child {
			margin-bottom: 24px;
		}
	}
	.mt {
		margin-top: 24px;
	}
	.zyjyzb {
		width: 100%;
		height: 705px;
		padding: 24px 16px 0;
		.zyjyzb-toolbar {
			width: 100%;
			height: 40px;
			padding-bottom: 8px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.zyjyzb-tabs {
				display: flex;
				align-items: center;
				.zyjyzb-tabs-item {
					height: 32px;
					padding: 0 14px;
					line-height: 32px;
					background: #f2f2f2;
					border-radius: 16px;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #15224c;
					margin: 0 8px;
					cursor: pointer;
					&.actived {
						background: #2591f7;
						box-shadow: 0px 8px 10px 0px rgba(37, 145, 247, 0.21);
						color: #ffffff;
					}
				}
				.date {
					margin-left: 24px;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #b9bdc9;
				}
			}
			.zyjyzb-lenged {
				display: flex;
				.zyjyzb-lenged-item {
					display: flex;
					align-items: center;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #737a94;
					margin-left: 23px;
					.icon {
						width: 10px;
						height: 10px;
						margin-right: 9px;
						&.r {
							background: #d0454d;
							&.bar {
								background: url('@/assets/image/home/label_red.png') no-repeat;
								background-size: 100% 100%;
								width: 16px;
								height: 12px;
							}
						}
						&.g {
							background: #0cc41d;
							&.bar {
								background: url('@/assets/image/home/label_green.png') no-repeat;
								background-size: 100% 100%;
								width: 16px;
								height: 12px;
							}
						}
					}
				}
			}
		}
		.zyjyzb-content {
			// display: flex;
			width: 100%;
			height: calc(100% - 40px);
			// justify-content: space-between;
			// flex-wrap: wrap;
			.zyjyzb-content-item {
				margin-top: 14px;
				background: url('@/assets/image/home/jywcqk-bg.png') no-repeat;
				background-size: 100% 100%;
				width: 231px;
				height: 139px;
				padding: 20px;
				padding-bottom: 0;
				display: inline-block;
				margin-left: 16px;
				.zyjyzb-content-item-head {
					display: flex;
					align-items: center;
					padding-bottom: 18px;
					border-bottom: 1px solid rgba($color: #dfebf6, $alpha: 0.7);
					justify-content: space-between;
					.name {
						font-family: Alibaba PuHuiTi 2;
						font-weight: normal;
						font-size: 16px;
						color: #15224c;
					}
					.qk {
						font-family: Alibaba PuHuiTi 2;
						font-weight: normal;
						font-size: 14px;
						display: flex;
						align-items: center;
						color: #15224c;
						.icon {
							width: 8px;
							height: 8px;
							background: #d0454d;
							box-shadow: 0px 2px 4px 0px rgba(208, 69, 77, 0.24);
							border-radius: 50%;
							margin-right: 8px;
							&.g {
								background: #0cc41d;
							}
						}
					}
				}
				.zyjyzb-content-item-info {
					.info-item {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-top: 8px;
						.label {
							font-family: Alibaba PuHuiTi 2;
							font-weight: normal;
							font-size: 14px;
							color: #737a94;
						}
						.value {
							font-family: Alibaba PuHuiTi 2;
							font-weight: normal;
							font-size: 18px;
							color: #d0454d;
							.unit {
								font-family: Alibaba PuHuiTi 2;
								font-weight: normal;
								font-size: 14px;
								margin-left: 8px;
							}
							&.g {
								color: #0cc41d;
							}
						}
					}
				}
				// &:nth-child(4n){
				// 	margin-left: 0;
				// }
			}
			.bar-box {
				width: 100%;
				height: 100%;
			}
			.swiper {
				width: 100%;
				height: 100%;
				position: relative;
				overflow: hidden;
				.swiper-item {
					width: 100%;
					height: 100%;
				}
				::v-deep .el-carousel__indicators {
					.el-carousel__indicator {
						.el-carousel__button {
							background: #2591f7;
						}
					}
				}
			}
		}
	}
	.focus-news {
		padding: 8px 24px;
		width: 100%;
		height: 312px;
		display: flex;
		flex-direction: column;
		.show-img {
			margin-top: 16px;
			cursor: pointer;
			width: 100%;
			background: #a9a9a9;
			height: 189px;
			box-shadow: 0px 4px 16px 0px rgba(4, 80, 184, 0.08);
			border-radius: 6px;
			overflow: hidden;
			margin-bottom: 10px;
			position: relative;
			.icon {
				left: 0;
				top: 0;
				position: absolute;
				width: 52px;
				height: 27px;
				background: #2591f7;
				box-shadow: 0px 2px 4px 0px rgba(37, 145, 247, 0.24);
				border-radius: 6px 0px 6px 0px;
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 12px;
				color: #ffffff;
				line-height: 27px;
				text-align: center;
			}
			.show-img-main {
				width: 100%;
				height: 100%;
			}
			.info {
				width: 100%;
				height: 67px;
				position: absolute;
				left: 0;
				bottom: 0;
				background: rgba($color: #15224c, $alpha: 0.54);
				padding: 14px 22px 0;
				.title {
					width: 100%;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #ffffff;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-bottom: 5px;
				}
				.date {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: rgba($color: #fff, $alpha: 0.5);
				}
			}
			&.expir{
				cursor: not-allowed;
				.icon{
					background: #999;
				}
			}
		}
		.focus-news-list {
			flex: 1;
			width: 100%;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			.focus-news-item {
				cursor: pointer;
				flex: 1;
				flex-shrink: 0;
				min-height: 36px;
				max-height: 37px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.title {
					.icon {
						width: 8px;
						height: 8px;
						margin-right: 12px;
					}
					width: 230px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #15224c;
				}
				.date {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #737a94;
				}
				&.expir {
					cursor: not-allowed;
					.title {
						color: #999;
					}
					.date {
						color: #999;
					}
				}
			}
		}
	}
	.info-list {
		width: 100%;
		padding: 0 24px;
		overflow: hidden;
		height: 220px;
		.info-item {
			cursor: pointer;
			display: flex;
			align-items: center;
			height: 20%;
			justify-content: space-between;
			.title {
				.icon {
					width: 8px;
					height: 8px;
					margin-right: 12px;
				}
				width: 230px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #15224c;
			}
			.date {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #737a94;
			}
			&.expir {
					cursor: not-allowed;
					.title {
						color: #999;
					}
					.date {
						color: #999;
					}
				}
		}
	}
	.ctny {
		width: 100%;
		height: 76px;
		padding: 0 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.left {
			.label {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 18px;
				color: #15224c;
				margin-bottom: 10px;
			}
			.num {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #737a94;
			}
		}
		.right {
			display: flex;
			align-items: center;

			.icon {
				width: 30px;
				margin-right: 22px;
				height: 20px;
			}
			.value {
				font-family: Alibaba PuHuiTi 2;
				font-weight: 600;
				font-size: 24px;
				color: #0cc41e;
			}
			.increase {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #0cc41e;
				margin-left: 10px;
			}
			&.red {
				.value {
					color: #ff2400;
				}
				.increase {
					color: #ff2400;
				}
			}
		}
	}
	.download-box {
		width: 100%;
		height: 121px;
		padding: 24px 0;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		.download-item {
			flex: 1;
			flex-shrink: 0;
			min-width: 33.3333%;
			height: 100%;
			font-family: Alibaba PuHuiTi 2;
			font-weight: normal;
			font-size: 14px;
			text-align: center;
			cursor: pointer;
			color: #15224c;
			.icon {
				width: 52px;
				height: 52px;
				margin-bottom: 8px;
				margin: 0 auto;
				display: block;
			}
			&:nth-child(n + 1) {
				border-left: 1px solid rgba($color: #dfebf6, $alpha: 0.7);
			}
		}
	}
}
</style>


