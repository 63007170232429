<!--这个是二级页面，列表页面，所有首页的二级页面都用这个vue文件-->
<template>
	<div class="secondLevel">
		<div class="tab-pages" v-if="showTabs">
			<div
				class="left-btn"
				:class="{ disabled: tabPageNum == 0 }"
				@click="pageChange('last', tabPageNum == 0)"
			>
				<i class="es-icon-pointer-left" />
			</div>
			<div class="tab-content">
				<div class="tab-scroll-box" :style="{ transform: `translateX(-${tabPageNum * 1100}px)` }">
					<div
						class="tab-item"
						:class="{ actived: tabPageActived == index }"
						v-for="(item, index) in tabPageList"
						:key="index"
						@click="onTabPageClick(index)"
					>
						{{ item.name }}
					</div>
				</div>
			</div>
			<div
				class="right-btn"
				:class="{ disabled: tabPageNum == parseInt(tabPageList.length / 10) }"
				@click="pageChange('next', tabPageNum == parseInt(tabPageList.length / 10))"
			>
				<i class="es-icon-pointer-right" />
			</div>
		</div>
		<div class="crumbs">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>
					当前位置：
					<span class="home-btn" @click="backHome">首页</span>
				</el-breadcrumb-item>
				<el-breadcrumb-item class="actived">
					{{ tabPageList[tabPageActived]?.name || currentLocation || '--' }}
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="list-box">
			<div class="list">
				<template v-if="currentLocation == '子公司要闻' || currentLocation == '集团要闻'">
					<div
						class="list-item yw"
						v-for="(item, index) in list"
						:key="index"
						@click="onClick(item)"
						:class="{ expir: itemClass(item) }"
					>
						<div class="left">
							<img
								class="img"
								:src="
									item.adjunctId
										? `${baseUrl}/portal/fileManagement/previewAdjunct.dhtml?adjunctId=${item.adjunctId}`
										: require('@/assets/image/home/default_news_img.png')
								"
								alt=""
							/>
						</div>
						<div class="right">
							<div class="right-info">
								<div class="info-title">
									{{ item.title }}
								</div>
								<div class="info-date">
									{{ item.publishTime.split(' ')[0] }}
								</div>
							</div>
							<div class="info-content">
								{{ item.infoAbstract }}
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div
						class="list-item"
						:class="{ expir: itemClass(item) }"
						v-for="(item, index) in list"
						:key="index"
						@click="onClick(item)"
					>
						<div class="left">
							<div class="icon" />
							{{ item.title }}
						</div>
						<div class="right">
							{{ item.publishTime?.split(' ')[0] || item.date?.split(' ')[0] }}
						</div>
					</div>
				</template>
				<no-data v-if="list.length == 0" />
				<div class="page-box" v-if="showPage">
					<el-pagination
						background
						@size-change="onListSizeChange"
						@current-change="onListPageChange"
						:current-page.sync="listPageNum"
						:page-size="listPageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="listTotal"
						:page-sizes="[10, 20, 30, 40, 50]"
					></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NoData from '../components/NoData';
export default {
	components: { NoData },
	data() {
		return {
			tabPageNum: 0,
			tabPageList: [],
			tabPageActived: 0,
			currentLocation: '',
			listPageNum: 1,
			listPageSize: 10,
			listTotal: 0,
			list: [],
			pathName: '',
			showTabs: false,
			showPage: true,
			baseUrl: window.location.origin + (window.location.hostname == 'localhost' ? '/dev-api' : '')
		};
	},
	created() {
		this.currentLocation = this.$route.meta.name;
		this.pathName = this.$route.name;
		this.tabPageActived = this.$route.query.activedIndex || 0;
		this.getMenu();
	},
	methods: {
		onTabPageClick(index) {
			this.tabPageActived = index;
			this.listPageNum = 1;
			this.listPageSize = 10;
			this.listTotal = 0;
			this.getList();
		},
		pageChange(type, cant) {
			if (cant) return;
			switch (type) {
				case 'next':
					this.tabPageNum++;
					break;
				case 'last':
					this.tabPageNum--;
					break;
				default:
					break;
			}
		},
		onListPageChange(num) {
			this.listPageNum = num;
			this.getList();
		},
		onListSizeChange(size) {
			this.listPageSize = size;
			this.getList();
		},
		//获取二级菜单
		getMenu() {
			switch (this.pathName) {
				//这个是没有页签的 集团要闻、子公司要闻
				case 'groupNews':
					this.showTabs = false;
					this.$.ajax({
						url: this.$url.getColumnIdByCode,
						params: { code: 'gsyw' }
					}).then(res => {
						if (res.status == 'success') {
							this.tabPageList[0] = { id: res.data };
							this.getList();
						}
					});
					break;
				//子公司要闻
				case 'subsidiaryInformation':
					this.showTabs = false;
					this.$.ajax({
						url: this.$url.getColumnIdByCode,
						params: { code: 'agsyw' }
					}).then(res => {
						if (res.status == 'success') {
							this.tabPageList[0] = { id: res.data };
							this.getList();
						}
					});
					break;
				//公告/通知获取有tabs
				case 'announcementNotice':
					this.showTabs = true;
					this.$.ajax({ url: this.$url.getColumnIdByCodes, params: { code: 'gg,tz' } }).then(
						res => {
							if (res.status == 'success') {
								let arr = res.data;
								this.tabPageList = [
									{ name: '公告', id: arr[0] },
									{ name: '通知', id: arr[1] }
								];
								this.getList();
							}
						}
					);
					break;
				//公司文件/制度汇编获取有tabs
				case 'companyDocumentsSystemCompilation':
					this.showTabs = true;
					this.$.ajax({ url: this.$url.getColumnIdByCodes, params: { code: 'gswj,zdhb' } }).then(
						res => {
							if (res.status == 'success') {
								let arr = res.data;
								this.tabPageList = [
									{ name: '公司文件', id: arr[0] },
									{ name: '制度汇编', id: arr[1] }
								];
								this.getList();
							}
						}
					);
					break;
				//综合信息查tabs方式不一样
				case 'integratedInformation':
					this.showTabs = true;
					this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'zhxx' } }).then(res => {
						if (res.status == 'success') {
							this.$.ajax({
								url: this.$url.getColumnList,
								params: { columnId: res.data }
							}).then(res => {
								if (res.status == 'success') {
									this.tabPageList = res.data;
									this.getList();
								}
							});
						}
					});
					break;
				//下载中心也是另一种
				case 'downloadCenter':
					this.showTabs = true;
					this.showPage = false;
					//先拿下载中心tabs
					this.$.ajax({
						url: this.$url.getDownloadTabs,
						params: { code: true, codeType: 'ct' }
					}).then(res => {
						this.tabPageList =
							res[0]?.children.map(e => {
								return { name: e.name, id: e.id };
							}) || [];
						this.getList();
					});
					break;
				default:
					break;
			}
		},
		//拿列表
		getList() {
			let loading = this.$.loading(this.$loading, '加载中...');
			//下载中心不一样
			if (this.pathName == 'downloadCenter') {
				this.$.ajax({
					url: this.$url.getDownloadList,
					params: {
						typeId: this.tabPageList[this.tabPageActived].id
					}
				}).then(res => {
					loading.close();
					this.list = res.data;
				});
				return;
			}
			this.$.ajax({
				url: this.$url.getColumnListJson + `?columnId=${this.tabPageList[this.tabPageActived].id}`,
				data: {
					page: this.listPageNum,
					rows: this.listPageSize
				},
				method: 'POST'
			}).then(res => {
				loading.close();
				// if(res.rCode==0){
				this.list = res.data;
				this.listTotal = res.totalrecords;
				// }
			});
		},
		//面包屑回首页
		backHome() {
			this.$router.push({ path: '/portal/home' });
		},
		onClick(item) {
			if (this.itemClass(item)) {
				this.$message.warning('该内容已结束！');
				return;
			}
			switch (this.pathName) {
				case 'downloadCenter':
					window.open(
						`${this.baseUrl}/oa/fileManagement/downloadByAdjunctId.dhtml?adjunctId=${item.adjunctId}&fileName=${item.originalName}`,
						'_blank'
					);
					break;
				case 'announcementNotice':
					// this.$router.push({name:'detail',query:{id:item.id,name:this.tabPageActived==0?'announcement':'notice',actived:this.tabPageActived}})

					if (item.infoType == 0 && item.infoUrl) {
						window.open(item.infoUrl, '_blank');
					} else {
						var { href } = this.$router.resolve({
							name: 'detail',
							query: {
								id: item.id,
								name: this.tabPageActived == 0 ? 'announcement' : 'notice',
								actived: this.tabPageActived
							}
						});
						window.open(href, '_blank');
					}
					break;
				case 'companyDocumentsSystemCompilation':
					if (item.infoType == 0 && item.infoUrl) {
						window.open(item.infoUrl, '_blank');
					} else {
						var { href } = this.$router.resolve({
							name: 'detail',
							query: {
								id: item.id,
								name: this.tabPageActived == 0 ? 'companyDocuments' : 'systemCompilation',
								actived: this.tabPageActived
							}
						});
						window.open(href, '_blank');
					}
					break;
				default:
					// this.$router.push({name:'detail',query:{id:item.id,name:this.pathName,actived:this.tabPageActived}})

					if (item.infoType !== 0 && item.infoUrl) {
						window.open(item.infoUrl, '_blank');
					} else {
						var { href } = this.$router.resolve({
							name: 'detail',
							query: { id: item.id, name: this.pathName, actived: this.tabPageActived }
						});
						window.open(href, '_blank');
					}
					break;
			}
		},
		//类函数
		itemClass(item) {
			if (item.expirationDate && item.expirationDate.length > 0) {
				let exDate = new Date(item.expirationDate).getDate();
				let now = new Date().getDate();
				return exDate < now;
			} else {
				return false;
			}
		}
	}
};
</script>

<style lang=scss scoped>
.secondLevel {
	width: 100%;
	.tab-pages {
		width: 100%;
		height: 54px;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		.left-btn,
		.right-btn {
			line-height: 54px;
			height: 100%;
			padding: 0 8px;
			color: #737a94;
			cursor: pointer;
			&.disabled {
				cursor: not-allowed;
				color: #b9bdc9;
			}
		}
		.tab-content {
			width: 1100px;
			height: 100%;
			overflow: hidden;
			.tab-scroll-box {
				display: flex;
				height: 100%;
				transition: transform linear 0.7s;
			}
			.tab-item {
				width: 110px;
				height: 100%;
				text-align: center;
				line-height: 54px;
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				cursor: pointer;
				flex-shrink: 0;
				font-size: 16px;
				color: #15224c;
				&.actived {
					color: #2591f7;
					position: relative;
					&::after {
						position: absolute;
						content: '';
						display: block;
						width: 20px;
						height: 2px;
						background: #2591f7;
						bottom: 0;
						left: 45px;
					}
				}
			}
		}
	}
	.crumbs {
		width: 100%;
		height: 66px;
		background: #f4f6fd;
		display: flex;
		align-items: center;
		::v-deep .el-breadcrumb {
			width: 1200px;
			margin: 0 auto;
			.el-breadcrumb__inner {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 16px;
				color: #737a94;
			}
			.el-breadcrumb__separator {
				color: #737a94;
			}
			.actived {
				.el-breadcrumb__inner {
					color: #2591f7;
				}
			}
			.home-btn {
				cursor: pointer;
				// color: #2591f7;
			}
		}
	}
	.list-box {
		width: 100%;
		padding-bottom: 64px;
		background: #f4f6fd;
		.list {
			width: 1200px;
			margin: 0 auto;
			padding: 0 24px;
			background: #ffffff;
			border-radius: 6px;
			.list-item {
				width: 100%;
				padding: 22px 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				cursor: pointer;
				border-bottom: 1px solid rgba($color: #dfebf6, $alpha: 0.7);
				.left {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 16px;
					color: #15224c;
					align-items: center;
					width: 520px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					.icon {
						width: 8px;
						flex-shrink: 0;
						display: inline-block;
						height: 8px;
						background: url('@/assets/image/home/focus-news-actived.png') no-repeat;
						background-size: 100% 100%;
						margin-right: 18px;
					}
				}
				.right {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 16px;
					color: #737a94;
				}
				&.yw {
					.left {
						width: auto;
						.img {
							width: 310px;
							height: 189px;
						}
					}
					.right {
						width: calc(100% - 330px);
						height: 189px;
						.right-info {
							display: flex;
							align-items: center;
							font-weight: 700;
							font-style: normal;
							justify-content: space-between;
							margin-bottom: 8px;
							color: #333;
						}
					}
				}
				&:hover {
					.left {
						color: #2591f7;
					}
					.right {
						color: #2591f7;
						.right-info {
							color: #2591f7;
						}
					}
				}
				&.expir {
					cursor: not-allowed;
					.left {
						// color: #999 !important;
						position: relative;
						&::after{
							content:'已结束';
							position: absolute;
							width: 100%;
							z-index: 1;
							top: 0;
							color: #fff;
							font-size: 26px;
							left: 0;
							display: flex;
							align-items: center;
							justify-content: center;
							height: 100%;
							background: rgba($color: #333, $alpha: 0.7)
						}
					}
					.right {
						color: #999 !important;
						.right-info{
							color: #999 !important;
						}
					}
				}
			}
		}
		.page-box {
			width: 100%;
			height: 80px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
</style>