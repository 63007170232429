import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		subsystemList: [],//子系统
		makePendingCount: 0,// 待办数量
		readPendingCount: 0,//  待阅数量 
		allPendingCount: 0,//  所有待办的数量(保留字段)
		meApplicationCount: 0,//   我的发起的数量
		pendedCount: 0,//  已办的数量
		signCount: 0, //待签公文
		favoriteCount: 0 //我的关注
	},
	getters: {},
	mutations: {
		websocket(state, data) {
			
		},
		updateSystem(state, data) {
			state.subsystemList = data;
		},
		updateCount(state, { name, count }) {
			state[name] = count;
		}
	},
	actions: {
		getCount(store, { ajax, userId }) {
			ajax({
				url: '/bpm/pendingIndex/getIndexCount.dhtml',
				params: {
					userId: userId
				}
			})
				.then(res => {
					if (res.rCode == 0) {
						let { results } = res;
						for (let i in results) {
							store.commit('updateCount', { name: i, count: results[i] })
						}
					}
				})
				.catch(err => { });
			ajax({
				url: '/archives/archives/manage/signCount.dhtml',
				params: {
					userId: userId
				}
			})
				.then(res => {
					if (res.rCode == 0) {
						let { results } = res;
						for (let i in results) {
							store.commit('updateCount', { name: i, count: results[i] })
						}
					}
				})
				.catch(err => { });
		}
	},
	modules: {}
});
