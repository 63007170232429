<template>
	<div class="no-data" :style="{height:`${height}px`}">
		<img class="img" src="@/assets/image/home/img_nodata.png" alt="" />
		<div class="text">暂无数据</div>
	</div>
</template>

<script>
export default {
    props:{
        height:{
            default:310
        }
    }
};
</script>

<style lang=scss scoped>
.no-data {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.img {
		width: 104px;
		height: 88px;
	}
	.text {
		font-family: Alibaba PuHuiTi 2;
		font-weight: normal;
		font-size: 16px;
		color: #737a94;
		margin-top: 24px;
	}
}
</style>