<template>
	<layout :contents="contents"></layout>
</template>
<script>
import Layout from '@/components/Layout.vue';
export default {
	components: { Layout },
	data() {
		return {};
	},
	computed: {
		contents() {
			return [
				{
					type: 'backlog',
					search: true,
					span: 14,
					moreUrl: [
						'/bpm/wfPending/wfPendingMerge/list.dhtml',
						'/bpm/wfPending/wfPendingMerge/list.dhtml',
						'/archives/archives/sign/list.dhtml',
						'/bpm/wfpened/wfPended/list.dhtml',
						'',
						'/bpm/favorite/favoriteRecord/list.dhtml'
					]
				},
				{
					type: 'calendar',
					span: 10
				},
				{
					type: 'application',
					span: 7
				},
				{
					type: 'documents',
					showNew: true,
					span: 7
				},
				{
					type: 'notices',
					span: 10
				}
			];
		}
	},
	created() {},
	mounted() {},
	methods: {}
};
</script>
<style lang="scss" scoped>
::v-deep {
	.es-card .es-card-header .es-header-text {
		font-size: 16px;
	}
}
</style>
