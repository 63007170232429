<template>
	<es-card class="layot-card" header="日程" :shadow="shadow" :border="false" mode="chunk">
		<div slot="suffix" class="calendar-suffix">
			<el-dropdown class="calendar-dropdown" trigger="click" @command="handleCommand">
				<i class="el-icon-caret-bottom"></i>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item :class="{ active: !user }">全部</el-dropdown-item>
					<el-dropdown-item
						v-for="item in listsArr"
						:key="item.userId"
						:class="{ active: user == item.userId }"
						:command="item"
					>
						{{ item.userId == userId ? '我的' : item.userName }}
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<el-button type="primary" round size="mini" @click="handleAdd">新增日程</el-button>
			<el-button type="primary" round size="mini" @click="handleImpower">日程授权</el-button>
			<div class="calendar-handle">
				<i class="calendar-prev es-icon-pointer-left" @click="handlePrev"></i>
				<es-date-picker
					class="calendar-picker"
					v-model="value"
					type="month"
					placeholder="选择日期时间"
					format="yyyy年M月"
					@change="handleChange"
				></es-date-picker>
				<i class="calendar-next es-icon-pointer-right" @click="handleNext"></i>
			</div>
			<el-button type="text" round @click="handleMore">更多</el-button>
		</div>
		<es-calendar
			ref="calendar"
			:show-title="false"
			height="192px"
			@init="handleInit"
			@select="handleSelect"
			@show="handleShow"
		>
			<template slot="mark" slot-scope="{ data }">
				<div class="calendar-mark" v-show="showMark(data)"></div>
			</template>
		</es-calendar>
		<div class="calendar-list-header">
			<div class="calendar-tabs" :class="{ 'show-handle': show }">
				<div class="calendar_tabs" ref="box">
					<ul
						class="calendar_tabs-content"
						ref="types"
						:style="{ width: width + 'px', left: left + 'px' }"
					>
						<li
							class="calendar_tabs-list"
							:class="{ 'is-select': active == '' }"
							@click="handleChangeType('')"
						>
							全部
						</li>
						<li
							class="calendar_tabs-list"
							:class="{ 'is-select': item.value == active }"
							v-for="item in checked"
							@click="handleChangeType(item)"
							:key="item.value"
						>
							{{ item.name }}
						</li>
					</ul>
				</div>
				<div class="calendar-tpye-handle" v-show="show">
					<i class="calendar-tpye-prev es-icon-pointer-left" @click="handlePrevType"></i>
					<i class="calendar-tpye-next es-icon-pointer-right" @click="handleNextType"></i>
				</div>
			</div>
			<el-popover
				class="calendar-list-type"
				placement="bottom-end"
				width="354"
				trigger="click"
				ref="popover"
			>
				<el-button type="primary" round slot="reference" size="mini">类型配置</el-button>
				<es-checkbox-group v-model="checked" :data="type" value-type="object"></es-checkbox-group>
				<div class="calendar-list-btn">
					<el-button type="primary" size="mini" @click="handleSave">保存</el-button>
				</div>
			</el-popover>
		</div>
		<div class="table-box">
			<es-data-table v-bind="table" :height="height"></es-data-table>
		</div>
		<es-dialog
			v-if="visible"
			:width="dialogWidth"
			:height="dialogHeight"
			:title="title"
			:visible.sync="visible"
			:show-scale="false"
			@close="handleClose"
		>
			<iframe v-if="url" frameborder="0" width="100%" height="100%" :src="url"></iframe>
			<!-- <es-form label-width="80px" :model="form" :contents="formList" v-else></es-form> -->
		</es-dialog>
	</es-card>
</template>
<script>
let config = {
	stripe: true,
	theadBorder: false,
	border: 'none'
};
export default {
	name: 'Calendar',
	props: {
		shadow: {
			type: String,
			default: 'never'
		},
		types: {
			type: Array,
			default() {
				return [
					{ name: '会议', value: 1, show: true },
					{ name: '事项', value: 2, show: true },
					{ name: '出差', value: 3, show: true },
					{ name: '请假', value: 4, show: true }
				];
			}
		}
	},
	computed: {},
	watch: {
		types: {
			deep: true,
			handler(val) {
				this.type = val;
				this.init();
			}
		},
		checked: {
			deep: true,
			handler() {
				this.showHandle();
			}
		}
	},
	data() {
		return {
			dialogWidth: '',
			dialogHeight: '',
			title: '',
			visible: false,
			url: '',
			userId: this.$.getStorage('userId'),
			user: '',
			year: '',
			month: '',
			//today: '',
			counts: {},
			value: '',
			type: [],
			active: '',
			show: false,
			boxWidth: '',
			width: '',
			left: 0,
			checked: [],
			table: {
				...config,
				data: [],
				thead: [
					{
						title: '时间',
						align: 'center',
						field: 'startpoint',
						width: '135',
						render: (h, params) => {
							let { row } = params;
							let { startpoint, endpoint } = row;
							return h('span', {}, [`${startpoint} ~ ${endpoint}`]);
						}
					},
					{
						title: '标题',
						field: 'subject',
						showOverflowTooltip: true,
						minWidth: '80',
						render: (h, params) => {
							let {
								row: { subject }
							} = params;
							return h(
								'span',
								{
									class: 'es-pointer',
									on: {
										click: () => {
											this.handleView(row);
										}
									}
								},
								[subject]
							);
						}
					},
					{
						title: '地点',
						align: 'center',
						field: 'place',
						minWidth: '66'
					},
					{
						title: '发起人',
						align: 'center',
						field: 'createUser',
						width: '66'
					},
					{
						title: '来源',
						align: 'center',
						field: 'from',
						width: '66',
						render: (h, params) => {
							let { row } = params;
							let { from } = row;
							return h(
								'span',
								{
									style: {
										color: from == '分享' ? '#38BD55' : from == '授权' ? '#6989D3' : ''
									}
								},
								[from]
							);
						}
					},
					{
						title: '操作',
						type: 'handle',
						length: 1,
						width: '62',
						template: '',
						events: [
							{
								text: '查看',
								rules: data => {
									return data.view;
								},
								event: res => {
									this.handleView(res.row);
								}
							},
							{
								text: '分享',
								selector: true,
								types: ['employee'],
								title: '选择分享人',
								rules: data => {
									return data.share;
								},
								events: {
									confirm: (res, data) => {
										this.handleShare(res, data);
									}
								}
							},
							{
								text: '删除',
								rules: data => {
									return data.del;
								},
								event: res => {
									this.handleDelete(res.row);
								}
							},
							{
								text: '编辑',
								rules: data => {
									return data.edit;
								},
								event: res => {
									this.handleEdit(res.row);
								}
							},
							{
								text: '撤回',
								rules: data => {
									return data.revoke;
								},
								event: res => {
									this.handleRevoke(res.row);
								}
							}
						]
					}
				]
			},
			height: '180px',
			lists: [],
			listsArr: [],
			searchType: -1, //用来判断查询全部 他人 自己
			searchUserId: '', //查询他人的时候 需要的userId
			selectDate: '' //当前选中的日期
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			this.$.ajax({
				url: '/sys/v1/mecpSys/findSysCode.dhtml',
				params: { sysAppCode: 'schedule_data_source_code' }
			})
				.then(res => {
					if (res.rCode == 0) {
						let { results } = res;
						let types = [];
						results.forEach(item => {
							let { shortName, cciValue } = item;
							types.push({
								name: shortName,
								value: cciValue
							});
						});
						this.type = types;
						this.left = 0;
						// this.checked = this.type.filter(item => {
						// 	return item.show;
						// });
						// this.showHandle();
					}
				})
				.catch(err => {});
			this.$.ajax({
				url: '/oa/schedule/scheduleType/getType.dhtml '
			})
				.then(res => {
					if (res.rCode == 0) {
						let { results } = res;
						this.checked = results;
						this.showHandle();
					}
				})
				.catch(err => {});
		},
		handleCommand(res) {
			if (res) {
				this.user = res.userId;
				// this.setData(this.lists);

				//查询自己
				if (res.userId == this.userId) {
					this.searchType = 0;
					this.getCount(`${this.year}-${this.month.toString().padStart(2, '0')}`);
					this.getList(this.selectDate);
				} else {
					//查询他人
					this.searchType = 1;
					this.searchUserId = res.userId;
					this.getCount(`${this.year}-${this.month.toString().padStart(2, '0')}`);
					this.getList(this.selectDate);
				}
			} else {
				this.user = '';
				// this.setData(this.lists);

				//查询全部
				this.searchType = -1;
				this.getCount(`${this.year}-${this.month.toString().padStart(2, '0')}`);
				this.getList(this.selectDate);
			}
		},
		showHandle() {
			this.$nextTick(() => {
				if (this.$refs.box) {
					let { width } = this.$refs.box.getBoundingClientRect();
					this.boxWidth = width;
					let w = 0;
					let lists = this.$refs.types.querySelectorAll('.calendar_tabs-list');
					lists.forEach(ele => {
						w += ele.clientWidth;
					});
					if (w > width) {
						this.show = true;
						this.width = w;
					} else {
						this.show = false;
						this.width = '';
					}
				}
			});
		},
		closeDialog() {
			this.visible = false;
			if (this.user) {
				//查询自己
				if (this.user == this.userId) {
					this.searchType = 0;
					this.getCount(`${this.year}-${this.month.toString().padStart(2, '0')}`);
					this.getList(this.selectDate);
				} else {
					//查询他人
					this.searchType = 1;
					this.searchUserId = this.user;
					this.getCount(`${this.year}-${this.month.toString().padStart(2, '0')}`);
					this.getList(this.selectDate);
				}
			} else {
				//查询全部
				this.searchType = -1;
				this.getCount(`${this.year}-${this.month.toString().padStart(2, '0')}`);
				this.getList(this.selectDate);
			}
		},
		handleAdd() {
			this.title = '新增日程';
			this.url = '/oa/schedule/scheduleRecord/edit.dhtml?operate=add&date=' + this.selectDate;
			this.visible = true;
			window.top.closeDialog = this.closeDialog;
		},
		handleImpower() {
			this.title = '日程授权';
			this.url = '/oa/schedule/scheduleAuth/edit.dhtml?operate=single';
			this.visible = true;
			window.top.closeDialog = this.closeDialog;
		},
		handleInit(res) {
			let { year, month } = res;
			this.year = year;
			this.month = month;
			this.value = `${year}年${month}月`;

			this.getCount(`${year}-${month.toString().padStart(2, '0')}`);
		},
		handlePrev() {
			this.$refs.calendar.prevMonth();
		},
		handleNext() {
			this.$refs.calendar.nextMonth();
		},
		handleChange(res) {
			this.$refs.calendar.handleChange(res);
		},
		handleMore() {
			window.open('/oa/schedule/scheduleRecord/calendar.dhtml');
		},
		handleChangeType(res) {
			if (res) {
				this.active = res.value;
			} else {
				this.active = '';
			}
			this.setData(this.lists);
		},
		handlePrevType() {
			if (this.boxWidth + Math.abs(this.left) < this.width) {
				this.left -= 80;
			}
		},
		handleNextType() {
			if (this.left < 0) {
				this.left += 80;
			}
		},
		handleSave() {
			let params = this.checked.map(item => {
				return {
					typevalue: item.value,
					typename: item.name
				};
			});
			this.$.ajax({
				method: 'post',
				url: '/oa/schedule/scheduleType/save.dhtml ',
				data: { types: JSON.stringify(params) }
			})
				.then(res => {
					this.$refs.popover.hidePopper();
					if (res.rCode == 0) {
						this.$message({
							message: '保存成功！',
							type: 'success'
						});
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch(err => {});
		},
		getCount(month) {
			let params = {
				month
			};
			if (this.searchType != -1) {
				params.type = this.searchType;
				if (this.searchType == 1) {
					params.userId = this.searchUserId;
				}
			}

			this.$.ajax({
				url: '/oa/schedule/scheduleHome/monthSchedule.dhtml',
				params
			})
				.then(res => {
					if (res.rCode == 0) {
						let { results } = res;
						let data = {};
						results.forEach(item => {
							let key = `${item.year}-${item.month}-${item.day}`;
							data[key] = item.count;
						});
						this.counts = data;
					}
				})
				.catch(err => {});
		},
		getList(date) {
			let params = {
				date
			};
			if (this.searchType != -1) {
				params.type = this.searchType;
				if (this.searchType == 1) {
					params.userId = this.searchUserId;
				}
			}
			this.$.ajax({
				url: '/oa/schedule/scheduleHome/getScheduleList.dhtml',
				params: params
			})
				.then(res => {
					if (res.rCode == 0) {
						let { results } = res;
						this.lists = results;
						if (this.searchType == -1) {
							this.listsArr = results;
						}
						this.setData(results);
					}
				})
				.catch(err => {});
		},
		setData(results) {
			let list = [];
			results.forEach(item => {
				if (this.active) {
					let lists = item.scheduleList.filter(item => {
						return item.source == this.active;
					});
					list.push(...lists);
				} else {
					list.push(...item.scheduleList);
				}
			});
			this.$set(this.table, 'data', list);
		},
		showMark(res) {
			let key = `${res.year}-${res.month}-${res.date}`;
			return this.counts[key];
		},
		handleSelect(res) {
			let { year, month, date } = res;
			let today = `${year}-${month.toString().padStart(2, '0')}-${date
				.toString()
				.padStart(2, '0')}`;
			this.getList(today);
			this.selectDate = today;
			// if (this.month != month || this.year != year) {
			// 	this.getCount(`${year}-${month.toString().padStart(2, '0')}`);
			// }
		},
		handleView(res) {
			let { id } = res;
			this.title = '查看日程';
			this.url = `/oa/schedule/scheduleRecord/edit.dhtml?operate=view&id=${id}`;
			this.visible = true;
			window.top.closeDialog = () => {
				this.visible = false;
			};
		},
		handleShare(res, data) {
			let { id } = data.row;
			let shareUserIds = res
				.map(item => {
					return item.showid;
				})
				.join(',');
			let loading = this.$loading({
				fullscreen: true,
				text: '分享中，请稍等...',
				background: 'rgba(0, 0, 0, 0.75)'
			});
			this.$.ajax({
				url: '/oa/schedule/scheduleRecord/shareSave.dhtml',
				params: {
					id: id,
					shareUserIds: shareUserIds
				}
			})
				.then(res => {
					loading.close();
					if (res.rCode == 0 || res.status == 'success') {
						this.$message({
							message: '分享成功！',
							type: 'success'
						});
					} else {
						this.$message.error(res.msg || res.message);
					}
				})
				.catch(err => {
					loading.close();
				});
		},
		handleDelete(res) {
			this.$confirm('确定删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					let loading = this.$loading({
						fullscreen: true,
						text: '删除中，请稍等！',
						background: 'rgba(0, 0, 0, 0.75)'
					});

					this.$.ajax({
						url: '/oa/schedule/scheduleRecord/delete.dhtml',
						params: { id: res.id }
					})
						.then(res => {
							loading.close();
							if (res.rCode == 0 || res.status == 'success') {
								this.$message({
									message: '删除成功！',
									type: 'success'
								});
								this.getCount(`${this.year}-${this.month.toString().padStart(2, '0')}`);
								this.getList(this.selectDate);
							} else {
								this.$message.error(res.msg || res.message);
							}
						})
						.catch(err => {
							loading.close();
						});
				})
				.catch(() => {});
		},
		handleEdit(res) {
			let { id } = res;
			this.title = '编辑日程';
			this.url = `/oa/schedule/scheduleRecord/edit.dhtml?operate=edit&id=${id}`;
			this.visible = true;
			window.top.closeDialog = () => {
				this.visible = false;
			};
		},
		handleRevoke(res) {
			this.$confirm('确定撤回吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					let loading = this.$loading({
						fullscreen: true,
						text: '撤回中，请稍等...',
						background: 'rgba(0, 0, 0, 0.75)'
					});

					this.$.ajax({
						url: res.revokeUrl,
						params: { id: res.id }
					})
						.then(res => {
							loading.close();
							if (res.rCode == 0 || res.status == 'success') {
								this.$message({
									message: '撤回成功！',
									type: 'success'
								});
								this.getCount(`${this.year}-${this.month.toString().padStart(2, '0')}`);
								this.getList(this.selectDate);
							} else {
								this.$message.error(res.msg || res.message);
							}
						})
						.catch(err => {
							loading.close();
						});
				})
				.catch(() => {});
		},
		handleShow(res) {
			if (res) {
				this.height = '180px';
			} else {
				this.height = '340px';
			}
		},
		handleClose() {
			window.top.closeDialog = () => {};
			delete window.top.closeDialog;
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep {
	.es-calendar-weeks {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			left: 16px;
			top: 0;
			right: 16px;
			bottom: 0;
			border-radius: 15px;
			background-color: $--color-primary-light-1;
			z-index: 0;
		}

		.es-week {
			position: relative;
			z-index: 1;
			color: $--color-primary;
		}
	}

	.el-input {
		line-height: 30px;
		width: 80px;
	}

	.el-input__inner {
		font-weight: bold;
		height: 30px;
		line-height: 30px;
		text-align: center;
		padding: 0;
		border: 0 !important;
		box-shadow: none !important;
		cursor: pointer;
	}

	.el-input__prefix,
	.el-input__suffix {
		display: none;
	}

	.es-data-table-content .es-table thead th {
		background-color: #f5f9ff;
	}

	.el-button--mini {
		padding: 4px 8px;
	}
	.es-dropdown-selector {
		.el-button--text {
			color: rgba(0, 0, 0, 0.75);
		}
	}
}

.calendar-suffix {
	display: flex;
	height: 40px;
	padding: 5px 8px;
	flex: 1;

	.calendar-handle {
		display: flex;
		flex: 1;
		justify-content: end;
		margin-right: 12px;

		.calendar-prev,
		.calendar-next {
			cursor: pointer;
			line-height: 30px;
		}
	}

	.calendar-dropdown {
		line-height: 30px;

		.el-icon-caret-bottom {
			line-height: 30px;
			position: relative;
			left: -10px;
			cursor: pointer;
		}
	}
}

.calendar-mark {
	width: 6px;
	height: 6px;
	border-radius: 10px;
	background-color: #ff5d5d;
	position: absolute;
	top: -2px;
	right: -2px;
}

.calendar-list-header {
	//padding-right: 100px;
	position: relative;
	height: 30px;

	.calendar-tabs {
		background-color: #ecf0fa;
		border-radius: 20px;
		position: relative;
		padding-right: 66px;
		&.show-handle {
			padding-right: 102px;
		}

		.calendar_tabs {
			overflow: hidden;

			.calendar_tabs-content {
				position: relative;
				height: 30px;
				.calendar_tabs-list {
					padding: 0 12px;
					min-width: 56px;
					height: 30px;
					line-height: 30px;
					cursor: pointer;
					text-align: center;
					float: left;

					&.is-select {
						border-radius: 20px;
						background-color: $--color-primary;
						color: #fff;
					}
				}
			}
		}

		.calendar-tpye-handle {
			position: absolute;
			right: 66px;
			top: 0;
			line-height: 30px;
			padding: 0 2px;

			.calendar-tpye-prev {
				margin-right: 4px;
				cursor: pointer;
			}

			.calendar-tpye-next {
				cursor: pointer;
			}
		}
	}

	.calendar-list-type {
		position: absolute;
		right: 0;
		top: 0;
	}
}

.calendar-list-btn {
	text-align: right;
	padding-top: 8px;
}
.table-box {
	padding-top: 10px;
}

.el-dropdown-menu__item.active {
	background-color: rgba(0, 0, 0, 0.04);
	color: $--color-primary-light-4;
}
</style>
