<!--
* @desc: 考核完成情况
* @Author: YanZhongYing
* @Date: 2024-06-12
-->
<template>
  <div class="box">
    <div class="flex-c-sb head">
      <div class="box-flex">
        <div
          :class="['tabs-item', ind == activeTab ? 'tabs-active' : '']"
          v-for="(item, ind) of tabs"
          :key="item.ind"
          @click="changeTabs(ind)"
        >
          {{ item.name }}
          <span v-if="item.value" class="tabs-item-top">{{ item.value }}</span>
        </div>
      </div>
      <div class="flex-c">
        <p class="wc">完成考核进度</p>
        <p class="wwc" style="margin: 0 20px">未完成考核进度</p>
        <es-date-picker v-model="date" type="month"> </es-date-picker>
        <!-- <div class="rizhi">
          <i class="es-icon-calendar"></i
          ><span style="margin-left: 6px">1-{{ month }}月</span>
        </div> -->
      </div>
    </div>
    <div class="content">
      <template v-for="(data, ind) of lists">
        <div :key="ind" v-if="ind == activeTab && show" class="wh100">
          <!-- 营业收入、利润总额 -->
          <div class="wh100" v-if="activeTab == 0 || activeTab == 1">
            <template v-for="(item, i) of data">
              <topLableBars
                v-if="item.length > 0"
                class="bars"
                :key="i"
                :id="'bar' + i"
                :data="item"
              ></topLableBars>
              <el-empty :key="i" v-else description="暂无数据"></el-empty>
            </template>
          </div>
          <!-- 盈利企业、控亏企业 -->
          <div class="wh100" v-else>
            <el-carousel
              v-if="data.data.length > 0"
              height="calc(100% - 40px)"
              indicator-position="outside"
            >
              <el-carousel-item v-for="i in data.page" :key="i">
                <template
                  v-for="(item, k) of data.data.slice(9 * (i - 1), 9 * i)"
                >
                  <div :key="k" class="c-item flex-c">
                    <div class="c-item-title">{{ item.name }}</div>
                    <div class="flex-sa" style="width: 100%">
                      <div class="flex-fc-c">
                        <span
                          :class="[
                            'c-item-value',
                            item.operaingIncomeType == '完成' ? 'wc' : 'wwc',
                          ]"
                        >
                          {{ item.operaingIncome }}
                        </span>
                        <span>营业收入</span>
                      </div>
                      <div class="flex-fc-c">
                        <span
                          :class="[
                            'c-item-value',
                            item.totalProfitType == '完成' ? 'wc' : 'wwc',
                          ]"
                        >
                          {{ item.totalProfit }}
                        </span>
                        <span>利润总额</span>
                      </div>
                      <div class="flex-fc-c">
                        <span
                          :class="['yl', activeTab == 3 ? 'kk' : '']"
                        ></span>
                        <span>{{ activeTab == 2 ? "盈利" : "控亏" }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </el-carousel-item>
            </el-carousel>
            <el-empty v-else description="暂无数据"></el-empty>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Mixins from "./mixins/mixins";
import { operateAnalysis, operateShow } from "@/http/operateAnalysisShow";
import topLableBars from "./components/topLableBars.vue";

export default {
  components: {
    topLableBars,
  },
  mixins: [Mixins],
  data() {
    return {
      activeTab: 0,
      month: new Date().getMonth() + 1,
      barData1: [[], []],
      barData2: [[], []],
      carouselData1: [],
      carouselData2: [],
      show: false,
    };
  },
  computed: {
    tabs() {
      return [
        {
          name: "营业收入",
          type: 1,
        },
        {
          name: "利润总额",
          type: 2,
        },
        {
          name: "盈利企业",
          value: this.carouselData1.length,
          type: 3,
        },
        {
          name: "控亏企业",
          value: this.carouselData2.length,
          type: 4,
        },
      ];
    },
    lists() {
      return [
        this.barData1,
        this.barData2,
        {
          data: this.carouselData1,
          page: Math.ceil(this.carouselData1.length / 9),
        },
        {
          data: this.carouselData2,
          page: Math.ceil(this.carouselData2.length / 9),
        },
      ];
    },
  },
  watch: {
    date() {
      this.getData();
    },
  },
  mounted() {
    // this.getData();
  },
  methods: {
    /**
     * @desc: 获取数据
     **/
    getData() {
      this.show = false;
      const loading = this.$.loading(this.$loading, "加载中");
      // 获取营业收入
      this.$.ajax({
        url: operateShow.rankingIncome,
        params: { dateString: this.date, type: 1 },
      })
        .then((res) => {
          loading.close();
          const { rCode, results } = res;
          if (rCode == 0) {
            let data = results.map((i) => ({
              name: i.companyName,
              value: i.process,
              type: i.condition,
            }));
            this.barData1[0] = data.slice(0, Math.ceil(data.length / 2));
            this.barData1[1] = data.slice(
              Math.ceil(data.length / 2),
              data.length
            );

            setTimeout(() => {
              this.show = true;
            }, 0);
          }
        })
        .catch((error) => {
          loading.close();
        });
      // 获取利润总额
      this.$.ajax({
        url: operateShow.rankingProfits,
        params: { dateString: this.date, type: 1 },
      })
        .then((res) => {
          loading.close();
          const { rCode, results } = res;
          if (rCode == 0) {
            let data = results.map((i) => ({
              name: i.companyName,
              value: i.process,
              type: i.condition,
            }));
            this.barData2[0] = data.slice(0, Math.ceil(data.length / 2));
            this.barData2[1] = data.slice(
              Math.ceil(data.length / 2),
              data.length
            );
          }
        })
        .catch((error) => {
          loading.close();
        });
      // 盈利企业、控亏企业
      this.$.ajax({
        url: operateAnalysis.completionInCome,
        params: { dateString: this.date },
      })
        .then((res) => {
          loading.close();
          const { rCode, results } = res;
          if (rCode == 0) {
            this.carouselData1 = results.profit;
            this.carouselData2 = results.profitLoss;
          }
        })
        .catch((error) => {
          loading.close();
        });
    },

    changeTabs(type) {
      this.activeTab = type;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  // padding: 16px 26px;
  font-size: 17px;
  background: #f6f6f6;
  overflow: auto;
  .head {
    padding: 0 26px;
    background: #fff;
    height: 54px;
  }
  .tabs-item {
    height: 30px;
    line-height: 28px;
    padding: 0 25px;
    color: #333;
    background: linear-gradient(#e4eef9, #fff);
    border-radius: 15px;
    margin-right: 20px;
    border: 1px solid #a3c8ef;
    position: relative;
    cursor: pointer;
    &-top {
      position: absolute;
      right: -10px;
      top: -10px;
      height: 26px;
      width: 26px;
      border-radius: 50%;
      text-align: center;
      color: #fff;
      line-height: 26px;
      font-size: 16px;
      background: linear-gradient(#ef3b35, #c71c1c);
    }
  }
  .tabs-active {
    background: linear-gradient(#5eaeea, #327ad4);
    color: #fff;
  }
  .wc {
    color: #c61b1b;
  }
  .wwc {
    color: #7ed321;
  }
  .es-date-picker {
    width: 130px;
  }
  .rizhi {
    height: 30px;
    line-height: 28px;
    border-radius: 15px;
    padding: 0 20px 0 10px;
    border: 1px solid #ccc;
  }
  .content {
    height: calc(100% - 86px);
    margin: 16px;
    background: #fff;

    .bars {
      width: 100%;
      height: 50%;
      min-height: 300px;
      min-width: 1200px;
    }
    // 盈利企业、控亏企业
    ::v-deep .el-carousel {
      height: 100%;
      .el-carousel__item {
        display: flex;
        flex-wrap: wrap;
        align-content: start;
      }
    }

    .c-item {
      width: calc(33% - 10px);
      height: calc(33% - 40px);
      border: 1px solid #b9d8ff;
      border-radius: 5px;
      position: relative;
      background: #f1f6fc;
      margin: 20px 0;
      &:not(:nth-child(3n)) {
        margin-right: 20px;
      }
      &-title {
        position: absolute;
        width: 150px;
        height: 50px;
        line-height: 50px;
        border-radius: 5px;
        overflow: hidden;
        top: -15px;
        background: url("@/assets/image/operateAnalysisShow/title_blue.png")
          center no-repeat;
        background-size: 100% 100%;
        font-size: 20px;
        color: #fff;
        text-align: center;
      }
      &-value {
        font-size: 26px;
        line-height: 40px;
      }
      .yl {
        width: 16px;
        height: 16px;
        margin: 12px 0;
        border-radius: 50%;
        line-height: 40px;
        background-color: #c61b1b;
      }
      .kk {
        background-color: #7ed321;
      }
    }
  }
}
</style>
@/http/operateAnalysisShow