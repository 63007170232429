<template>
	<es-error-page :type="type"></es-error-page>
</template>

<script>
export default {
	data() {
		return {
			type: 0
		};
	},
	watch: {
		'$route.query': {
			immediate: true,
			handler(val) {
				this.type = val.type ? val.type : 0;
			}
		}
	},
	mounted() {}
};
</script>
