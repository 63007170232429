<template>
	<div :id="id" class="echart"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
	name: 'Pies',
	data() {
		return {
			firstLoad: true
		};
	},
	props: {
		id: {
			type: String
		},
		data: {
			type: Array,
			default: () => {
				return [];
			}
		},
		legendTitle: {
			type: String
		}
	},
	watch: {
		data: {
			handler(val) {
				this.$nextTick(() => {
					this.drawBar(this.id, val);
				});
			},
			deep: true
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.drawBar(this.id, this.data);
		});
	},

	methods: {
		drawBar(id, res) {
			if (this.chart) {
				this.chart.dispose();
			}
			let data = JSON.parse(JSON.stringify(res));
			let dom = document.getElementById(id);
			if (!dom || data.length == 0) {
				return;
			}
			this.chart = echarts.init(dom);

			let color = [];
			[
				['#FC9264', '#ED5F5A'],
				['#FDB000', '#FFE06F'],
				['#D0E261', '#8FD267'],
				['#69C0FC', '#009FFF'],
				['#8392FE', '#CF98FE'],
				['#88E2FB', '#3CBDF6']
			].forEach(ele => {
				color.push(
					new echarts.graphic.LinearGradient(
						0,
						1,
						0,
						0,
						[
							{ offset: 0, color: ele[0] },
							{ offset: 1, color: ele[1] }
						],
						false
					)
				);
			});

			let legendList = [];
			this.data.forEach((i, key) => {
				if (key == 0) {
					i.selected = true;
				}
				data[key].name = i.companyName;
				data[key].value = Math.abs(i.chartProcess);
				legendList.push(i.companyName);
			});

			let objData = this.array2obj(data, 'name');

			let option = {
				color: color,
				legend: {
					type: 'scroll',
					// height: 320,
					orient: 'vertical',
					top: 300,
					data: legendList,
					itemWidth: 12,
					itemHeight: 12,
					itemGap: 20,
					formatter: name => {
						let data = '';
						if (name.length > 13) {
							// data = name.replace(
							//   name.substring(0, 15),
							//   name.substring(0, 15) + "\n"
							// );
							data = name.substring(0, 13) + '...';
						} else {
							data = name;
						}

						return (
							'{title|' +
							data +
							'  ' +
							'}{remark|' +
							this.legendTitle +
							'：' +
							'} {value|' +
							objData[name].currentLoss +
							'}'
						);
					},
					textStyle: {
						fontSize: 15,
						rich: {
							title: {
								color: '#999',
								width: 220,
								fontSize: 15
							}
						}
					}
				},
				tooltip: {
					show: true,
					trigger: 'item',
					confine: true,
					formatter: res => {
						if (!res.data.children) return;
						let str = '';
						res.data.children.forEach(e => {
							let txtString = e.companyName;
							let name = '';
							for (var i = 0, len = txtString.length; i < len; i++) {
								if (i % 12 === 0 && i != 0) name += '<br/>';
								name += txtString[i];
							}
							str += ` <div style='display:flex;align-items:center;justify-content:space-between;padding:8px 0'> <div style='width:220px;color:#999;font-size:15px;display:inline-block;text-align:left'>${name}</div>  <div style='padding-left:40px;font-size:15px;flex:1'>${
								this.legendTitle
							}：${(e.currentLoss - 0).toLocaleString()}</div></div>`;
						});
						return str;
					}
				},
				series: [
					{
						type: 'pie',
						center: ['50%', '22%'],
						radius: ['25%', '40%'],
						clockwise: false, //饼图的扇区是否是顺时针排布
						avoidLabelOverlap: false,
						itemStyle: {
							//图形样式
							normal: {
								borderColor: '#ffffff',
								borderWidth: 4
							}
						},
						label: {
							normal: {
								// show:false,
								formatter: val => {
									return `{b|${val.name}}\n{hr|}\n{d|${val.data.process}}`;
								},

								rich: {
									b: {
										color: '#999',
										padding: 4,
										fontSize: 14
									},
									hr: {
										borderColor: '#999',
										width: '100%',
										borderWidth: 1,
										height: 0
									},
									d: {
										padding: 4,
										fontSize: 14
									}
								}
							}
						},
						labelLine: {
							normal: {
								length: 20,
								length2: 20,
								lineStyle: {
									color: '#999',
									width: 1
								}
							}
						},

						data: data
					}
				]
			};
			this.chart.setOption(option);
			// if (this.firstLoad) {
			// 	this.firstLoad = false;
			// 	this.drawBar(id, res);
			// }
			window.addEventListener('resize', () => {
				this.chart && this.chart.resize();
			});
		},
		array2obj(array, key) {
			var resObj = {};
			for (var i = 0; i < array.length; i++) {
				resObj[array[i][key]] = array[i];
			}
			return resObj;
		}
	}
};
</script>

<style lang="scss" scoped>
.echart {
	width: 100%;
	height: 100%;
}
</style>
