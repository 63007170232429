<!--这个是二级页面，列表页面，所有非首页的二级页面都用这个vue文件-->
<template>
	<div class="secondLevel">
		<div class="tab-pages">
			<div
				class="left-btn"
				:class="{ disabled: tabPageNum == 0 }"
				@click="pageChange('last', tabPageNum == 0)"
			>
				<i class="es-icon-pointer-left" />
			</div>
			<div class="tab-content">
				<div class="tab-scroll-box" :style="{ transform: `translateX(-${tabPageNum * 1100}px)` }">
					<div
						class="tab-item"
						:class="{ actived: tabPageActived == index }"
						v-for="(item, index) in tabPageList"
						:key="index"
						@click="onTabPageClick(index)"
					>
						{{ item.name }}
					</div>
				</div>
			</div>
			<div
				class="right-btn"
				:class="{ disabled: tabPageNum == parseInt(tabPageList.length / 10) }"
				@click="pageChange('next', tabPageNum == parseInt(tabPageList.length / 10))"
			>
				<i class="es-icon-pointer-right" />
			</div>
		</div>
		<div class="crumbs">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>当前位置：{{ currentLocation }}</el-breadcrumb-item>
				<el-breadcrumb-item class="actived">
					{{ tabPageList[tabPageActived]?.name || '--' }}
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="list-box">
			<div class="list">
				<div
					class="list-item"
					v-for="(item, index) in list"
					:key="index"
					:class="{ expir: itemClass(item) }"
					@click="onClick(item)"
				>
					<div class="left">
						<div class="icon" />
						{{ item.title }}
					</div>
					<div class="right">{{ item.publishTime.split(' ')[0] }}</div>
				</div>
				<no-data v-if="list.length == 0" />
				<div class="page-box">
					<el-pagination
						background
						@size-change="onListSizeChange"
						@current-change="onListPageChange"
						:current-page.sync="listPageNum"
						:page-size="listPageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="listTotal"
						:page-sizes="[10, 20, 30, 40, 50]"
					></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NoData from '../components/NoData';
export default {
	components: { NoData },
	data() {
		return {
			tabPageNum: 0,
			tabPageList: [],
			tabPageActived: 0,
			currentLocation: '',
			listPageNum: 1,
			listPageSize: 10,
			listTotal: 0,
			list: [],
			baseUrl: window.location.origin + (window.location.hostname == 'localhost' ? '/dev-api' : ''),
			pathName: ''
		};
	},
	created() {
		this.currentLocation = this.$route.meta.name;
		this.pathName = this.$route.name;
		this.tabPageActived = this.$route.query.activedIndex || 0;
		this.getMenu();
	},
	methods: {
		onTabPageClick(index) {
			this.tabPageActived = index;
			this.listPageNum = 1;
			this.listPageSize = 10;
			this.listTotal = 0;
			this.getList();
		},
		pageChange(type, cant) {
			if (cant) return;
			switch (type) {
				case 'next':
					this.tabPageNum++;
					break;
				case 'last':
					this.tabPageNum--;
					break;
				default:
					break;
			}
		},
		onListPageChange(num) {
			this.listPageNum = num;
			this.getList();
		},
		onListSizeChange(size) {
			this.listPageSize = size;
			this.getList();
		},
		//获取二级菜单
		getMenu() {
			this.$.ajax({
				url: this.$url.getColumnList,
				params: { columnId: this.$route.query.pathId }
			}).then(res => {
				if (res.status == 'success') {
					this.tabPageList = res.data;
					this.getList();
				}
			});
		},
		//拿列表
		getList() {
			let loading = this.$.loading(this.$loading, '加载中...');
			this.$.ajax({
				url: this.$url.getColumnListJson + `?columnId=${this.tabPageList[this.tabPageActived].id}`,
				data: {
					page: this.listPageNum,
					rows: this.listPageSize
				},
				method: 'POST'
			}).then(res => {
				loading.close();
				// if (res.rCode == 0) {
				this.list = res.data;
				this.listTotal = res.totalrecords;
				// }
			});
		},
		onClick(item) {
			//跳转类型的
			if (this.itemClass(item)) {
				this.$message.warning('该内容已结束！');
				return;
			}
			if (item.infoType !== 0 && item.infoUrl) {
				window.open(item.infoUrl, '_blank');
			} else {
				const { href } = this.$router.resolve({
					name: 'detail',
					query: {
						id: item.id,
						name: this.pathName,
						actived: this.tabPageActived,
						pathName: this.tabPageList[this.tabPageActived].name,
						pathId: this.$route.query.pathId
					}
				});
				window.open(href, '_blank');
			}
			// this.$router.push({
			// 	name: 'detail',
			// 	query: {
			// 		id: item.id,
			// 		name: this.pathName,
			// 		actived: this.tabPageActived,
			// 		pathName: this.tabPageList[this.tabPageActived].name,
			// 		pathId: this.$route.query.pathId
			// 	}
			// });
		},
		//类函数
		itemClass(item) {
			if (item.expirationDate && item.expirationDate.length > 0) {
				let exDate = new Date(item.expirationDate).getDate();
				let now = new Date().getDate();
				return exDate < now;
			} else {
				return false;
			}
		}
	}
};
</script>

<style lang=scss scoped>
.secondLevel {
	width: 100%;
	.tab-pages {
		width: 100%;
		height: 54px;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		.left-btn,
		.right-btn {
			line-height: 54px;
			height: 100%;
			padding: 0 8px;
			color: #737a94;
			cursor: pointer;
			&.disabled {
				cursor: not-allowed;
				color: #b9bdc9;
			}
		}
		.tab-content {
			width: 1100px;
			height: 100%;
			overflow: hidden;
			.tab-scroll-box {
				display: flex;
				height: 100%;
				transition: transform linear 0.7s;
			}
			.tab-item {
				width: 110px;
				height: 100%;
				text-align: center;
				line-height: 54px;
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				cursor: pointer;
				flex-shrink: 0;
				font-size: 16px;
				color: #15224c;
				&.actived {
					color: #2591f7;
					position: relative;
					&::after {
						position: absolute;
						content: '';
						display: block;
						width: 20px;
						height: 2px;
						background: #2591f7;
						bottom: 0;
						left: 45px;
					}
				}
			}
		}
	}
	.crumbs {
		width: 100%;
		height: 66px;
		background: #f4f6fd;
		display: flex;
		align-items: center;
		::v-deep .el-breadcrumb {
			width: 1200px;
			margin: 0 auto;
			.el-breadcrumb__inner {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 16px;
				color: #737a94;
			}
			.el-breadcrumb__separator {
				color: #737a94;
			}
			.actived {
				.el-breadcrumb__inner {
					color: #2591f7;
				}
			}
		}
	}
	.list-box {
		width: 100%;
		padding-bottom: 64px;
		background: #f4f6fd;
		.list {
			width: 1200px;
			margin: 0 auto;
			padding: 0 24px;
			background: #ffffff;
			border-radius: 6px;
			.list-item {
				width: 100%;
				padding: 22px 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				cursor: pointer;
				border-bottom: 1px solid rgba($color: #dfebf6, $alpha: 0.7);
				.left {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 16px;
					color: #15224c;
					align-items: center;
					width: 520px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					.icon {
						width: 8px;
						display: inline-block;
						height: 8px;
						flex-shrink: 0;
						background: url('@/assets/image/home/focus-news-actived.png') no-repeat;
						background-size: 100% 100%;
						margin-right: 18px;
					}
				}
				.right {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 16px;
					color: #737a94;
				}
				&:hover {
					.left {
						color: #2591f7;
					}
					.right {
						color: #2591f7;
					}
				}
				&.expir {
					cursor: not-allowed;
					.left {
						color: #999 !important;
						.icon{
						background: url('@/assets/image/home/focus-news.png') no-repeat;
						background-size: 100% 100%;
						}
					}
					.right {
						color: #999 !important;
						.right-info {
							color: #999 !important;
						}
					}
				}
			}
		}
		.page-box {
			width: 100%;
			height: 80px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
</style>