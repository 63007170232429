<!--集团领导看的首页-->
<template>
	<div class="background">
		<div class="content">
			<card title="经营完成情况（完成预算进度）" width="792">
				<div class="title1">
					<div class="label">
						<img class="icon" src="@/assets/image/home/title-icon1.png" alt="" />
						绝对值指标
						<span class="unit">(单位：亿元)</span>
					</div>
					<div class="date">
						截至日期：
						{{ showDate1 }}
						<es-date-picker
							v-model="date1"
							type="month"
							placeholder="选择截至月份"
							width="50"
							@change="onDateChange"
						/>
					</div>
				</div>
				<div class="jdzzb-list">
					<div class="jdzzb-item" v-for="(item, index) in jdzzbList" :key="index">
						<div class="label">{{ item.name }}</div>
						<div class="value">{{ item.value }}</div>
						<div
							class="increase"
							:class="{
								up: item.percentage && item.percentage - 0 > 0,
								down: item.percentage && item.percentage - 0 < 0
							}"
						>
							{{ item.percentage + '%' }}
						</div>
						<div class="percentage" v-if="item.budgetCompletionRate">
							<div class="label">预计完成率</div>
							<div class="value">{{ item.budgetCompletionRate + '%' }}</div>
						</div>
					</div>
				</div>
				<div class="title1">
					<div class="label">
						<img class="icon" src="@/assets/image/home/title-icon1.png" alt="" />
						比率类指标
					</div>
				</div>
				<div class="bllzb-list">
					<div class="bllzb-item" v-for="(item, index) in bllzbList" :key="index">
						<div class="label">{{ item.name }}</div>
						<div class="value">
							<div class="value-left">
								{{ item.value }}
								<span class="unit">{{ index == bllzbList.length - 1 ? '万元' : '%' }}</span>
							</div>
							<div
								class="value-right"
								:class="{
									up: item.percentage && item.percentage - 0 > 0,
									down: item.percentage && item.percentage - 0 < 0
								}"
							>
								{{ item.percentage + (index == bllzbList.length - 1 ? '万元/人' : '个百分点') }}
							</div>
						</div>
					</div>
				</div>
			</card>
			<div class="bb">
				<card title="集团要闻" :more="true" width="384" @onMore="onMore('groupNews')">
					<div class="focus-news">
						<div
							class="show-img"
							v-if="jtywList[0] && jtywList[0].adjunctId && jtywList[0].adjunctId.length > 0"
							@click="toDetail(jtywList[0], 'groupNews')"
							:class="{ expir: itemClass(jtywList[0]) }"
						>
							<div class="icon">{{ itemClass(jtywList[0]) ? '已结束' : 'NEW' }}</div>
							<img
								class="show-img-main"
								:src="`${baseUrl}/portal/fileManagement/previewAdjunct.dhtml?adjunctId=${jtywList[0].adjunctId}`"
								alt=""
							/>
							<div class="info">
								<div class="title">{{ jtywList[0].title }}</div>
								<div class="date">{{ jtywList[0].publishTime.split(' ')[0] }}</div>
							</div>
						</div>
						<div class="focus-news-list" v-if="jtywList.length > 0">
							<div
								class="focus-news-item"
								:class="{ expir: itemClass(item) }"
								v-for="item in jtywList"
								:key="item.id"
								@click="toDetail(item, 'groupNews')"
							>
								<div class="title">
									<img
										class="icon"
										:src="
											!itemClass(item)
												? require('@/assets/image/home/focus-news-actived.png')
												: require('@/assets/image/home/focus-news.png')
										"
										alt=""
									/>
									{{ item.title }}
								</div>
								<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
							</div>
						</div>
						<no-data v-if="jtywList.length == 0" />
					</div>
				</card>
				<card title="子公司要闻" :more="true" width="384" @onMore="onMore('subsidiaryInformation')">
					<div class="focus-news">
						<div
							class="show-img"
							v-if="zgsywList[0] && zgsywList[0].adjunctId && zgsywList[0].adjunctId.length > 0"
							@click="toDetail(zgsywList[0], 'subsidiaryInformation')"
							:class="{ expir: itemClass(zgsywList[0]) }"
						>
							<div class="icon">{{ itemClass(zgsywList[0]) ? '已结束' : 'NEW' }}</div>
							<img
								class="show-img-main"
								:src="`${baseUrl}/portal/fileManagement/previewAdjunct.dhtml?adjunctId=${zgsywList[0].adjunctId}`"
								alt=""
							/>
							<div class="info">
								<div class="title">{{ zgsywList[0].title }}</div>
								<div class="date">{{ zgsywList[0].publishTime.split(' ')[0] }}</div>
							</div>
						</div>
						<div class="focus-news-list" v-if="zgsywList.length > 0">
							<div
								class="focus-news-item"
								v-for="item in zgsywList"
								:class="{ expir: itemClass(item) }"
								:key="item.id"
								@click="toDetail(item, 'subsidiaryInformation')"
							>
								<div class="title">
									<img
										class="icon"
										:src="
											!itemClass(item)
												? require('@/assets/image/home/focus-news-actived.png')
												: require('@/assets/image/home/focus-news.png')
										"
										alt=""
									/>
									{{ item.title }}
								</div>
								<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
							</div>
						</div>
						<no-data v-if="zgsywList.length == 0" />
					</div>
				</card>
			</div>
			<card
				class="mt"
				width="384"
				:headTab="true"
				:headTabList="['公告', '通知']"
				:more="true"
				@onMore="onMore('announcementNotice')"
			>
				<template #0>
					<div class="info-list" v-if="ggList.length > 0">
						<div
							class="info-item"
							v-for="item in ggList"
							:class="{ expir: itemClass(item) }"
							:key="item.id"
							@click="toDetail(item, 'announcement', 0)"
						>
							<div class="title">
								<img
									class="icon"
									:src="
										!itemClass(item)
											? require('@/assets/image/home/focus-news-actived.png')
											: require('@/assets/image/home/focus-news.png')
									"
									alt=""
								/>
								{{ item.title }}
							</div>
							<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
						</div>
					</div>
					<no-data v-if="ggList.length == 0" height="220" />
				</template>
				<template #1>
					<div class="info-list" v-if="tzList.length > 0">
						<div
							class="info-item"
							v-for="item in tzList"
							:key="item.id"
							:class="{ expir: itemClass(item) }"
							@click="toDetail(item, 'notice', 1)"
						>
							<div class="title">
								<img
									class="icon"
									:src="
										!itemClass(item)
											? require('@/assets/image/home/focus-news-actived.png')
											: require('@/assets/image/home/focus-news.png')
									"
									alt=""
								/>
								{{ item.title }}
							</div>
							<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
						</div>
					</div>
					<no-data v-if="tzList.length == 0" height="220" />
				</template>
			</card>
			<card
				class="mt"
				title="综合信息"
				:more="true"
				width="384"
				@onMore="onMore('integratedInformation')"
			>
				<div class="info-list" v-if="zhxxList.length > 0">
					<div
						class="info-item"
						v-for="item in zhxxList"
						:key="item.id"
						:class="{ expir: itemClass(item) }"
						@click="toDetail(item, 'integratedInformation')"
					>
						<div class="title">
							<img
								class="icon"
								:src="
									!itemClass(item)
										? require('@/assets/image/home/focus-news-actived.png')
										: require('@/assets/image/home/focus-news.png')
								"
								alt=""
							/>
							{{ item.title }}
						</div>
						<div class="date">{{ item.publishTime.split(' ')[0] }}</div>
					</div>
				</div>

				<no-data v-if="zhxxList.length == 0" height="220" />
			</card>
			<div class="bb mt">
				<card width="384">
					<div class="ctny">
						<div class="left">
							<div class="label">{{ shareData.shareCompanyName }}</div>
							<div class="num">{{ shareData.shareCode }}</div>
						</div>
						<div class="right" :class="{ red: shareData.increase && shareData.increase > 0 }">
							<img
								v-if="shareData.increase && shareData.increase > 0"
								class="icon"
								src="@/assets/image/home/stock.png"
								alt=""
							/>
							<img v-else class="icon" src="@/assets/image/home/fall.png" alt="" />
							<div class="value">{{ shareData.currentPrice }}</div>
							<div class="increase">{{ `(${shareData.increase || 0}%)` }}</div>
						</div>
					</div>
				</card>
				<card title="下载中心" :more="true" width="384" @onMore="onMore('downloadCenter')">
					<div class="download-box">
						<div
							class="download-item"
							v-for="(item, index) in downloadList"
							@click="onMore('downloadCenter', index)"
							:key="item.id"
						>
							<img
								class="icon"
								:src="
									[
										require('@/assets/image/home/wenjian.png'),
										require('@/assets/image/home/ruanjian.png'),
										require('@/assets/image/home/zhidu.png')
									][index]
								"
								alt=""
							/>
							{{ item.name }}
						</div>
					</div>
				</card>
			</div>
		</div>
	</div>
</template>

<script>
import Card from '../components/Card.vue';
import NoData from '../components/NoData';
export default {
	components: { Card, NoData },
	data() {
		return {
			date1: '',
			jdzzbList: [],
			bllzbList: [],
			jtywList: [],
			zgsywList: [],
			tzList: [],
			ggList: [],
			zhxxList: [],
			baseUrl: window.location.origin + (window.location.hostname == 'localhost' ? '/dev-api' : ''),
			downloadList: [],
			shareData: {}
		};
	},
	computed: {
		showDate1() {
			let lastDay = new Date(this.date1.split('-')[0], this.date1.split('-')[1], 0).getDate();
			return this.date1 + '-' + lastDay;
		}
	},
	mounted() {
		this.getFinaceDate();
		this.getColumnIdByCode();
		this.getDownloadTabsFun();
		this.getShareData();
	},
	methods: {
		//财务接口-刘珂良
		getFinaceDate() {
			//先拿时间
			this.$.ajax({ url: this.$url.getRecentDate }).then(res => {
				if (res.rCode == 0) {
					let dateString = res.results.date;
					this.date1 = dateString;
					this.getFinaceData(dateString);
				}
			});
		},
		//拿数据
		getFinaceData(dateString) {
			let loading = this.$.loading(this.$loading, '加载中...');
			this.$.ajax({
				url: this.$url.mainOperatingIndicators,
				params: { dateString }
			}).then(res => {
				loading.close();
				if (res.rCode == 0) {
					this.jdzzbList = res.results[0].data;
					this.bllzbList = res.results[1].data;
				}
			});
		},
		//切换时间
		onDateChange(val) {
			this.getFinaceData(val);
		},
		//先拿目录id再用目录id去查数据
		getColumnIdByCode() {
			//集团要闻
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'gsyw' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnListJson + `?columnId=${res.data}`,
						data: {
							page: 1,
							rows: 10
						},
						method: 'POST'
					}).then(res => {
						this.jtywList = res.data;
					});
				}
			});
			//子公司要闻
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'agsyw' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnListJson + `?columnId=${res.data}`,
						data: {
							page: 1,
							rows: 10
						},
						method: 'POST'
					}).then(res => {
						this.zgsywList = res.data;
					});
				}
			});
			//公告
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'gg' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnListJson + `?columnId=${res.data}`,
						data: {
							page: 1,
							rows: 10
						},
						method: 'POST'
					}).then(res => {
						this.ggList = res.data;
					});
				}
			});
			//通知
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'tz' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnListJson + `?columnId=${res.data}`,
						data: {
							page: 1,
							rows: 10
						},
						method: 'POST'
					}).then(res => {
						this.tzList = res.data;
					});
				}
			});
			//综合信息
			this.$.ajax({ url: this.$url.getColumnIdByCode, params: { code: 'zhxx' } }).then(res => {
				if (res.status == 'success') {
					this.$.ajax({
						url: this.$url.getColumnList,
						params: { columnId: res.data }
					}).then(res => {
						if (res.status == 'success') {
							let columnId = res.data[0].id;
							this.$.ajax({
								url: this.$url.getColumnListJson + `?columnId=${columnId}`,
								data: {
									page: 1,
									rows: 10
								},
								method: 'POST'
							}).then(res => {
								this.zhxxList = res.data;
							});
						}
					});
				}
			});
		},
		//拿下载中心
		getDownloadTabsFun() {
			this.$.ajax({ url: this.$url.getDownloadTabs, params: { code: true, codeType: 'ct' } }).then(
				res => {
					this.downloadList = res[0]?.children || [];
				}
			);
		},
		onMore(code, activedIndex) {
			this.$router.push({ name: code, query: { activedIndex } });
			// //集团要闻
			// groupNews
			// //子公司要闻
			// subsidiaryInformation
			// //公告通知
			// announcementNotice
			// //综合信息
			// integratedInformation
			// //下载中心
			// downloadCenter
		},
		//拿股票信息
		getShareData() {
			this.$.ajax({ url: this.$url.getShareData, method: 'POST' }).then(res => {
				if (res.status == 'success') {
					this.shareData = res.data.shareDataList[0];
				}
			});
		},
		//去详情
		toDetail(item, name) {
			if (this.itemClass(item)) {
				this.$message.warning('该内容已结束！');
				return;
			}
			//跳转类型的
			if (item.infoType !== 0 && item.infoUrl) {
				window.open(item.infoUrl, '_blank');
			}
			//去详情
			else {
				// this.$router.push({name:'detail',query:{id:item.id,name}})
				var { href } = this.$router.resolve({ name: 'detail', query: { id: item.id, name } });
				window.open(href, '_blank');
			}
		},
		//类函数
		itemClass(item) {
			if (item.expirationDate && item.expirationDate.length > 0) {
				let exDate = new Date(item.expirationDate).getDate();
				let now = new Date().getDate();
				return exDate < now;
			} else {
				return false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.background {
	width: 100%;
	padding: 24px 0px 64px;
	background: #f4f6fd;
}
.content {
	width: 1200px;
	display: flex;
	margin: 0 auto;
	justify-content: space-between;
	flex-wrap: wrap;
	.bb {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		::v-deep .card:first-child {
			margin-bottom: 24px;
		}
	}
	.mt {
		margin-top: 24px;
	}
	.title1 {
		width: 100%;
		height: 65px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 24px;
		.label {
			display: flex;
			align-items: center;
			font-family: Alibaba PuHuiTi 2;
			font-weight: normal;
			font-size: 16px;
			color: #15224c;
			.unit {
				margin-left: 11px;
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #b9bdc9;
			}
			.icon {
				width: 12px;
				height: 8px;
				margin-right: 11px;
			}
		}
		.date {
			display: flex;
			font-family: Alibaba PuHuiTi 2;
			font-weight: normal;
			font-size: 14px;
			color: #b9bdc9;
			align-items: center;
			.el-date-editor {
				margin-left: 16px;
				width: 240px;
			}
		}
	}
	.jdzzb-list {
		padding: 0 24px 0 21px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.jdzzb-item {
			width: 142px;
			height: 263px;
			background: url('@/assets/image/home/jdzzb-bg.png') no-repeat;
			background-size: 100% 100%;
			padding: 23px 21px 0 21px;
			.label {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #737a94;
				margin-bottom: 16px;
			}
			.value {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 24px;
				color: #15224c;
				margin-bottom: 20px;
			}
			.increase {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #737a94;
				margin-bottom: 20px;
				position: relative;
				&.up {
					color: #ff2400;
					&::after {
						content: '';
						display: inline-block;
						width: 8px;
						height: 10px;
						background: url('@/assets/image/home/icon_up.png') no-repeat;
						background-size: 100% 100%;
						margin-left: 7px;
					}
				}
				&.down {
					color: #0cc41d;
					&::after {
						content: '';
						display: inline-block;
						width: 8px;
						height: 10px;
						background: url('@/assets/image/home/icon_down.png') no-repeat;
						background-size: 100% 100%;
						margin-left: 7px;
					}
				}
			}
			.percentage {
				border-top: 1px solid rgba($color: #dfebf6, $alpha: 0.7);
				padding-top: 14px;
				.label {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #737a94;
					margin-bottom: 8px;
				}
				.value {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #15224c;
				}
			}
		}
	}
	.bllzb-list {
		width: 100%;
		padding: 0 24px 0 21px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.bllzb-item {
			background: url('@/assets/image/home/bllzb-bg.png') no-repeat;
			background-size: 100% 100%;
			margin-bottom: 13px;
			width: 243px;
			height: 141px;
			padding: 18px 18px 0 18px;
			.label {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #737a94;
				margin-bottom: 16px;
			}
			.value {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				.value-left {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 24px;
					color: #15224c;
					.unit {
						font-family: Alibaba PuHuiTi 2;
						font-weight: normal;
						font-size: 14px;
						color: #15224c;
						margin-left: 8px;
					}
				}
				.value-right {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #15224c;
					&.up {
						color: #ff2400;
						&::after {
							content: '';
							display: inline-block;
							width: 8px;
							height: 10px;
							background: url('@/assets/image/home/icon_up.png') no-repeat;
							background-size: 100% 100%;
							margin-left: 7px;
						}
					}
					&.down {
						color: #0cc41d;
						&::after {
							content: '';
							display: inline-block;
							width: 8px;
							height: 10px;
							background: url('@/assets/image/home/icon_down.png') no-repeat;
							background-size: 100% 100%;
							margin-left: 7px;
						}
					}
				}
			}
		}
	}
	.focus-news {
		padding: 8px 24px;
		width: 100%;
		height: 312px;
		display: flex;
		flex-direction: column;
		.show-img {
			margin-top: 16px;
			cursor: pointer;
			width: 100%;
			background: #a9a9a9;
			height: 189px;
			box-shadow: 0px 4px 16px 0px rgba(4, 80, 184, 0.08);
			border-radius: 6px;
			overflow: hidden;
			margin-bottom: 10px;
			position: relative;
			.icon {
				left: 0;
				top: 0;
				position: absolute;
				width: 52px;
				height: 27px;
				background: #2591f7;
				box-shadow: 0px 2px 4px 0px rgba(37, 145, 247, 0.24);
				border-radius: 6px 0px 6px 0px;
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 12px;
				color: #ffffff;
				line-height: 27px;
				text-align: center;
			}
			.show-img-main {
				width: 100%;
				height: 100%;
			}
			.info {
				width: 100%;
				height: 67px;
				position: absolute;
				left: 0;
				bottom: 0;
				background: rgba($color: #15224c, $alpha: 0.54);
				padding: 14px 22px 0;
				.title {
					width: 100%;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #ffffff;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-bottom: 5px;
				}
				.date {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: rgba($color: #fff, $alpha: 0.5);
				}
			}
			&.expir {
				cursor: not-allowed;
				.icon {
					background: #999;
				}
			}
		}
		.focus-news-list {
			flex: 1;
			width: 100%;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			.focus-news-item {
				cursor: pointer;
				flex: 1;
				flex-shrink: 0;
				min-height: 36px;
				max-height: 37px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.title {
					.icon {
						width: 8px;
						height: 8px;
						margin-right: 12px;
					}
					width: 230px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #15224c;
				}
				.date {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 14px;
					color: #737a94;
				}
				&.expir {
					cursor: not-allowed;
					.title {
						color: #999;
					}
					.date {
						color: #999;
					}
				}
			}
		}
	}
	.info-list {
		width: 100%;
		padding: 0 24px;
		overflow: hidden;
		height: 220px;
		.info-item {
			cursor: pointer;
			display: flex;
			align-items: center;
			height: 20%;
			justify-content: space-between;
			.title {
				.icon {
					width: 8px;
					height: 8px;
					margin-right: 12px;
				}
				width: 230px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #15224c;
			}
			.date {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #737a94;
			}
			&.expir {
				cursor: not-allowed;
				.title {
					color: #999;
				}
				.date {
					color: #999;
				}
			}
		}
	}
	.ctny {
		width: 100%;
		height: 76px;
		padding: 0 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.left {
			.label {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 18px;
				color: #15224c;
				margin-bottom: 10px;
			}
			.num {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #737a94;
			}
		}
		.right {
			display: flex;
			align-items: center;
			.icon {
				width: 30px;
				margin-right: 22px;
				height: 20px;
			}
			.value {
				font-family: Alibaba PuHuiTi 2;
				font-weight: 600;
				font-size: 24px;
				color: #0cc41e;
			}
			.increase {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 14px;
				color: #0cc41e;
				margin-left: 10px;
			}
			&.red {
				.value {
					color: #ff2400;
				}
				.increase {
					color: #ff2400;
				}
			}
		}
	}
	.download-box {
		width: 100%;
		height: 121px;
		padding: 24px 0;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		.download-item {
			flex: 1;
			flex-shrink: 0;
			min-width: 33.3333%;
			height: 100%;
			font-family: Alibaba PuHuiTi 2;
			font-weight: normal;
			font-size: 14px;
			text-align: center;
			cursor: pointer;
			color: #15224c;
			.icon {
				width: 52px;
				height: 52px;
				margin-bottom: 8px;
				margin: 0 auto;
				display: block;
			}
			&:nth-child(n + 1) {
				border-left: 1px solid rgba($color: #dfebf6, $alpha: 0.7);
			}
		}
	}
}
</style>
