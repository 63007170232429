<!-- 分阅 -->
<template>
	<es-form
		v-loading="loading"
		element-loading-text="获取数据中..."
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.65)"
		:hides="hides"
		:model="formData"
		:contents="contents"
		@submit="handleSubmit"
	></es-form>
</template>

<script>
export default {
	name: 'ShareRead',
	components: {},
	props: {
		rowData: Object,
		notify: String,
		notifyList: Array
	},
	data() {
		return {
			loading: true,
			formData: {},
			hides: [],
			contents: [
				{
					name: 'nextReadUserId',
					label: '办理用户',
					type: 'selector',
					title: '下步处理对象',
					types: ['myemployee', 'enterprise', 'department', 'persongroup'],
					placeholder: '请选择办理用户',
					rules: {
						required: true,
						message: '请选择办理用户',
						trigger: 'change'
					}
				},
				{
					name: 'handleExplain',
					label: '办理说明',
					type: 'textarea',
					placeholder: '请输入办理说明',
					rules: {
						required: true,
						message: '请输入办理说明',
						trigger: 'change'
					}
				},
				{
					name: 'notificationType',
					label: '通知方式',
					type: 'checkbox',
					data: this.notifyList,
					placeholder: '请选择通知方式',
					events: {
						change: (name, data) => {
							if (data.length) {
								this.hides = [];
							} else {
								this.hides = ['notificationMsg'];
							}
						}
					}
				},
				{
					name: 'notificationMsg',
					label: '通知消息',
					type: 'textarea',
					placeholder: '请输入通知消息',
					rules: {
						required: true,
						message: '请输入通知消息',
						trigger: 'change'
					}
				}
			],
			taskExamine: {}
		};
	},
	computed: {},
	watch: {
		notify: {
			immediate: true,
			handler(val) {
				this.$set(this.formData, 'notificationType', val ? val.split(',') : []);
			}
		}
	},
	created() {
		this.getMsg();
	},
	mounted() {},
	methods: {
		getMsg() {
			this.$.ajax({
				url: '/bpm/task/taskHandle/toStartTaskReadInitData.dhtml',
				params: {
					appId: this.rowData.appid
				}
			})
				.then(res => {
					if (res.rCode == 0) {
						let { taskExamine } = res.results;
						let {
							processDefinitionId,
							nodeId,
							businessId,
							pendingId,
							appId,
							businessName,
							bCode,
							bName,
							openMode
						} = taskExamine;
						this.taskExamine = taskExamine;
						this.$.ajax({
							url: '/bpm/task/taskHandle/getNotificationMsg.dhtml',
							params: {
								processDefinitionId,
								nodeId,
								businessId,
								pendingId,
								appId,
								businessName,
								bCode,
								bName,
								openMode
							}
						})
							.then(data => {
								this.loading = false;
								if (data.status == 'success') {
									this.$set(this.formData, 'notificationMsg', data.message);
								}
							})
							.catch(err => {
								this.loading = false;
								if (err.message && err.message !== 'canceled') {
									this.$message.error(err.message);
								}
							});
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				})
				.catch(err => {
					this.loading = false;
					if (err.message && err.message !== 'canceled') {
						this.$message.error(err.message);
					}
				});
		},
		handleSubmit() {
			let nextReadUserId = this.formData.nextReadUserId
				.map(item => {
					return item.showid;
				})
				.join(',');
			let notificationType = this.notifyList
				.filter(item => {
					return this.formData.notificationType.includes(item.value);
				})
				.map(item => {
					return item.value;
				})
				.join(',');
			let taskAction =
				this.taskExamine.taskAction == 'taskReadAndEnd' ? 'taskReadAndEnd' : 'create';
			let {
				itemName,
				bCode,
				bName,
				isinstancy,
				businessId,
				businessName,
				currentOrgProcessKey,
				processDefinitionId,
				appId,
				choiceDeptId,
				choiceOrgId,
				pendingId
			} = this.taskExamine;
			let data = {
				...this.formData,
				itemName,
				bCode,
				bName,
				isinstancy,
				businessId,
				businessName,
				currentOrgProcessKey,
				processDefinitionId,
				appId,
				choiceDeptId,
				choiceOrgId,
				pendingId,
				nextReadUserId,
				notificationType
			};
			let loading = this.$.loading('', '分阅中，请稍等！');
			let url = `bpm/task/taskHandle/taskRead.dhtml?taskAction=${taskAction}`;
			this.$.ajax({
				url: url,
				method: 'post',
				data: data
			})
				.then(res => {
					if (res.rCode == 0 || res.status == 'success') {
						this.$message({
							type: 'success',
							duration: 2000,
							message: `《${this.rowData.pendtitle}》${this.rowData.taskReadTypeName}成功！`,
							onClose: () => {
								loading.close();
								this.$emit('success');
							}
						});
					} else {
						loading.close();
						let msg = res.msg || res.message || '系统错误，请联系管理员！';
						this.$message.error(msg);
					}
				})
				.catch(err => {
					loading.close();
					if (err.message && err.message !== 'canceled') {
						this.$message.error(err.message);
					}
				});
		}
	}
};
</script>

<style lang="scss" scoped></style>
