<template>
	<div></div>
</template>

<script>
export default {
	created() {
		this.$.ajax({ url: this.$url.getWeb }).then(res => {
			if (res.status == 'success') {
				window.location.href = res.data?.webUrl || 'main.html';
			}
		});
	}
};
</script>

<style>
</style>