<!--
* @desc: 左侧树
* @Author: YanZhongYing
* @Date: 2024-06-07
-->
<template>
	<div class="tree-box">
		<es-button @click="drawer = true" class="button">
			<i class="el-icon-s-unfold"></i>
		</es-button>
		<el-drawer
			title=""
			:visible.sync="drawer"
			direction="ltr"
			:with-header="false"
			:modal="false"
			size="240px"
		>
			<es-tree
				class="tree"
				style="width: 240px"
				:url="common.companyTree"
				:search="search"
				:param="{
					dateString: date
				}"
				:key="key"
				:tips="false"
				showSearch
				@node-click="handleNodeClick"
			></es-tree>
		</el-drawer>
	</div>
</template>

<script>
import { common } from '@/http/operateAnalysisShow';
export default {
	props: {
		date: {
			type: String
		}
	},
	data() {
		return {
			common,
			search: {
				name: 'searchKey',
				placeholder: '请输入企业名称'
			},
			key: 0,
			drawer: false
		};
	},
	watch: {
		date() {
			this.key++;
			this.getfirstId();
		}
	},
	computed: {
		boxWidth() {
			return this.drawer ? '240px' : '0px'; // 展开和收回的宽度
		}
	},
	methods: {
		getfirstId() {
			this.$.ajax({
				url: common.companyTree,
				params: {
					dateString: this.date
				}
			})
				.then(res => {
					const { rCode, results } = res;
					if (rCode == 0) {
						this.$emit('setId', results.length > 0 ? results[0].id : '', true, results[0].name);
					} else {
						this.$message.error(msg);
					}
				})
				.catch(error => {});
		},
		handleNodeClick(data) {
			this.$emit('setId', data.id, false, data.name);
		}
	}
};
</script>

<style lang="scss" scoped>
.tree-box {
	height: 100%;
	background: #fff;
	z-index: 20;

	.button {
		height: 100%;
	}
}
.tree {
	width: 240px;
	height: 100%;
	border: 1px solid #eee;
	// transition: width 0.3s ease; /* 添加过渡效果 */
}
// 添加 选中 效果
::v-deep .is-current > .el-tree-node__parent .es-icon-node,
::v-deep .is-current > .el-tree-node__content .es-icon-node {
	width: 100%;
	background-color: #f0f7ff;
}

::v-deep .el-drawer__wrapper {
	position: absolute;
}
::v-deep .es-tree-search {
	background: #fff;
}
</style>
