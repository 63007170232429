<template>
	<es-card
		class="layot-card"
		:header="header"
		:shadow="shadow"
		:border="false"
		mode="chunk"
		:style="styles"
	>
		<div slot="suffix" class="application-suffix">
			<!-- <el-button type="primary" round size="mini">应用配置</el-button> -->
			<el-input
				class="application-search"
				placeholder="请输入关键字"
				size="small"
				prefix-icon="el-icon-search"
				v-model="where"
				ref="searchData"
			></el-input>
			<el-button class="application-more" type="text" @click="handleMore">更多</el-button>
		</div>
		<ul class="application-list">
			<li
				class="application-list-item"
				v-for="item in list"
				:key="item.id"
				:title="item.name"
				@click="handleClick(item)"
			>
				<div class="application-list-icon-box">
					<es-icon class="application-list-icon" :contents="item.icons || defaultIcon"></es-icon>
				</div>
				<div class="application-list-text es-text-ellipsis">
					{{ item.name }}
				</div>
			</li>
		</ul>
		<es-dialog
			:visible.sync="visible"
			title="常用应用"
			width="1200px"
			height="716px"
			@scale="handleScale"
		>
			<div class="application-list-box">
				<el-input
					class="application-search"
					placeholder="请输入关键字"
					size="small"
					clearable
					prefix-icon="el-icon-search"
					v-model="name"
					ref="searchList"
				></el-input>
				<el-scrollbar class="application-lists-box" ref="listsBox">
					<ul class="application-lists">
						<li
							class="application-list-item"
							v-for="(ele, index) in datas"
							:key="ele.id"
							:title="ele.name"
							@click="handleClick(ele)"
						>
							<div class="application-list-icon-box" :style="setStyle(index)">
								<es-icon
									class="application-list-icon"
									:contents="ele.icons || defaultIcon"
								></es-icon>
							</div>
							<div class="application-list-text">
								<div class="application-lists-text es-text-ellipsis-row2">{{ ele.name }}</div>
							</div>
						</li>
					</ul>
				</el-scrollbar>
				<el-pagination
					class="application-pagination"
					background
					layout="total,prev, pager, next"
					total-text="共 {total} 项"
					hide-on-single-page
					:page-size="size"
					:total="lists.length"
					@current-change="
						res => {
							this.handleChange(res);
						}
					"
				></el-pagination>
			</div>
		</es-dialog>
	</es-card>
</template>
<script>
import { debounce } from 'throttle-debounce';
export default {
	name: 'Application',
	props: {
		height: {
			type: [String, Number],
			default: '374'
		},
		header: {
			type: String,
			default: '常用应用'
		},
		shadow: {
			type: String,
			default: 'never'
		},
		defaultIcon: {
			type: String,
			default: 'es-icon-application'
		}
	},
	computed: {
		styles() {
			return { height: parseInt(this.height) + 'px' };
		},
		datas() {
			let start = (this.page - 1) * this.size;
			let end = this.page * this.size;
			return this.lists.slice(start, end);
		}
	},
	watch: {
		where(val) {
			this.getDatas(val, 'list');
		},
		name(val) {
			this.getDatas(val, 'lists');
		}
	},
	data() {
		return {
			userId: this.$.getStorage('userId'),
			where: '',
			name: '',
			results: [],
			list: [],
			lists: [],
			visible: false,
			size: 20,
			page: 1,
			backgroundColor: ['#0B3978', '#2591F7', '#69C0FF', '#E34D59', '#2591F7', '#FF9C52', '#17C27B']
		};
	},
	beforeCreate() {
		this.getDatas = debounce(500, (name, key) => {
			this[key] = name
				? this.results.filter(item => {
						return item.name.indexOf(name) > -1;
				  })
				: this.results;
		});
	},
	created() {
		this.getData();
	},
	mounted() {},
	methods: {
		getData(param, flag) {
			this.$.ajax({
				url: '/sysv3/sysUucApp/getUserRecordApp',
				params: {
					userId: this.userId,
					...(param || {})
				}
			})
				.then(res => {
					if (res.rCode == 0) {
						this.results = res.results;
						this.list = res.results.slice(0, 20);
						console.log(this.list);
						this.lists = res.results;
					}
				})
				.catch(err => {});
		},
		handleMore() {
			this.visible = true;
		},
		handleClick(data) {
			this.$.ajax({
				url: '/sysv3/sysUucApp/recordUserApp',
				params: {
					userId: this.userId,
					appId: data.id
				}
			})
				.then(res => {})
				.catch(err => {});
			data.openModel == 1 ? window.open(data.url) : this.$.win.top.jumpMenu(data.id);
		},
		setStyle(num) {
			let obj = {};
			if (this.backgroundColor) {
				if (Array.isArray(this.backgroundColor)) {
					obj.backgroundColor = this.backgroundColor[num % this.backgroundColor.length];
				} else {
					obj.backgroundColor = this.backgroundColor;
				}
			}
			return obj;
		},
		handleChange(res) {
			this.page = res;
		},
		handleScale(res) {
			if (res) {
				this.$nextTick(() => {
					let { width, height } = this.$refs.listsBox.$el.getBoundingClientRect();
					width = parseInt(width * 0.85);
					this.size = parseInt(width / 250) * parseInt(height / 100);
					//this.page = 1;
				});
			} else {
				this.size = 20;
			}
		}
	},
	destroyed() {}
};
</script>
<style lang="scss" scoped>
::v-deep {
	.el-button--mini {
		padding: 4px 8px;
	}
	.el-input__inner {
		border-radius: 20px;
	}
	.el-input__suffix {
		cursor: pointer;
	}
}
.application-suffix {
	flex: 1;
	text-align: right;
}
.application-search {
	width: 230px;
	height: 30px;
	line-height: 30px;
	margin-left: auto;
}
.application-more {
	margin-left: 20px;
}
.application-list-box {
	height: 100%;
	padding-top: 80px;
	padding-bottom: 56px;
	overflow: hidden;
	position: relative;
	&::before {
		content: '';
		display: block;
		width: 85%;
		height: 1px;
		position: absolute;
		top: 70px;
		left: 50%;
		transform: translateX(-50%);
		background-color: #cdd9e4;
	}
	.application-search {
		width: 85%;
		max-width: 1016px;
		position: absolute;
		left: 50%;
		top: 16px;
		transform: translateX(-50%);
	}
	.application-lists-box {
		height: 100%;
	}
}
.application-list,
.application-lists {
	width: 85%;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	.application-list-item {
		width: calc(20% - 8px);
		text-align: center;
		margin: 4px;
		cursor: pointer;
		.application-list-icon-box {
			width: 36px;
			height: 36px;
			line-height: 36px;
			border-radius: 6px;
			margin: 0 auto;
			background-color: $--color-primary-light-1;
			.application-list-icon {
				color: $--color-primary-light-5;
				font-size: 18px;
				line-height: 36px;
			}
		}
		.application-list-text {
			font-size: 12px;
			padding: 4px;
		}
	}
}
.application-lists {
	.application-list-item {
		width: 230px;
		padding: 20px 24px;
		margin: 10px;
		box-shadow: 0px 12px 24px 0px rgba(33, 70, 141, 0.1);
		border-radius: 9px;
		background-color: #fff;
		.application-list-icon-box {
			width: 40px;
			height: 40px;
			line-height: 40px;
			margin: 0;
			float: left;
			.application-list-icon {
				font-size: 20px;
				line-height: 40px;
				color: #fff;
			}
		}
		.application-list-text {
			height: 40px;
			margin-left: 56px;
			padding: 0;
			text-align: left;
			display: flex;
			align-items: center;
		}
		.application-lists-text {
			font-size: 16px;
			line-height: 20px;
			color: #15224c;
		}
	}
}
.application-pagination {
	text-align: right;
	padding: 10px 20px 18px 20px;

	::v-deep {
		.el-pagination__total {
			float: left;
		}
		.el-pager li {
			background-color: #fff;
		}
		.btn-prev,
		.btn-next {
			background-color: transparent;
		}
	}
}
</style>
