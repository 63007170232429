// 门户模块的接口地址
// 备注好功能与负责人
export default {
    //财务相关-刘珂良
    //获取最新财务时间
    getRecentDate: '/gwapi/gz-financial-analysis/operateAnalysis/getRecentDate',
    //获取财务树，拿根节点单位id-弃用
    // companyTreeCt: '/finance/FinancialCompany/companyTreeCt.dhtml',
    //领导角色看的时候调用的，拿两个指标数据
    mainOperatingIndicators: '/gz-financial-analysis/operateAnalysis/mainOperatingIndicators',
    //普通员工角色看的时候调用，分别为4个页签
    //利润总额排名
    rankingOfTotalProfits: '/gz-financial-analysis/operateAnalysis/rankingOfTotalProfits',
    //盈利企业与亏损企业
    budgetCompletionInCome: '/gz-financial-analysis/operateAnalysis/budgetCompletionInCome',
    //营业收入排名
    rankingOfOperatingIncome: '/gz-financial-analysis/operateAnalysis/rankingOfOperatingIncome',
    //应用相关-杨杰
    //获取应用与更多系统
    getMainAppWithTag: '/sysv3/sysUucApp/getMainAppWithTag',
    //主页、二级列表相关-牟正勇
    //顶部图片轮播
    getBannerList: '/portal/getBannerList.dhtml',
    //顶部导航菜单
    getNavigation: '/portal/getNavigation.dhtml',
    //底部友情链接
    getFootUrlList: '/portal/getFoot.dhtml',
    //首页小栏目的id
    getColumnIdByCode: '/portal/getColumnIdByCode.dhtml',
    //也可以通过多个code查多个
    getColumnIdByCodes: '/portal/getColumnIdByCodes.dhtml',
    //二级菜单
    getColumnList: '/portal/getColumnList.dhtml',
    //list
    getColumnListJson: '/portal/listJson.dhtml',
    //首页下载中心
    getDownloadTabs: '/oa/oaDowncenterFileClass/getClassTree.dhtml',
    //每个下载类型的list
    getDownloadList: '/portal/downloadList.dhtml',
    //底部股票信息
    getShareData: '/portal/getShareData.dhtml',
    //根据附件id获取附件
    previewAdjunct: '/portal/fileManagement/previewAdjunct.dhtml',
    //详情接口
    detail: '/portal/detail.dhtml',
    //搜索
    search: '/portal/search.dhtml',
    //重定向
    getWeb: '/portal/getWeb.dhtml'
}
