<template>
	<div class="home" ref="home">
		<div class="menu">
			<div
				class="left-btn"
				:class="{ disabled: menuPageNum == 1 }"
				@click="onChangeMenuPage('left', menuPageNum == 1)"
			>
				<i class="es-icon-pointer-left" />
			</div>
			<div class="menu-page-box">
				<div
					class="menu-page"
					v-for="(page, i) in Math.ceil(menuList.length / 8)"
					:key="i"
					:style="{ transform: `translateX(-${(menuPageNum - 1) * 100}%)` }"
				>
					<div
						class="menu-item"
						v-for="(item, index) in menuList.slice((page - 1) * 8, page * 8)"
						:class="{ sysImg: item.icon, disabled: !item.permission, ml: index == 4 }"
						:key="item.id"
						:title="item.name"
						@click="onMenuClick(item)"
					>
						<img
							class="icon"
							:src="
								item.icon
									// ? `${baseUrl}/cform/fileManagement/previewAdjunct.dhtml?adjunctId=${item.icon}`
									? `${baseUrl}/dochub/v2/downloadDocument?documentId=${item.icon}`
									: require('@/assets/image/home/ywxt.png')
							"
							alt=""
						/>
						<div class="label">{{ item.name }}</div>
					</div>
				</div>
			</div>
			<div class="menu-item gdxt" @click="onMoreSys">
				<img class="icon" src="@/assets/image/home/gdxt.png" alt="" />
				<div class="label">更多系统</div>
			</div>
			<div
				class="right-btn"
				:class="{ disabled: Math.ceil(menuList.length / 8) == menuPageNum }"
				@click="onChangeMenuPage('right', Math.ceil(menuList.length / 8) == menuPageNum)"
			>
				<i class="es-icon-pointer-right" />
			</div>

			<more-sys
				v-if="showMoreSys"
				ref="moreSys"
				@onClose="showMoreSys = false"
				:list="all"
				:pH="pH"
			/>
		</div>
		<!--集团员工看的-->
		<StaffRole v-if="role == 0" />
		<!--集团领导看的-->
		<LeadershipRole v-if="role == 1" />
		<!--非集团用户看的-->
		<OrdinaryRole v-if="role == 2" />
	</div>
</template>

<script>
import MoreSys from '../components/MoreSys';
import LeadershipRole from './LeadershipRole';
import OrdinaryRole from './OrdinaryRole';
import StaffRole from './StaffRole';
export default {
	components: { LeadershipRole, OrdinaryRole, StaffRole, MoreSys },
	data() {
		return {
			menuList: [],
			menuPageNum: 1,
			all: [],
			role: null,
			showMoreSys: false,
			pH: 0,
			baseUrl: window.location.origin + (window.location.hostname == 'localhost' ? '/dev-api' : ''),
			firstHalf: [],
			secondHalf: []
		};
	},
	created() {},
	mounted() {
		//信产的
		if (this.$route.name == 'homeII') {
			this.role = 2;
		} else {
			let interval = setInterval(() => {
				if (sessionStorage.getItem('mainConfig')) {
					this.role = JSON.parse(sessionStorage.getItem('mainConfig')).userModel.resourceCodes.find(
						e => {
							return e == 'viewMainBusinessSmallRole';
						}
					)
						? 1
						: 0;
					clearInterval(interval);
				}
			}, 100);
		}
		this.getMenu();
	},
	methods: {
		getMenu() {
			this.$.ajax({
				url: this.$url.getMainAppWithTag,
				params: { userId: sessionStorage.getItem('userId') }
			}).then(({ rCode, results }) => {
				if (rCode == 0) {
					this.menuList = results.mainApps;
					this.all = results.all;
				}
			});
		},
		onMenuClick(item) {
			if (!item.permission) return;
			try {
				if (item.openModel == 1) {
					window.open(item.url, '_blank');
				} else {
					if (item.url) {
						window.location.href = item.url;
					} else {
						window.location.href = './main.html#/?appCode=' + item.code;
					}
				}
			} catch {
				this.$message.error('系统地址异常：' + item.url);
			}
		},
		onMoreSys() {
			if (this.showMoreSys) {
				this.$refs.moreSys.onClose();
			} else {
				//计算高度，减去系统菜单高度，加上footer高度
				this.pH = this.$refs.home.scrollHeight - 156 + 396;
				this.showMoreSys = true;
			}
		},
		onChangeMenuPage(type, disabled) {
			if (disabled) return;
			switch (type) {
				case 'left':
					this.menuPageNum--;
					break;
				case 'right':
					this.menuPageNum++;
					break;
				default:
					break;
			}
		}
		// getHalf(type, arr) {
		// 	const list = arr;
		// 	const half = Math.ceil(list.length / 2);
		// 	return type == 'left' ? list.splice(0, half) || [] : list.splice(-half) || [];
		// }
	}
};
</script>

<style lang="scss" scoped>
.home {
	width: 100%;
	.menu {
		margin: 0 auto;
		width: 100%;
		height: 156px;
		background: #ffffff;
		position: relative;
		display: flex;
		justify-content: center;
		z-index: 5;
		box-shadow: 0px -17px 17px 1px rgba(0, 151, 216, 0.11);

		.menu-page-box {
			width: 1151.5px;
			height: 100%;
			display: flex;
			overflow: hidden;
		}
		.menu-page {
			width: 100%;
			flex-shrink: 0;
			height: 100%;
			display: flex;
			transition: transform linear 0.7s;
		}
		// .menu-right {
		// 	display: flex;
		// 	flex: 1;
		// 	height: 100%;
		// }
		// .menu-left {
		// 	display: flex;
		// 	justify-content: flex-end;
		// 	flex: 1;
		// 	height: 100%;
		// }
		.menu-item {
			height: 100%;
			width: 102px;
			cursor: pointer;
			margin: 0 12.75px;
			padding-top: 15px;
			.icon {
				width: 102px;
				height: 102px;
			}
			.label {
				position: relative;
				bottom: 18px;
				width: 100%;
				text-align: center;
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 16px;
				color: #15224c;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}
			&.sysImg {
				.icon {
					width: 56px;
					display: block;
					height: 56px;
					margin: 12px auto 24px;
				}
				.label {
					bottom: 4px;
				}
			}
			&.gdxt {
				width: 106px;
				height: 106px;
				background: linear-gradient(0deg, #055db4 0%, rgba(68, 114, 202, 0.9) 100%);
				border-radius: 12px;
				position: absolute;
				left: 50%;
				top: 25px;
				margin: 0;
				transform: translateX(-50%);
				.icon {
					width: 44px;
					display: block;
					margin: 0 auto 7px;
					height: 44px;
				}
				.label {
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 16px;
					color: #ffffff;
					bottom: 0;
				}
			}
			&.disabled {
				cursor: not-allowed;
				position: relative;
				&:hover::after {
					content: '暂无权限';
					border-radius: 8px;
					font-size: 16px;
					height: calc(100% - 16px);
					color: #fff;
					top: 8px;
					left: 0;
					position: absolute;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					background: rgba($color: #000, $alpha: 0.6);
				}
			}
			&.ml {
				margin-left: 144.25px;
			}
		}
		.left-btn,
		.right-btn {
			width: 24.25px;
			height: 100%;
			text-align: center;
			line-height: 156px;
			cursor: pointer;
			.es-icon-pointer-left,
			.es-icon-pointer-right {
				font-size: 24px;
				color: #000;
			}
			&.disabled {
				cursor: not-allowed;
				.es-icon-pointer-left,
				.es-icon-pointer-right {
					color: #999;
				}
			}
		}
	}
}
</style>
