<!--这个是详情，所有详情都用这个vue文件-->
<template>
	<div class="secondLevel">
		<div class="crumbs">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item
					v-for="(item, index) in crumbsList"
					:key="index"
					:class="{ actived: index == crumbsList.length - 1 }"
				>
					<span @click="onGo(item)">
						{{ `${index == 0 ? '当前位置：' : ''}${item.label || '--'}` }}
					</span>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div
			class="content-box"
			style="width: 1200px; margin: 0 auto; padding: 16px 0; background: #fff"
		>
			<div
				class="title"
				style="text-align: center; font-size: 24px; font-weight: 500; margin-bottom: 16px"
			>
				{{ title }}
			</div>
			<div
				class="info"
				style="
					width: 100%;
					height: 60px;
					background-color: #ddd;
					margin-bottom: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
				"
			>
				<div class="info-item">
					<i class="es-icon-edit" />
					{{ info.source || '--' }}
				</div>
				<div class="info-item">
					<i class="es-icon-zongtiqixian" />
					{{ info.date || '--' }}
				</div>
				<div class="info-item">
					<i class="es-icon-yanjing" />
					{{ info.findDetailCount || '--' }}
				</div>
			</div>
			<el-carousel
				class="banner-siwper"
				height="630px"
				:interval="5000"
				@change="onCarouselChange"
				v-if="swiperList && swiperList.length > 0"
			>
				<el-carousel-item v-for="item in swiperList" :key="item.id">
					<div class="banner-box">
						<img
							class="banner-item"
							:src="`${baseUrl}/portal/fileManagement/previewAdjunct.dhtml?adjunctId=${item.adjunctId}`"
							alt=""
						/>
						<p class="img-title">{{ title }}</p>
					</div>
				</el-carousel-item>
			</el-carousel>
			<div class="content" v-html="content"></div>
		</div>
		<div class="page-box">
			<div class="page" @click="onLast" v-if="last && last.title">上一条：{{ last.title }}</div>
			<div class="page" @click="onNext" v-if="next && next.title">下一条：{{ next.title }}</div>
			<i class="es-icon-print" @click="onPrint" />
		</div>
		<!--打印样式要写在行内-->
		<div
			class="content-box pa"
			id="printBox"
			style="width: 1200px; margin: 0 auto; padding: 16px 0; background: #fff"
		>
			<div
				class="title"
				style="text-align: center; font-size: 24px; font-weight: 500; margin-bottom: 16px"
			>
				{{ title }}
			</div>
			<div
				class="banner-siwper"
				:interval="5000"
				style="width: 900px; margin: 0 auto 16px; height: 630px"
				v-if="swiperList && swiperList.length > 0"
			>
				<div class="banner-box" style="position: relative; width: 100%; height: 600px">
					<img
						class="banner-item"
						style="width: 100%; height: 100%"
						:src="`${baseUrl}/portal/fileManagement/previewAdjunct.dhtml?adjunctId=${swiperList[carouselIndex].adjunctId}`"
						alt=""
					/>
					<p
						class="img-title"
						style="
							position: absolute;
							bottom: 0;
							width: 100%;
							width: 100%;
							line-height: 40px;
							height: 40px;
							position: absolute;
							bottom: 0;
							color: #fff;
							background-color: rgba(0, 0, 0, 0.5);
							text-align: center;
						"
					>
						{{ title }}
					</p>
				</div>
			</div>
			<div class="content" v-html="content"></div>
		</div>
	</div>
</template>

<script>
import print from 'print-js';
export default {
	components: {},
	data() {
		return {
			name: '',
			crumbsList: [],
			baseUrl: window.location.origin + (window.location.hostname == 'localhost' ? '/dev-api' : ''),
			imgList: [],
			fileList: [],
			title: '',
			content: '',
			info: {},
			swiperList: [],
			last: {},
			next: {},
			carouselIndex: 0
		};
	},
	created() {
		this.name = this.$route.query.name;
		let map = {
			//首页-综合信息
			integratedInformation: {
				crumbs: [
					{ label: '首页', name: 'home' },
					{
						label: this.$route.query.pathName || '综合信息',
						name: 'integratedInformation',
						actived: this.$route.query.actived
					},
					{ label: '详情' }
				]
			},
			//首页-通知
			notice: {
				crumbs: [
					{ label: '首页', name: 'home' },
					{
						label: '通知',
						name: 'announcementNotice',
						actived: this.$route.query.actived
					},
					{ label: '详情' }
				]
			},
			//首页-公告
			announcement: {
				crumbs: [
					{ label: '首页', name: 'home' },
					{
						label: '公告',
						name: 'announcementNotice',
						actived: this.$route.query.actived
					},
					{ label: '详情' }
				]
			},

			//首页-公司文件
			companyDocuments: {
				crumbs: [
					{ label: '首页', name: 'home' },
					{
						label: '公司文件',
						name: 'companyDocumentsSystemCompilation',
						actived: this.$route.query.actived
					},
					{ label: '详情' }
				]
			},
			//首页-制度汇编
			systemCompilation: {
				crumbs: [
					{ label: '首页', name: 'home' },
					{
						label: '制度汇编',
						name: 'companyDocumentsSystemCompilation',
						actived: this.$route.query.actived
					},
					{ label: '详情' }
				]
			},
			//首页-子公司要闻
			subsidiaryInformation: {
				crumbs: [
					{ label: '首页', name: 'home' },
					{
						label: this.$route.query.pathName || '子公司要闻',
						name: 'subsidiaryInformation',
						actived: this.$route.query.actived
					},
					{ label: '详情' }
				]
			},
			//首页-集团要闻
			groupNews: {
				crumbs: [
					{ label: '首页', name: 'home' },
					{
						label: this.$route.query.pathName || '集团要闻',
						name: 'groupNews',
						actived: this.$route.query.actived
					},
					{ label: '详情' }
				]
			},
			//二级-合规管理
			complianceManagement: {
				crumbs: [
					{ label: '合规管理', name: 'complianceManagement' },
					{
						label: this.$route.query.pathName,
						name: 'complianceManagement',
						actived: this.$route.query.actived
					},
					{ label: '详情' }
				]
			},
			//二级-制度汇编
			compilationOfSystems: {
				crumbs: [
					{ label: '制度汇编', name: 'compilationOfSystems' },
					{
						label: this.$route.query.pathName,
						name: 'compilationOfSystems',
						actived: this.$route.query.actived
					},
					{ label: '详情' }
				]
			},
			//二级-安全环境
			safetyAndEnvironmentalProtection: {
				crumbs: [
					{ label: '安全环境', name: 'safetyAndEnvironmentalProtection' },
					{
						label: this.$route.query.pathName,
						name: 'safetyAndEnvironmentalProtection',
						actived: this.$route.query.actived
					},
					{ label: '详情' }
				]
			},
			//二级-保密安全
			confidentialityAndSecurity: {
				crumbs: [
					{ label: '保密安全', name: 'confidentialityAndSecurity' },
					{
						label: this.$route.query.pathName,
						name: 'confidentialityAndSecurity',
						actived: this.$route.query.actived
					},
					{ label: '详情' }
				]
			},
			//搜索
			search: {
				crumbs: [{ label: '搜索', name: 'search' }, { label: '详情' }]
			}
		};
		this.crumbsList = map[this.name].crumbs;
	},
	mounted() {
		this.getInfo();
	},
	methods: {
		onGo(item) {
			this.$router.push({
				name: item.name,
				query: { activedIndex: item.actived, pathId: this.$route.query.pathId }
			});
		},
		getInfo() {
			this.$.ajax({ url: this.$url.detail, params: { id: this.$route.query.id } }).then(res => {
				if (res.status == 'success') {
					let data = res.data;
					this.title = data.information.title;
					this.content = data.information.content;
					this.info = {
						source: data.information.source,
						date: data.information.holdStartTime
							? `${data.information.holdStartTime}至${data.information.holdEndTime}`
							: data.information.publishTime,
						findDetailCount: data.findDetailCount
					};
					this.swiperList = data.wheelAdjuncts;
					this.last = data.lastInformation;
					this.next = data.nextInformation;
				}
			});
		},
		onLast() {
			let query = { ...this.$route.query, id: this.last.id };
			this.$router.replace({
				name: 'detail',
				query
			});
			window.location.reload();
		},
		onNext() {
			let query = { ...this.$route.query, id: this.next.id };
			this.$router.replace({
				name: 'detail',
				query
			});
			window.location.reload();
		},
		onPrint() {
			print({
				printable: 'printBox', //打印区域id
				type: 'html', //打印类型是html
				scanStyles: false,
				targetStyles: ['*']
			});
		},
		onCarouselChange(index) {
			this.carouselIndex = index;
		}
	}
};
</script>

<style lang=scss scoped>
.secondLevel {
	width: 100%;
	background: #f4f6fd;
	position: relative;
	padding-bottom: 20px;
	.crumbs {
		width: 100%;
		height: 66px;
		background: #f4f6fd;
		display: flex;
		align-items: center;
		::v-deep .el-breadcrumb {
			width: 1200px;
			margin: 0 auto;
			.el-breadcrumb__inner {
				font-family: Alibaba PuHuiTi 2;
				font-weight: normal;
				font-size: 16px;
				color: #737a94;
				cursor: pointer;
			}
			.el-breadcrumb__separator {
				color: #737a94;
			}
			.actived {
				.el-breadcrumb__inner {
					cursor: auto;
					color: #2591f7;
				}
			}
		}
	}
	.content-box {
		position: relative;
		z-index: 2;
		&.pa {
			position: absolute;
			z-index: 1;
			top: 66px;
			left: 50%;
			transform: translateX(-50%);
		}
		.title {
		}
		.info {
			.info-item {
				color: #999;
				padding: 0 20px;
				display: flex;
				align-items: center;
				font-size: 16px;
				font-family: 'Microsoft YaHei', \5fae\8f6f\96c5\9ed1, arial, \5b8b\4f53;
				i {
					font-size: 24px;
					margin-right: 5px;
				}
			}
		}
		.banner-siwper {
			width: 900px;
			margin: 0 auto 16px;
			.banner-box {
				position: relative;
				width: 100%;
				height: 600px;
				.banner-item {
					width: 100%;
					height: 100%;
				}
				.img-title {
					position: absolute;
					bottom: 0;
					width: 100%;
					width: 100%;
					line-height: 40px;
					height: 40px;
					position: absolute;
					bottom: 0;
					color: #fff;
					background-color: rgba(0, 0, 0, 0.5);
					text-align: center;
				}
			}
			::v-deep .el-carousel__button {
				background: #2591f7;
			}
		}
		.content {
			padding: 0 16px;
			// font-size: 16px;
			::v-deep p {
				max-inline-size: 100%;
				margin: 0px;
				padding: 0px;
				box-sizing: border-box !important;
				overflow-wrap: break-word !important;
				outline: none 0px !important;
				text-indent: 42px;
				letter-spacing: 0px;
				font-size: 21px;
			}
			::v-deep span,
			::v-deep p {
				font-family: '宋体';
			}
			// ::v-deep img{
			// 	width: 900px;
			// 	height: 600px;
			// 	display: block;
			// 	margin: 0 auto;
			// }
		}
	}
	.page-box {
		width: 1200px;
		padding: 20px 40px;
		margin: 0 auto;
		background-color: #ddd;
		position: relative;
		.page {
			cursor: pointer;
			color: #000000;
			font-size: 14px;
			width: calc(100% - 150px);
			white-space: nowrap;
			cursor: pointer;
			text-overflow: ellipsis;
			overflow: hidden;
			&:nth-child(1) {
				margin-bottom: 20px;
			}
		}
		.es-icon-print {
			position: absolute;
			font-size: 32px;
			cursor: pointer;
			right: 24px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
</style>