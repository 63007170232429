<template>
	<es-card
		class="layot-card"
		:header="header"
		:shadow="shadow"
		:border="false"
		mode="chunk"
		:style="styles"
	>
		<div slot="suffix" class="backlog-suffix" v-if="moreUrl && moreUrl.length && moreUrl[index]">
			<el-button type="text" round @click="handleMore">更多</el-button>
		</div>
		<tabs-table
			:height="_height"
			:contents="contents"
			ref="backlog"
			v-bind="$attrs"
			@active="handleActive"
		>
			<es-dialog
				v-if="visible"
				:width="dialogWidth"
				:height="dialogHeight"
				:title="title"
				:visible.sync="visible"
				:show-scale="false"
				@close="handleClose"
				@getCount="getCount"
			>
				<iframe v-if="url" frameborder="0" width="100%" height="100%" :src="url"></iframe>
				<share-read
					v-else-if="taskRead"
					:notify="notify"
					:notify-list="notifyList"
					:row-data="rowData"
					@success="handleSuccess"
				></share-read>
				<supervise
					v-else-if="showSupervise"
					:type-name="title"
					:notify="notify"
					:notify-list="notifyList"
					:row-data="rowData"
					@success="handleSuccess"
				></supervise>
				<es-form label-width="80px" :model="form" :contents="formList" v-else></es-form>
			</es-dialog>
		</tabs-table>
	</es-card>
</template>
<script>
import TabsTable from '@/components/public/TabsTable.vue';
import ShareRead from '@/components/public/ShareRead.vue';
import Supervise from '@/components/public/Supervise.vue';
let config = {
	showHeader: false,
	//stripe: true,
	theadBorder: false
	//border: false
	//page: true
};
function setInstancy(h, params) {
	let { row } = params;
	let isinstancy = row.isinstancy || row.exigentdegreeText;
	let reg = new RegExp('<[^>]+>', 'gi');
	let style = {};
	if (isinstancy) {
		if (reg.test(isinstancy)) {
			isinstancy = isinstancy.replace(reg, '');
		}
		if (isinstancy.indexOf('紧急') > -1) {
			style = { color: '#FFA500' };
		} else if (isinstancy.indexOf('加急') > -1) {
			style = { color: '#FF7F50' };
		} else if (isinstancy.indexOf('特急') > -1) {
			style = { color: '#ff0000' };
		}
	} else {
		isinstancy = '普通';
	}
	return h('span', { style: style }, [isinstancy]);
}

export default {
	name: 'Backlog',
	components: { TabsTable, ShareRead, Supervise },
	props: {
		moreUrl: Array,
		height: {
			type: [String, Number],
			default: 546
		},
		header: {
			type: String,
			default: '待办中心'
		},
		shadow: {
			type: String,
			default: 'never'
		},
		removeCollect: {
			type: String,
			default: '/bpm/favorite/favoriteRecord/removeCollect.dhtml'
		},
		collect: {
			type: String,
			default: '/bpm/favorite/favoriteRecord/collect.dhtml'
		},
		updatePendstate: {
			type: String,
			default: '/bpm/wfPending/wfPending/updatePendstate.dhtml'
		},
		openType: {
			type: Number,
			default: 0
		},
		host: String
	},
	computed: {
		styles() {
			return { height: parseInt(this.height) + 'px' };
		},
		_height() {
			return parseInt(this.height) - 106;
		},
		_host() {
			return this.host ? this.host : this.$.getStorage('host') || '';
		},
		formList() {
			return [
				{
					name: 'signattitude',
					label: '回复意见',
					type: 'textarea',
					rows: 4,
					rules: [{ required: true, message: '请输入回复意见', trigger: 'blur' }]
				},
				this.title == '签收公文'
					? {
							type: 'submit',
							contents: [
								{
									type: 'primary',
									text: '签收',
									validate: true,
									event: (res, value) => {
										this.handleSign(value, 'QS');
									}
								},
								{
									type: 'primary',
									text: '签收&登记',
									validate: true,
									event: (res, value) => {
										this.handleSign(value, 'QSDJ');
									}
								},
								{
									type: 'primary',
									text: '签收&办结',
									validate: true,
									event: (res, value) => {
										this.handleSign(value, 'BJ');
									}
								}
							]
					  }
					: {
							type: 'submit',
							contents: [
								{
									type: 'primary',
									text: '拒签',
									validate: true,
									event: (res, value) => {
										this.handleSign(value, 'JQ');
									}
								}
							]
					  }
			];
		}
	},
	watch: {},
	data() {
		return {
			contents: [
				{
					label: {
						icon: 'es-icon-shijian',
						text: '待办',
						count: 'makePendingCount'
					},
					...config,
					url: '/bpm/wfPending/wfPending/bootMakeListJson.dhtml',
					thead: [
						{
							title: '标题',
							field: 'pendtitle',
							showOverflowTooltip: true,
							minWidth: '220',
							render: (h, params) => {
								let { row } = params;
								let { pendtitle } = row;
								let style = { cursor: 'pointer' };
								return h(
									'span',
									{
										style: style,
										on: {
											click: () => {
												this.handleFinish(row);
											}
										}
									},
									[pendtitle]
								);
							}
						},
						{
							title: '业务类型',
							field: 'bname',
							width: '260',
							render: (h, params) => {
								let { row } = params;
								let { deptname, createtime } = row;
								return h('span', { style: { color: '#737A94' } }, [`${deptname} | ${createtime}`]);
							}
						},
						{
							title: '关注',
							align: 'center',
							width: '70',
							render: this.setConcernClick
						},
						{
							title: '操作',
							type: 'handle',
							width: '100',
							template: '',
							events: [
								{
									text: '办理',
									mold: 'text',
									rules: data => {
										return data.pendingattr == 0;
									},
									event: res => {
										this.handleFinish(res.row);
									}
								}
							]
						}
					]
				},
				{
					label: {
						icon: 'es-icon-jypx',
						text: '待阅',
						count: 'readPendingCount'
					},
					...config,
					url: '/bpm/wfPending/wfPending/bootReadListJson.dhtml',
					thead: [
						{
							title: '标题',
							field: 'pendtitle',
							showOverflowTooltip: true,
							minWidth: '220',
							render: (h, params) => {
								let { row } = params;
								let { pendtitle } = row;
								let style = { cursor: 'pointer' };
								return h(
									'span',
									{
										style: style,
										on: {
											click: () => {
												this.handleFinish(row);
											}
										}
									},
									[pendtitle]
								);
							}
						},
						{
							title: '业务类型',
							field: 'bname',
							width: '260',
							render: (h, params) => {
								let { row } = params;
								let { deptname, createtime } = row;
								return h('span', { style: { color: '#737A94' } }, [`${deptname} | ${createtime}`]);
							}
						},
						{
							title: '关注',
							align: 'center',
							width: '70',
							render: this.setConcernClick
						},
						{
							title: '操作',
							type: 'handle',
							width: '100',
							template: '',
							events: [
								{
									text: '办理',
									mold: 'text',
									event: res => {
										this.handleFinish(res.row);
									}
								}
							]
						}
					]
				},
				{
					label: {
						icon: 'es-icon-ziliao',
						text: '待签',
						count: 'signCount'
					},
					...config,
					searchKey: 'query_title',
					url: '/archives/archives/manage/signListPage.dhtml',
					thead: [
						{
							title: '标题',
							field: 'title',
							showOverflowTooltip: true,
							minWidth: '220',
							render: (h, params) => {
								let { row } = params;
								let { title } = row;
								let style = { cursor: 'pointer' };
								return h(
									'span',
									{
										style: style,
										on: {
											click: () => {
												this.handleView(row);
											}
										}
									},
									[title]
								);
							}
						},
						{
							title: '发文时间',
							field: 'sendarcdateText',
							width: '260',
							render: (h, params) => {
								let {
									row: { sendorgname, sendarcdateText, sendarcdate }
								} = params;
								if (!sendarcdateText) {
									sendarcdateText = this.$.formatDate(sendarcdate, 'yyyy-MM-dd HH:mm');
								}
								return h('span', { style: { color: '#737A94' } }, [
									`${sendorgname} | ${sendarcdateText}`
								]);
							}
						},
						{
							title: '操作',
							type: 'handle',
							width: '140',
							template: '',
							events: [
								{
									text: '签收',
									mold: 'text',
									//useCaseCode: 'my_workbenches_archives_read',
									event: res => {
										this.handleToSign(res.row);
									}
								},

								{
									text: '拒签',
									mold: 'text',
									//useCaseCode: 'my_workbenches_archives_read',
									event: res => {
										this.handleToRefuse(res.row);
									}
								}
							]
						}
					]
				},
				{
					label: {
						icon: 'es-icon-fuxuankuang1',
						text: '已办',
						count: 'pendedCount'
					},
					...config,
					url: '/bpm/wfpened/wfPended/bootListJson.dhtml',
					searchKey: 'keyword',
					thead: [
						{
							title: '标题',
							field: 'pendtitle',
							showOverflowTooltip: true,
							minWidth: '220',
							render: (h, params) => {
								let { row } = params;
								let { pendtitle } = row;
								let style = { cursor: 'pointer' };
								return h(
									'span',
									{
										style: style,
										on: {
											click: () => {
												this.handleFinish(row, true);
											}
										}
									},
									[pendtitle]
								);
							}
						},
						{
							title: '业务类型',
							field: 'bname',
							width: '260',
							render: (h, params) => {
								let { row } = params;
								let { deptname, createtime } = row;
								return h('span', { style: { color: '#737A94' } }, [`${deptname} | ${createtime}`]);
							}
						},
						{
							title: '关注',
							align: 'center',
							width: '60',
							render: this.setConcernClick
						},
						{
							title: '操作',
							type: 'handle',
							width: '160',
							template: '',
							events: [
								{
									text: '查看',
									mold: 'text',
									event: res => {
										this.handleFinish(res.row, true);
									}
								},
								{
									text: '撤回',
									mold: 'text',
									rules: data => {
										return data.isCanWithdraw;
									},
									event: res => {
										this.handleWithdraw(res.row);
									}
								},
								{
									text: '结阅',
									mold: 'text',
									useCaseCodeKey: 'bcode',
									rules: data => {
										return data.isCanEndTaskReadDeal;
									},
									event: res => {
										this.handleEndTaskReadDeal(res.row);
									}
								},
								{
									text: '',
									mold: 'text',
									useCaseCodeKey: 'bcode',
									template: res => {
										let {
											row: { taskReadTypeName }
										} = res;
										return taskReadTypeName;
									},
									rules: data => {
										return !this.$.isEmpty(data.taskReadTypeName);
									},
									event: res => {
										this.handleStartTaskRead(res.row);
									}
								}
							]
						}
					]
				},
				{
					label: {
						icon: 'el-icon-s-promotion',
						text: '发起',
						count: 'meApplicationCount'
					},
					...config,
					url: '/bpm/task/wfApplication/bootListJson.dhtml',
					searchKey: 'query_apprecordname',
					thead: [
						{
							title: '标题',
							field: 'apprecordname',
							showOverflowTooltip: true,
							minWidth: '220',
							render: (h, params) => {
								let { row } = params;
								let { apprecordname } = row;
								let style = { cursor: 'pointer' };
								return h(
									'span',
									{
										style: style,
										on: {
											click: () => {
												this.handleFinish(row, true);
											}
										}
									},
									[apprecordname]
								);
							}
						},
						{
							title: '业务类型',
							field: 'bname',
							width: '260',
							render: (h, params) => {
								let { row } = params;
								let { bname, starttime } = row;
								return h('span', { style: { color: '#737A94' } }, [`${bname} | ${starttime}`]);
							}
						},
						{
							title: '关注',
							align: 'center',
							width: '70',
							render: this.setConcernClick
						},
						{
							title: '操作',
							type: 'handle',
							width: '100',
							template: '',
							events: [
								{
									text: '查看',
									mold: 'text',
									event: res => {
										this.handleFinish(res.row, true);
									}
								},
								{
									text: '催办',
									mold: 'text',
									rules: data => {
										return (
											data.canSupervise &&
											(data.state == '处理中' || data.state == '暂停') &&
											(data.bcode == 'fawen' || data.bcode == 'shouwen')
										);
									},
									event: res => {
										this.handleSupervise(res.row);
									}
								},
								{
									text: '撤销',
									mold: 'text',
									useCaseCodeKey: 'bcode',
									rules: data => {
										return data.bcode != '' && data.state != '结束' && data.pendingCanBackToDraft;
									},
									event: res => {
										this.handleBackToDraft(res.row);
									}
								}
							]
						}
					]
				},
				{
					label: {
						icon: 'es-icon-guanzhu',
						text: '关注',
						count: 'favoriteCount'
					},
					...config,
					url: '/bpm/favorite/favoriteRecord/bootListJson.dhtml',
					searchKey: 'title',
					thead: [
						{
							title: '标题',
							field: 'title',
							showOverflowTooltip: true,
							minWidth: '240',
							render: (h, params) => {
								let { row } = params;
								let { title } = row;
								let style = { cursor: 'pointer' };
								return h(
									'span',
									{
										style: style,
										on: {
											click: () => {
												this.handleFinish(row, true);
											}
										}
									},
									[title]
								);
							}
						},
						{
							title: '业务类型',
							field: 'bname',
							width: '260',
							render: (h, params) => {
								let { row } = params;
								let { bname, sendorgname, createtime } = row;
								return h('span', { style: { color: '#737A94' } }, [
									`${bname || sendorgname} | ${createtime}`
								]);
							}
						},
						{
							title: '关注',
							align: 'center',
							field: 'concern',
							width: '70',
							render: (h, params) => {
								let {
									row: { id, recordid, bname, title, readurl, concern }
								} = params;
								concern = true;
								let style = {
									'font-size': '18px',
									color: '#ff0000'
								};
								let icon = concern ? 'es-icon-guanzhu' : 'es-icon-attention';
								return h('i', {
									class: icon + ' es-pointer es-pointer-icon',
									style,
									on: {
										click: () => {
											this.handleCollect({
												id,
												apprecordid: recordid,
												bname,
												pendtitle: title,
												readurl,
												concern
											});
										}
									}
								});
							}
						},
						{
							title: '操作',
							type: 'handle',
							width: '100',
							template: '',
							events: [
								{
									text: '查看',
									mold: 'text',
									event: res => {
										this.handleFinish(res.row, true);
									}
								}
							]
						}
					]
				}
			],
			dialogWidth: '',
			dialogHeight: '',
			title: '',
			visible: false,
			form: {
				signattitude: ''
			},
			url: '',
			index: 0,
			showSupervise: false,
			taskRead: false,
			notify: '',
			notifyList: [],
			rowData: {}
		};
	},
	created() {
		this.getCount();
	},
	mounted() {
		let mainConfig = this.$.getStorage('mainConfig');
		if (mainConfig) {
			mainConfig = JSON.parse(mainConfig);
			this.notify = mainConfig.userStyle.notify;
			this.notifyList = mainConfig.notifyList;
		}

		this.handleReload();
	},
	methods: {
		handleActive(n) {
			this.index = n;
		},
		//获取统计数据
		getCount() {
			this.$store.dispatch('getCount', { ajax: this.$.ajax, userId: this.userId });
		},
		//关注图标样式
		setConcernClick(h, params) {
			let {
				row: { id, apprecordid, bname, pendtitle, readurl, concern, frId }
			} = params;
			let style = {
				'font-size': '18px'
			};
			if (concern || frId) {
				style.color = '#ff0000';
			}
			let icon = concern || frId ? 'es-icon-guanzhu' : 'es-icon-attention';
			return h('i', {
				class: icon + ' es-pointer es-pointer-icon',
				style,
				on: {
					click: () => {
						this.handleCollect({
							id,
							apprecordid,
							bname,
							pendtitle,
							readurl,
							concern: concern || frId
						});
					}
				}
			});
		},
		//（取消）关注
		handleCollect({ id, apprecordid, bname, pendtitle, readurl, concern }) {
			let url = concern ? this.removeCollect : this.collect;
			this.$.ajax({
				url,
				method: 'post',
				data: {
					id: id,
					recordid: apprecordid,
					modulename: bname,
					title: pendtitle,
					urladdress: readurl,
					concern: concern
				}
			})
				.then(res => {
					if (res == 'success') {
						this.$message.success('关注成功！');
						this.$refs.backlog.reload();
						this.getCount();
					} else if (res == true) {
						this.$message.success('取消关注成功！');
						this.$refs.backlog.reload();
						this.getCount();
					} else {
						this.$message.error('操作失败！');
					}
				})
				.catch(err => {
					if (err.message) {
						this.$message.error(err.message);
					}
				});
		},
		//办理&查看
		handleFinish(res, look) {
			let { id, apprecordid, recordid, pendingurl, readurl, itemname, urladdress } = res;
			!look && this.handleUpdatePendstate(id);
			let url = look ? readurl : pendingurl;
			if (url) {
				url = url.replace('[recordid]', apprecordid);
				url = url.replace('[pendingId]', id);
				url = url.replace('[itemname]', itemname);
				if (url.indexOf('itemname=') == -1 && !look) {
					url += `&itemname=${itemname}`;
				}
				// let param = { pendingId: id, itemname: itemname };
				// pendingurl = this.$.urlJoinParams({ url: pendingurl, param: { pendingId: param } });
				let userId = this.$.getStorage('userId');
				let token = this.$.getStorage('token') || this.$.getStorage('Authorization');
				url = url.replace('[userId]', userId);
				url = url.replace('[token]', token);

				url = encodeURI(url);
				let win;
				if (this.openType) {
					win = window.open(url);
				} else {
					let width = window.screen.availWidth - 10;
					let height = window.screen.availHeight - 65;
					win = window.open(
						url,
						'',
						`width=${width},height=${height},top=0,left=0,resizable=yes,status=yes,menubar=no,scrollbars=yes`
					);
				}
				if (!look && win) {
					let times = setInterval(() => {
						if (win.closed) {
							clearInterval(times);
							// 列表刷新
							this.$refs.backlog.reload();
							this.getCount();
						}
					}, 1000);
				}
			}
			//我的关注查看
			if (urladdress) {
				urladdress = urladdress.replace('[recordid]', recordid);
				urladdress = urladdress.replace('[pendingId]', id);

				let url = encodeURI(urladdress);
				let win;
				if (this.openType) {
					win = window.open(url);
				} else {
					let width = window.screen.availWidth - 10;
					let height = window.screen.availHeight - 65;
					win = window.open(
						url,
						'',
						`width=${width},height=${height},top=0,left=0,resizable=yes,status=yes,menubar=no,scrollbars=yes`
					);
				}
				if (!look && win) {
					let times = setInterval(() => {
						if (win.closed) {
							clearInterval(times);
							// 列表刷新
							this.$refs.backlog.reload();
							this.getCount();
						}
					}, 1000);
				}
			}
		},
		//更新阅读状态
		handleUpdatePendstate(id) {
			this.$.ajax({
				url: this.updatePendstate,
				method: 'post',
				data: {
					pendingId: id
				}
			})
				.then(res => {})
				.catch(err => {});
		},
		//公文查看
		handleView(res) {
			var url = `/archives/archives/dispatchDraft/view/dispatch_view.dhtml?disId=${res.arcid}&qianshou=true&showExtendAttchment=true`;
			let width = window.screen.availWidth - 10;
			let height = window.screen.availHeight - 65;
			window.open(
				url,
				'',
				`width=${width},height=${height},top=0,left=0,resizable=yes,status=yes,menubar=no,scrollbars=yes`
			);
		},
		//催办
		handleSupervise(data) {
			this.taskRead = false;
			this.dialogWidth = '600px';
			this.dialogHeight = '480px';
			this.rowData = data;
			this.title = '催办';
			this.visible = true;
			this.showSupervise = true;
			window.top.closeDialog = () => {
				this.visible = false;
				this.taskRead = false;
				this.showSupervise = false;
				this.url = '';
			};
		},
		//撤销
		handleBackToDraft(data) {
			this.$confirm('确定撤销到草稿状态吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					let loading = this.$.loading('', '撤销中，请稍等！');
					let { apprecordid, bcode } = data;
					this.$.ajax({
						url: '/bpm/task/wfApplication/backToDraft.dhtml',
						params: { apprecordId: apprecordid, bcode }
					}).then(res => {
						loading.close();
						if (res.rCode == 0 || res.status == 'success') {
							this.$message.success('处理成功！');
							this.$refs.backlog.reload();
							this.getCount();
						} else {
							this.$message.error(res.msg || res.message);
						}
					});
				})
				.catch(err => {
					if (err.message && err.message !== 'canceled') {
						this.$message.error(err.message);
					}
				});
		},
		//撤回
		handleWithdraw(data) {
			this.$confirm('确认撤回吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					let loading = this.$.loading('', '撤回中，请稍等！');
					let { id } = data;
					this.$.ajax({
						url: '/bpm/task/taskHandle/taskWithdraw.dhtml',
						params: { pendedId: id }
					}).then(res => {
						loading.close();
						if (res.status == 'success') {
							this.$message.success('处理成功！');
							this.$refs.backlog.reload('0');
							this.getCount();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(err => {
					if (err.message && err.message !== 'canceled') {
						this.$message.error(err.message);
					}
				});
		},
		//结束阅办
		handleEndTaskReadDeal(data) {
			this.$confirm('确认结束阅办吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					let loading = this.$.loading('', '撤回中，请稍等！');
					let { appid } = data;
					this.$.ajax({
						url: '/bpm/task/taskHandle/endTaskReadDeal.dhtml',
						params: { appId: appid }
					}).then(res => {
						loading.close();
						if (res.status == 'success') {
							this.$message.success('处理成功！');
							this.$refs.backlog.reload();
							this.getCount();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(err => {
					if (err.message && err.message !== 'canceled') {
						this.$message.error(err.message);
					}
				});
		},

		//签收
		handleToSign(res) {
			this.dialogWidth = '600px';
			this.dialogHeight = '240px';
			this.url = '';
			this.taskRead = false;
			this.showSupervise = false;
			this.$set(this.form, 'signattitude', '已签收');
			this.$set(this.form, 'id', res.id);
			this.title = '签收公文';
			this.visible = true;
		},
		//拒签
		handleToRefuse(res) {
			this.dialogWidth = '600px';
			this.dialogHeight = '240px';
			this.url = '';
			this.taskRead = false;
			this.showSupervise = false;
			this.$set(this.form, 'signattitude', '拒签');
			this.$set(this.form, 'id', res.id);
			this.title = '拒签公文';
			this.visible = true;
		},
		//签收、拒签处理
		handleSign(value, state) {
			let params = {
				'bean.id': value.id,
				state: state == 'QSDJ' ? 'QS' : state,
				'bean.signattitude': value.signattitude
			};
			let loading = this.$.loading('', '处理中，请稍等！');
			this.$.ajax({
				url: '/archives/archives/sign/sign.dhtml',
				method: 'post',
				data: params
			}).then(res => {
				loading.close();
				if (res.status == 'success') {
					this.visible = false;
					this.$message.success('处理成功！');
					if (state == 'QSDJ') {
						let url = encodeURI(
							`/archives/archives/receip/register/receip_register.dhtml?id=${res.data}&itemname=登记`
						);
						if (this.openType) {
							window.open(url);
						} else {
							let width = window.screen.availWidth - 10;
							let height = window.screen.availHeight - 65;
							window.open(
								url,
								'',
								'width=' +
									width +
									',height=' +
									height +
									',top=0,left=0,resizable=yes,status=yes,menubar=no,scrollbars=yes'
							);
						}
					}
					this.$refs.backlog.reload();
					this.getCount();
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//已办其它操作
		handleStartTaskRead(data) {
			this.dialogWidth = '600px';
			this.dialogHeight = '420px';
			this.rowData = data;
			this.taskRead = true;
			this.showSupervise = false;
			this.title = data.taskReadTypeName;
			this.visible = true;
			window.top.closeDialog = () => {
				this.visible = false;
				this.taskRead = false;
				this.showSupervise = false;
				this.url = '';
			};
		},
		handleClose() {
			window.top.closeDialog = () => {};
		},
		handleMore() {
			window.top.jumpMenu(this.moreUrl[this.index]);
		},
		handleSuccess() {
			let taskRead = this.taskRead;
			this.visible = false;
			this.taskRead = false;
			this.showSupervise = false;
			this.url = '';
			taskRead && this.$refs.backlog.reload();
			this.getCount();
		},
		handleReload() {
			if (this.$.win.$wujie) {
				this.$.win.$wujie.bus.$on('waitdone', () => {
					console.log(99999);
					this.$refs.backlog.reload();
					this.getCount();
				});
			} else {
				console.log(999998887);
				this.bus.$on('waitdone', () => {
					console.log(999998888);
					this.$refs.backlog.reload();
					this.getCount();
				});
				window.addEventListener('message', (method, args) => {
					if (method === 'waitdone') {
						this.$refs.backlog.reload();
						this.getCount();
					}
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.backlog-suffix {
	flex: 1;
	text-align: right;
}
::v-deep .es-data-table-content .es-table thead th {
	background-color: #f5f9ff;
}
</style>
