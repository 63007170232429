<template>
	<el-tabs v-model="defaultActive" class="es-tabs-table" :style="styles">
		<slot></slot>
		<el-tab-pane
			v-for="(item, index) in contents"
			v-show="item.label=='待签'?getCount(item.label.count, item.label)>0:true"
			:name="String(index)"
			:key="index"
			:immediate="item.immediate"
			:label="typeof item.label === 'object' ? '' : item.label"
		>
			<template slot="label" v-if="typeof item.label === 'object'">
				<div class="tab-label">
					<span>
						<es-icon
							class="tab-label-icon"
							v-if="item.label.icon"
							:contents="item.label.icon"
						></es-icon>
						<span class="tab-label-text">
							{{ item.label.text }}
						</span>
					</span>
					<span class="tab-label-count">{{ getCount(item.label.count, item.label) }}</span>
				</div>
			</template>
			<template slot-scope="{ loaded, active }">
				<es-data-table
					v-bind="item"
					v-if="loaded"
					:display="active"
					:ref="'tabsTable_' + index"
				></es-data-table>
			</template>
		</el-tab-pane>
	</el-tabs>
</template>
<script>
import { mapState } from 'vuex';
export default {
	name: 'TabsTable',
	props: {
		active: {
			type: String,
			default: '0'
		},
		icon: String,
		tabsTitle: String,
		search: [Boolean, String],
		height: {
			type: Number,
			default: 272,
			validator: function (value) {
				return value > 271;
			}
		},
		contents: Array
	},
	computed: {
		styles() {
			return { height: this.height + 'px' };
		},
		name() {
			return this.contents[parseInt(this.defaultActive)].searchKey
				? this.contents[parseInt(this.defaultActive)].searchKey
				: typeof this.search == 'string'
				? this.search
				: 'query_keyword';
		},
		...mapState([
			'makePendingCount',
			'readPendingCount',
			'allPendingCount',
			'meApplicationCount',
			'pendedCount',
			'signCount',
			'favoriteCount'
		])
	},
	watch: {
		defaultActive: {
			immediate: true,
			handler(val, old) {
				this.where = '';
				this.$emit('active', parseInt(val));
				if (old && val != old) {
					this.$nextTick(() => {
						this.reload();
					});
				}
				console.log(val, old, 9999);
			}
		}
	},
	data() {
		return {
			defaultActive: this.active,
			where: ''
		};
	},
	mounted() {},
	methods: {
		getCount(res, obj) {
			return isNaN(Number(res)) ? this[res] : res;
		},
		reload(index) {
			console.log(this.$refs['tabsTable_' + this.defaultActive], 90999);
			this.$refs['tabsTable_' + this.defaultActive][0].reload();
			if (index) {
				this.$refs['tabsTable_' + index][0].reload();
			}
			this.$emit('getCount');
		},
		handleSearch() {
			let where = {};
			this.where && (where[this.name] = this.where);
			this.$refs['tabsTable_' + this.defaultActive][0].reload({ where: where });
		},
		handleSearchData(e) {
			if (e.keyCode === 13) {
				this.handleSearch();
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.es-tabs-table {
	background-color: #fff;

	.es-tabs-title {
		font-weight: bold;
		padding-right: 20px;
		padding-left: 26px;
		font-size: 15px;

		&.es-decorate {
			position: relative;

			&::before,
			&::after {
				content: '';
				display: block;
				width: 10px;
				height: 10px;
				background-color: #d7e0f4;
				position: absolute;
				left: 6px;
				top: 11px;
			}

			&::after {
				background-color: $--color-primary;
				left: 0;
				top: 17px;
			}
		}
	}
	.es-tabs-search {
		padding: 5px 0 5px 5px;
		.tabs-search {
			::v-deep .el-input__inner {
				border-radius: 20px;
			}
		}
		.el-icon-search {
			cursor: pointer;
		}
	}
}
.tab-label {
	position: relative;
	height: 56px;
	line-height: 24px;
	background-color: #f2f2f2;
	border-radius: 9px;
	display: flex;
	padding: 4px;
	justify-content: space-around;
	align-items: center;
	.tab-label-view {
		width: 100%;
		padding: 0 16px;
	}
	.tab-label-icon {
		font-size: 18px;
		color: #737a94;
	}
	.tab-label-text {
		font-size: 16px;
		color: #737a94;
	}
	.tab-label-count {
		float: right;
		font-weight: bold;
		font-size: 18px;
		color: #15224c;
	}
}
::v-deep {
	.es-data-table {
		height: 100%;
	}
	.es-data-table-content .es-table:not(.el-table--border) {
		border: 0;
		&::before {
			content: none;
		}
	}
	.el-tabs__header {
		margin-bottom: 6px;
	}
	.el-tabs__nav {
		float: none;
		display: flex;
		padding-bottom: 20px;
	}
	.el-tabs__item {
		padding: 0 6px;
		flex: 1;
		height: 56px;
		&.is-active .tab-label {
			background-color: var(--theme-primary-light-6);
			.tab-label-icon,
			.tab-label-text,
			.tab-label-count {
				color: #fff;
			}
		}
	}
	.el-tabs__content {
		height: calc(100% - 50px);

		.el-tab-pane {
			height: 100%;
		}
	}
	.el-badge__content.is-fixed {
		top: 8px;
		right: 8px;
	}
}
</style>
