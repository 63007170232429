<!--
* @desc: 主要经营指标
* @Author: YanZhongYing
* @Date: 2024-06-05
-->
<template>
  <div class="content">
    <div class="box-date">
      <es-date-picker v-model="date" type="month"> </es-date-picker>
      <div class="end-date">截至日期：{{ endDate }}</div>
    </div>

    <div class="box flex-fc">
      <template v-for="(item, index) of info">
        <div :key="index" class="box-item flex-item">
          <!-- 头部 -->
          <Header :title="item.title" />
          <!-- 绝对值指标 -->
          <div
            v-if="item.title == '绝对值指标' && item.data.length > 0"
            class="absolute-box"
          >
            <template v-for="(i, ind) of item.data">
              <div
                :key="ind"
                class="absolute-box-item"
                :style="{
                  backgroundImage:
                    'url(' +
                    require(`@/assets/image/operateAnalysisShow/card${
                      ind + 1
                    }.png`) +
                    ')',
                }"
              >
                <div class="absolute-box-item-name">
                  {{ i.name }}
                </div>
                <div class="absolute-box-item-value">
                  {{ i.value }}<span class="unit">{{ i.valueUnit }}</span>
                </div>
                <div class="absolute-box-item-per">
                  {{ i.percentage }}%
                  <img
                    class="per-img"
                    :src="
                      require(`@/assets/image/operateAnalysisShow/icon_${
                        i.percentage > 0 ? 'up' : 'down'
                      }.png`)
                    "
                    alt=""
                    srcset=""
                  />
                </div>
                <div v-if="i.budgetCompletionRate">
                  <div class="absolute-box-item-line"></div>
                  <div class="absolute-box-item-text">预算完成率</div>
                  <div class="absolute-box-item-rate">
                    {{ Number(i.budgetCompletionRate) }}%
                  </div>
                  <!-- Number(i.budgetCompletionRate) -->
                  <el-progress
                    :percentage="Number(i.budgetCompletionRate)"
                    :stroke-width="8"
                    :show-text="false"
                    background="#ffffff20"
                  ></el-progress>
                </div>
              </div>
            </template>
          </div>
          <!-- 比率类指标 -->
          <div
            v-if="item.title == '比率类指标' && item.data.length > 0"
            class="interest-box"
          >
            <template v-for="(i, ind) of item.data">
              <div :key="ind" class="interest-box-item">
                <div class="interest-box-item-name">{{ i.name }}</div>

                <img
                  class="interest-box-item-img"
                  :src="
                    require(`@/assets/image/operateAnalysisShow/img${
                      ind + 1
                    }.png`)
                  "
                  alt=""
                />
                <div class="interest-box-item-value">
                  {{ i.value }}
                  <span>{{ i.name.includes("全员劳动") ? "万元" : "%" }}</span>
                </div>
                <div class="interest-box-item-per">
                  {{ i.percentage
                  }}{{ i.name.includes("全员劳动") ? "万元/人" : "个百分点" }}
                  <img
                    class="per-img"
                    :src="
                      require(`@/assets/image/operateAnalysisShow/icon_${
                        i.percentage > 0 ? 'up' : 'down'
                      }.png`)
                    "
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
            </template>
          </div>
          <div class="empty" v-if="item.data.length == 0">
            <el-empty description="暂无数据"></el-empty>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Mixins from "./mixins/mixins";
import Header from "./components/Header.vue";
import { operateShow, common } from "@/http/operateAnalysisShow";
export default {
  components: { Header },
  mixins: [Mixins],
  data() {
    return {
      operateShow,
      info: [
        {
          data: [],
          title: "绝对值指标",
        },
        {
          data: [],
          title: "比率类指标",
        },
      ],
      endDate: "",
      id: "", //9151000020181233912024059,
    };
  },

  watch: {
    date(val) {
      this.getTree();
      // 设置日期为下一个月的第一天
      const nextMonth = new Date(val.split("-")[0], val.split("-")[1], 1);
      // 减去一天，得到本月的最后一天
      nextMonth.setDate(nextMonth.getDate() - 1);
      this.endDate = val + "-" + nextMonth.getDate();

      // this.getData();
    },
  },

  methods: {
    getTree() {
      this.$.ajax({
        url: common.companyTree,
        params: {
          dateString: this.date,
        },
      })
        .then((res) => {
          const { rCode, results } = res;
          if (rCode == 0) {
            this.id = results.length > 0 ? results[0].id : "";
            if (this.id) {
              this.getData();
            } else {
              this.info = [
                {
                  data: [],
                  title: "绝对值指标",
                },
                {
                  data: [],
                  title: "比率类指标",
                },
              ];
            }
          } else {
            this.$message.error(msg);
          }
        })
        .catch((error) => {});
    },
    /**
     * @desc: 获取数据
     **/
    getData() {
      const loading = this.$.loading(this.$loading, "加载中");
      this.$.ajax({
        url: operateShow.mainData,
        params: {
          dateString: this.date,
          id: this.id,
        },
      })
        .then((res) => {
          loading.close();
          const { rCode, results, msg } = res;
          if (rCode == 0) {
            this.info = results;
            this.info[1].title = "比率类指标";
          }
        })
        .catch((error) => {
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  font-size: 14px;
  background: #fff;
  position: relative;
  overflow: auto;
  .box-date {
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    align-items: center;
    .es-date-picker {
      width: 130px;
      margin-right: 16px;
    }
  }
  .box {
    width: 100%;
    height: 100%;
    &-item {
      border-radius: 8px;
      box-shadow: 0px 0px 10px 0px rgba(30, 52, 70, 0.05);
      // border: 1px solid red;
      &:first-child {
        margin-bottom: 6px;
      }
      ::v-deep .info-title {
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        .icon {
          height: 16px;
          top: 4px;
        }
      }
    }
  }
}

//绝对值指标
.absolute-box {
  height: calc(100% - 40px);
  padding: 14px;
  display: flex;
  justify-content: space-between;
  &-item {
    flex: 1;
    height: 100%;
    background-size: 100% 100%;
    &:not(:last-child) {
      margin-right: 14px;
    }
    color: #fff;
    padding: 10px;
    &-name {
      font-size: 16px;
    }
    &-value {
      font-size: 26px;
      margin: 8px 0;
      .unit {
        font-size: 14px;
      }
    }
    &-per {
      display: inline-block;
      background: #ffffff10;
      border-radius: 16px;
      .per-img {
        margin-left: 6px;
        width: 10px;
      }
    }
    &-line {
      height: 1px;
      background: #ffffff;
      opacity: 0.2;
      margin: 8px 0;
    }
    &-rate {
      font-size: 18px;
      margin-bottom: 4px;
    }
    // 进度条
    ::v-deep .el-progress {
      .el-progress-bar__inner {
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          #fff 100%
        ) !important;
      }
    }
  }
}

// 比率类指标
.interest-box {
  height: calc(100% - 40px);
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  color: #333333;
  &-item {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:not(:last-child) {
      border-right: 1px solid #eee;
    }
    &-name {
      font-size: 16px;
    }
    &-progress {
      margin: 10px 0;
      ::v-deep .el-progress__text {
        .el-progress-text {
          font-size: 38px;
        }
        .unit {
          font-size: 18px;
        }
      }
    }

    &-per {
      background: #cccccc20;
      border-radius: 16px;
      padding: 6px 12px;
      .per-img {
        margin-left: 8px;
        width: 12px;
      }
    }
    &-img {
      width: 76px;
      margin: 10px 0;
    }
    &-value {
      margin-bottom: 6px;
      font-size: 26px;
      span {
        font-size: 16px;
      }
    }
  }
}
.empty {
  height: calc(100% - 40px);
  ::v-deep .el-empty {
    padding: 0;
    .el-empty__image {
      width: 120px;
    }
  }
}
// 在小于或等于 800 像素的屏幕上
@media screen and (max-width: 800px) {
  .absolute-box {
    &-item-value {
      font-size: 20px;
      .unit {
        font-size: 12px;
      }
    }
  }
  .interest-box {
    &-item-img {
      width: 60px;
    }
    .per-img {
      width: 9px;
      margin-left: 2px;
    }
  }
}
</style>
@/http/operateAnalysisShow