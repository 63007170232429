<template>
	<div :id="mid" class="echart"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
	name: 'MoreBars',
	props: {
		mid: String,
		data: {
			type: Object,
			default: function () {
				return {};
			}
		},
		isLegend: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {};
	},
	watch:{
		data:{
			deep:true,
			handler(val){
				this.$nextTick(function () {
			this.drawBar(this.mid, this.data);
		});
			}
		}
	},
	mounted() {
	},
	methods: {
		drawBar: function (id, data) {
			let dom = document.getElementById(id);
			if (!dom) {
				return false;
			}
			let chart = echarts.init(dom);
			chart.clear();

			let xData = [],
				list = data.list,
				series = [],
				legendData = [];
			for (let i in list[0].data) {
				xData.push(list[0].data[i].name);
			}
			for (let i in list) {
				legendData.push(list[i].name);
				series.push({
					name: list[i].name,
					type: 'bar',
					barWidth: '12', //柱子宽度
					// barGap: 1, //柱间距离
					itemStyle: {
						normal: {
							// barBorderRadius: [4, 4, 0, 0],
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{
									offset: 0,
									color: data.color[i][0]
								},
								{
									offset: 1,
									color: data.color[i][1]
								}
							]),
							borderRadius:[5,5,0,0]
						}
					},
					label: {
						//图形上的文本标签
						normal: {
							show: false,
							position: 'top',
							textStyle: {
								color: '#333333',
								fontStyle: 'normal',
								// fontFamily: '微软雅黑',
								fontSize: 12
							}
						}
					},
					data: list[i].data
				});
			}

			let option = {
				//   backgroundColor: '#0a1a38',
				title: {
					show: data.name,
					text: data.name,
					textStyle: {
						color: '#fff'
					},
					top: 0,
					left: 0
				},
				tooltip: {
					trigger: 'axis',
					backgroundColor: '#202630',
					borderColor: '#202630',
					textStyle: {
						color: '#fff',
						fontSize: 12
					}
					// axisPointer: {
					//   type: 'line',
					//   lineStyle: {
					//     type: 'solid',
					//   },
					// },
					//  formatter: function (params) {
					//   return (
					//     params[0].name + "<br/>" + params[0].marker+ params[0].seriesName+ '  '+ params[0].value
					//   );
					// },
				},
				grid: [
					{
						bottom: '15%',
						top: '15%',
						left: '5%',
						right: '5%'
					}
				],
				legend: {
					show: this.isLegend,
					icon: 'rect',
					data: legendData,
					left: 'center',
					top: 18,
					itemGap: 16,
					textStyle: {
						color: '#333',
						fontSize:14, lineHeight :12
					},
					itemWidth: 14,
					itemHeight: 12
				},
				xAxis: {
					type: 'category',
					data: xData,
					
					axisLine: {
						show: false,
						lineStyle: {
							color: '#eee'
						}
					},
					axisLabel: {
						textStyle: {
							color: '#333'
						},
						formatter: function (params) {
							if (params.length > 6) {
								return params.replace(params.substr(6, 1), '\n' + params.substr(6, 1));
							} else {
								return params;
							}
						},
						lineHeight: 18,
						
					interval:0
					},
					axisTick: {
						show:false
					},
				},

				yAxis: {
					name: data.unit,
					nameTextStyle: {
						//单位样式
						color: '#666666',
						fontSize: 12,
						padding: [0, 20, 0, 0]
					},
					// type: 'value',
					// max: '6',
					splitLine: {
						//   show: false,
						lineStyle: {
							color: '#eee'
						}
					},
					minInterval:1,
					axisLabel: {
						textStyle: {
							color: '#333'
						}
					}
				},
				series
			};
			chart.setOption(option);
			window.addEventListener('resize', () => {
				chart && chart.resize();
			});
		}
	}
};
</script>
<style scoped>
.echart {
	width: 100%;
	height: 100%;
}
</style>
