<template>
	<statistic mode="horizontal" :data="data"></statistic>
</template>
<script>
import Statistic from '@/components/public/Statistic.vue';
export default {
	name: 'Attendance',
	components: { Statistic },
	props: {
		gutter: {
			type: Number,
			default: 20
		},
		contents: Array
	},
	data() {
		return {
			userId: this.$.getStorage('userId'),
			data: [
				{ icon: 'es-icon-daiban', label: '外出', value: 0, unit: '天', name: 'egress' },
				{ icon: 'es-icon-daiban', label: '迟到', value: 0, unit: '天', name: 'late' },
				{ icon: 'es-icon-daiban', label: '请假', value: 0, unit: '天', name: 'leave' },
				{ icon: 'es-icon-daiban', label: '加班', value: 0, unit: '天', name: 'overtime' },
				{ icon: 'es-icon-daiban', label: '出差', value: 0, unit: '天', name: 'travel' },
				{ icon: 'es-icon-daiban', label: '年假', value: 0, unit: '天', name: 'annaual' }
			]
		};
	},
	created() {
		this.getData();
	},
	methods: {
		getData() {
			this.$.ajax({
				url: '/logistic/attendanceDetails/getMonthDetails.json',
				params: {
					userId: this.userId
				}
			})
				.then(res => {
					if (res.rCode == 0) {
						let { results } = res;
						this.data.forEach(item => {
							item.value = results[item.name];
						});
					}
				})
				.catch(err => {});
		}
	}
};
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	height: 100%;
	padding: 16px;
	background-color: #f5f8fd;
}
</style>
