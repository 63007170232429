<!--
* @desc: 利润贡献情况
* @Author: YanZhongYing
* @Date: 2024-06-17
-->
<template>
  <div class="box">
    <div class="box-date flex-end">
      <es-button style="margin-right: 16px" @click="exportExcel"
        >导出</es-button
      >
      <es-date-picker v-model="date" type="month"> </es-date-picker>
    </div>
    <div class="box-c box-flex">
      <template v-for="(item, ind) of info">
        <div :key="ind" class="box-c-item flex-item">
          <!-- 头部 -->
          <Header :title="item.title" remark="万元" />
          <!-- card -->
          <div class="card">
            <Pies
              v-if="item.data.length > 0"
              :id="'pie' + ind"
              :data="item.data"
              :legendTitle="
                ind == 0
                  ? '营业收入本期累计'
                  : ind == 1
                  ? '利润总额本期累计'
                  : '利润总额'
              "
            ></Pies>
            <el-empty v-else description="暂无数据"></el-empty>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Mixins from "./mixins/mixins";
import Header from "./components/Header.vue";
import Pies from "./components/Pies.vue";
import { operateAnalysisXc, common } from "@/http/operateAnalysisShow";
export default {
  components: {
    Header,
    Pies,
  },
  mixins: [Mixins],
  data() {
    return {
      info: [
        {
          title: "营业收入贡献占比",
          data: [],
        },
        {
          title: "盈利贡献占比",
          data: [],
        },
        {
          title: "亏损影响占比",
          data: [],
        },
      ],
      show: false,
    };
  },
  watch: {
    date() {
      this.getData();
    },
  },
  mounted() {},
  methods: {
    /**
     * @desc: 获取数据
     **/
    getData() {
      const loading = this.$.loading(this.$loading, "加载中");
      let params = { dateString: this.date };
      this.$.ajax({
        url: operateAnalysisXc.contributeData,
        params,
      })
        .then((res) => {
          loading.close();
          const { rCode, results } = res;
          if (rCode == 0) {
            this.$set(this.info[0], "data", results.operatingIncome||[]);
            this.$set(this.info[1], "data", results.profit||[]);
            this.$set(this.info[2], "data", results.loss||[]);
            this.show = true;
          }
        })
        .catch((error) => {
          loading.close();
        });
    },
    /**
     * @desc: 导出
     **/
    exportExcel() {
      let date = this.date.split("-");
      window.open(
        this.$.urlJoinParams({
          url: this.$.getStorage("host") + common.exportExcel,
          param: {
            excelCode: "dataRevenueExcelServiceImpl",
            fileName: date[0] + "年" + date[1] + "月营收利润贡献情况",
            dateString: date[0] + date[1],
          },
        })
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  background: #f6f6f6;
  font-size: 17px;
  overflow: auto;
  &-date {
    width: 100%;
    padding: 6px 10px;
    background: #fff;
    border: 1px solid #eee;
    .es-date-picker {
      width: 200px;
    }
  }
  &-c {
    height: calc(100% - 50px);
    padding: 16px;
    &-item {
      height: 100%;
      background: #fff;
      &:nth-child(2) {
        margin: 0 16px;
      }
    }
    // 前三名
    .card {
      height: calc(100% - 60px);
      padding: 16px 16px 0px 16px;
    }
  }
}
</style>