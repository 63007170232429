<template>
	<div></div>
</template>
<script>
export default {
	name: 'Transfer',
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.$router.replace({ path: to.query.path, query: to.query.query });
		});
	},
	data() {
		return {};
	}
};
</script>
