<template>
	<div class="portal">
		<el-scrollbar class="es-scrollbar">
			<div class="head">
				<div class="toolbar">
					<img class="little-logo" src="@/assets/image/home/little-logo.png" alt="" />
					<div class="tab-list">
						<div
							v-for="(item, index) in tabList"
							:key="item.id"
							class="tab-item"
							:class="{ actived: tabActived == index }"
							@click="onTabClick(item, index)"
						>
							{{ item.name }}
						</div>
					</div>
					<div class="btn-list">
						<div class="btn-item" @click="showGroup = true">川投内网群</div>
						<div class="btn-item" title="搜索" @click="onSearch">
							<img class="icon" src="@/assets/image/home/search.png" alt="" />
						</div>
						<div class="btn-item" title="退出" @click="onQuit">
							<img class="icon" src="@/assets/image/home/exit.png" alt="" />
						</div>
					</div>
				</div>
				<!-- <img
					v-if="bannerImgList.length == 0"
					class="big-logo"
					src="@/assets/image/home/big-logo.png"
					alt=""
				/> -->
				<el-carousel class="banner-siwper" height="320px" :interval="5000">
					<el-carousel-item>
						<div class="big-bg">
							<img class="big-logo" src="@/assets/image/home/big-logo.png" alt="" />
						</div>
					</el-carousel-item>
					<el-carousel-item v-for="item in bannerImgList" :key="item.id">
						<img
							class="banner-item"
							:src="`${baseUrl}/portal/fileManagement/previewAdjunct.dhtml?adjunctId=${item.adjunctId}`"
							alt=""
						/>
					</el-carousel-item>
				</el-carousel>
			</div>
			<router-view :key="routeKey"></router-view>
			<div class="footer">
				<div class="footer1">
					<div class="left">
						<img class="footer-logo" src="@/assets/image/home/foot-logo.png" alt="" />
						<div class="QR-code-list">
							<div class="QR-code-item">
								<div class="QR">
									<img class="QR-img" src="@/assets/image/home/android.png" alt="" />
								</div>
								<div class="label">Android 下载</div>
							</div>
							<div class="QR-code-item">
								<div class="QR">
									<img class="QR-img" src="@/assets/image/home/ios.png" alt="" />
								</div>
								<div class="label">IOS 下载</div>
							</div>
							<div class="QR-code-item">
								<div class="QR">
									<img class="QR-img" src="@/assets/image/home/u595.png" alt="" />
								</div>
								<div class="label">关注公众号</div>
							</div>
						</div>
					</div>
					<div class="right">
						<div class="title">友情链接</div>
						<div class="list">
							<el-scrollbar class="es-scrollbar">
								<div
									class="item"
									v-for="item in friendshipLinkList"
									:title="item.title"
									:key="item.id"
									@click="onFirUrlClick(item)"
								>
									{{ item.title }}
								</div>
							</el-scrollbar>
						</div>
					</div>
				</div>
				<div class="footer2">
					<div class="item">
						<img src="@/assets/image/home/owner-logo.png" alt="" />
						四川省投资集团有限责任公司
					</div>
					<div class="item">四川川投智胜数字科技有限公司技术支持</div>
					<div class="item">地址：中国·四川省·成都市临江西路1号</div>
					<div class="item">邮编：610044</div>
				</div>
			</div>
		</el-scrollbar>
		<group v-if="showGroup" @onClose="showGroup = false"></group>
	</div>
</template>

<script>
import Group from './children/components/Group.vue';
import { logout } from 'eoss-ui/src/config/api.js';
export default {
	data() {
		return {
			//头部页签
			tabList: [],
			//头部页签激活项
			tabActived: 0,
			//友情链接
			friendshipLinkList: [],
			showGroup: false,
			routeKey: 0,
			bannerImgList: [],
			baseUrl: window.location.origin + (window.location.hostname == 'localhost' ? '/dev-api' : '')
		};
	},
	components: { Group },
	computed: {},
	watch: {
		$route(val) {
			this.tabActived =
				this.tabList.find((e, i) => {
					return e.code == val.name;
				})?.value ||
				this.tabList.find((e, i) => {
					return e.code == val.query.name;
				})?.value ||
				0;
		}
	},
	created() {},
	mounted() {
		this.getImgList();
		this.getNavigation();
		this.getFootUrlListFun();
	},
	methods: {
		onTabClick(item, index) {
			if (item.pathValue) {
				window.open(item.pathValue, '_blank');
				return;
			}
			this.tabActived = index;
			this.routeKey++;
			this.$router.push({ name: item.code, query: { pathId: item.id } });
		},
		onQuit() {
			this.$confirm('确定注销吗?', '退出系统', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$.ajax({ method: 'post', url: logout })
						.then(res => {
							if (res.rCode == 0) {
								this.$.removeStorage([
									'Authorization',
									'token',
									'ssId',
									'userId',
									'userName',
									'auth',
									'deviceUnique',
									'menus',
									'useCaseCodes',
									'mainConfig',
									'jump'
								]);
								try {
									const loginPage = this.$.getStorage('login') || this.$.getStorage('loginPage');
									if (loginPage) {
										let src;
										if (!this.$.startWith(loginPage, ['http', '/'], true)) {
											let pathname = this.$.win.top.location.pathname;
											if (pathname !== '/') {
												pathname = pathname.split('/');
												pathname.splice(pathname.length - 1);
												pathname = pathname.join('/');
												src = pathname + '/' + loginPage.replace('./', '');
											} else {
												src = pathname + loginPage.replace('./', '');
											}
										} else {
											src = loginPage;
										}
										this.$.win.top.location.href = src;
									} else if (this.$.win.top.location.href.indexOf('main.html') > -1) {
										this.$.win.top.location.href = './login.html';
									} else {
										const hash = this.$.win.top.location.hash;
										if (hash) {
											const len = this.$.win.top.location.href.indexOf(hash);
											this.$.win.top.location.href =
												this.$.win.location.href.slice(0, len) + '#/login';
										} else {
											this.$.win.top.location.href = '/login.html';
										}
									}
								} catch (error) {
									this.$.win.postMessage({ type: 1 }, '*');
								}
							}
						})
						.catch(err => {
							if (err.message && err.message !== 'canceled') {
								this.$message.error(err.message);
							}
						});
				})
				.catch(e => {});
		},
		//顶部图片轮播
		getImgList() {
			this.$.ajax({ url: this.$url.getBannerList }).then(res => {
				//有图片才开始轮播
				if (res.status == 'success' && res.data.length > 0) {
					this.bannerImgList = res.data.filter(e => {
						return e.adjunctId;
					});
				}
			});
		},
		//顶部导航接口
		getNavigation() {
			this.$.ajax({ url: this.$url.getNavigation }).then(res => {
				if (res.status == 'success') {
					let list = res.data;
					let map = [
						{ label: '首页', code: 'home', value: 0 },
						{ label: '制度汇编', code: 'compilationOfSystems', value: 1 },
						{ label: '合规管理', code: 'complianceManagement', value: 2 },
						{ label: '安全环保', code: 'safetyAndEnvironmentalProtection', value: 3 },
						{ label: '人力资源', code: 'rlzy', value: 4 },
						{ label: '保密安全', code: 'confidentialityAndSecurity', value: 5 }
					];
					//后台没有设置地址的功能，只能前端去匹配name
					this.tabList = list.map(e => {
						let item = map.find(ele => {
							return ele.label == e.name;
						});
						return {
							...e,
							code: item.code,
							value: item.value
						};
					});
					this.tabActived =
						this.tabList.find((e, i) => {
							return e.code == this.$route.name;
						})?.value ||
						this.tabList.find((e, i) => {
							return e.code == this.$route.query.name;
						})?.value ||
						0;
				}
			});
		},
		//获取友情链接
		getFootUrlListFun() {
			this.$.ajax({ url: this.$url.getFootUrlList }).then(res => {
				if (res.status == 'success') {
					this.friendshipLinkList = res.data.friendshipLink;
				}
			});
		},
		//点击友情链接
		onFirUrlClick(item) {
			try {
				window.open(item.linkUrl, '_blank');
			} catch {
				this.$message.error('系统地址异常：' + item.url);
			}
		},
		onSearch() {
			this.$router.push({ name: 'search' });
		}
	}
};
</script>

<style scoped lang="scss">
.portal {
	width: 100%;
	height: 100%;
	padding: 0;
	overflow: hidden;
	.head {
		width: 100%;
		height: 320px;
		// z-index: 5;
		position: relative;
		background-size: 100% 100%;
		&.bg {
			background: url('@/assets/image/home/head-bg.png') no-repeat;
		}
		.toolbar {
			display: flex;
			height: 80px;
			padding: 0 12px 0 400px;
			position: relative;
			border-bottom: 1px solid rgba(255, 255, 255, 0.16);
			justify-content: space-between;
			z-index: 9;
			background: rgba($color: #3e62ba, $alpha: 0.8);
			position: fixed;
			width: 100%;
			top: 0;
			.little-logo {
				width: 357px;
				height: 40px;
				margin-top: 24px;
				left: 12px;
				position: absolute;
			}
			.tab-list {
				display: flex;
				align-items: center;
				.tab-item {
					cursor: pointer;
					margin-right: 36px;
					padding: 0px 16px 0;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 18px;
					color: rgba($color: #fff, $alpha: 0.35);
					height: 100%;
					display: flex;
					align-items: center;
					&:last-child {
						margin-right: 0;
					}
					&.actived {
						background: url('@/assets/image/home/head-tab-actived.png') no-repeat;
						background-size: 65px 55px;
						color: #ffffff;
						background-position: center calc(50% + 7px);
					}
				}
			}
			.btn-list {
				display: flex;
				// position: absolute;
				// right: 12px;
				height: 100%;
				.btn-item {
					cursor: pointer;
					padding: 0 28px;
					display: flex;
					align-items: center;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 16px;
					color: #ffffff;
					.icon {
						width: 22px;
						height: 22px;
					}
				}
			}
		}
		.banner-siwper {
			height: 320px;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			.big-bg {
				width: 100%;
				height: 100%;
				background: url('@/assets/image/home/head-bg.png') no-repeat;
				background-size: 100% 100%;
				padding-top: 130px;

				.big-logo {
					display: block;
					width: 538px;
					height: 116px;
					margin: 0px auto;
					z-index: 5;
					position: relative;
				}
			}
			.banner-item {
				min-width: 1200px;
				width: auto;
				display: block;
				margin: 0 auto;
				height: 100%;
			}
		}
	}
	.footer {
		width: 100%;
		.footer1 {
			display: flex;
			padding: 48px 360px;
			padding-right: 0;
			width: 100%;
			height: 340px;
			background: url('@/assets/image/home/foot-bg.png') no-repeat;
			background-size: 100% 100%;
			.left {
				width: 536px;
				height: 100%;
				padding-right: 64px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				border-right: 1px solid rgba($color: #fff, $alpha: 0.2);
				.footer-logo {
					width: 109px;
					height: 43px;
				}
				.QR-code-list {
					width: 100%;
					display: flex;
					justify-content: space-between;
					.QR-code-item {
						.QR {
							width: 120px;
							height: 120px;
							margin-bottom: 19px;
							background: #fff;
							.QR-img {
								width: 100%;
								height: 100%;
							}
						}
						.label {
							text-align: center;
							font-family: Alibaba PuHuiTi 2;
							font-weight: normal;
							font-size: 16px;
							color: #ffffff;
						}
					}
				}
			}
			.right {
				height: 100%;
				width: 660px;
				.title {
					height: 50px;
					padding: 8px 0 0 64px;
					font-family: Alibaba PuHuiTi 2;
					font-weight: normal;
					font-size: 20px;
					color: #ffffff;
				}
				.list {
					height: calc(100% - 50px);
					width: 100%;
					.item {
						width: 50%;
						padding-left: 64px;
						cursor: pointer;
						display: inline-block;
						margin: 8px 0;
						font-family: Alibaba PuHuiTi 2;
						font-weight: normal;
						font-size: 16px;
						color: rgba($color: #fff, $alpha: 0.7);
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						&:hover {
							color: #fff;
						}
					}
				}
			}
		}
		.footer2 {
			width: 100%;
			height: 56px;
			background: #ececec;
			display: flex;
			align-items: center;
			justify-content: center;
			.item {
				img {
					width: 14px;
					height: 14px;
					margin-right: 8px;
				}
				padding: 0 24px;
				font-family: Alibaba PuHuiTi 2;
				display: flex;
				align-items: center;
				font-weight: normal;
				font-size: 14px;
				color: #737a94;
				border-right: 1px solid rgba($color: #737a94, $alpha: 0.2);
				&:last-child {
					border-right: none;
				}
			}
		}
	}
}
</style>
